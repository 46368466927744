<app-btn-generar-resumen-mensual (procesoFinalizado)="procesoFinalizado($event)"></app-btn-generar-resumen-mensual>
<br>
<br>
<div class="alert alert-danger alert-dismissible">
  <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  <strong>Atención!</strong> Si usted esta generando este proceso el mismo día que la fecha de generaciín de resumen parametrizada en el sistema, recuerde correr el proceso de actualización de ICL.
</div>
<div class="alert alert-info alert-dismissible">
  <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
  <strong>Info!</strong> Este proceso realiza la generación de los resúmenes para los inquilinos. El mismo abarca las
  deudas pendientes hasta la fecha y lo que debe abonar el mes posterior. Luego se envía un correo con dicha informacion
  al correo correspondiente
  del inquilino.
</div>
