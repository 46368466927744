
import { N } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';
import { InquilinoController } from '@controllers/inquilinos.controller';
import { AccionesDialog, TiposBonificaciones, TiposMovimiento, TiposPunitorio, } from '@models/base/identificadores.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { GetPendingDebtInquilinoResponse } from '@models/inquilino/getPendingDebtInquilinoResponse.model';

import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { PagoDto } from '@models/pago/pagoDto.model';
import { DetalleServicioPropiedadDetailDto, Vencimiento } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { DialogEditarAdicionalesInquilinoComponent } from '../dialog-editar-adicionales-inquilino/dialog-editar-adicionales-inquilino.component';
import { DialogEditarBonificacionesComponent } from '../dialog-editar-bonificaciones/dialog-editar-bonificaciones.component';
import { DialogEditarMovimientosComponent } from '../dialog-editar-movimientos/dialog-editar-movimientos.component';
import { DialogEditarPeriodosContratoComponent } from '../dialog-editar-periodos-contrato/dialog-editar-periodos-contrato.component';
import { DialogNuevoPagoComponent } from '../dialog-nuevo-pago/dialog-nuevo-pago.component';
import { DialogVisualizaReciboComponent } from '../dialog-visualiza-recibo/dialog-visualiza-recibo.component';
import { ContratoDto } from '@models/contrato/contratoDto.model';

export class Movimiento {
  idMonivimiento?: number
  idTipoMovimiento?: number //1=Deuda alquiler
  importe?: number
}

@Component({
  selector: 'app-dialog-nuevo-recibo',
  templateUrl: './dialog-nuevo-recibo.component.html',
  styleUrls: ['./dialog-nuevo-recibo.component.css'],
  providers: [
    [DatePipe],
  ],
})
export class DialogNuevoReciboComponent implements OnInit {
  //#region Variables

  displayedColumns: string[] = ['formaPago', 'importe', 'fecha', 'detalle', 'accion'];
  pagos: PagoDto[] = []
  dataSource = new MatTableDataSource<PagoDto>();
  nuevoLiquidacionFormGroup: FormGroup;
  action: any;
  estadoEditarMontos: boolean = false
  movimientos: MovimientoDetailDto[] = [];
  movimientosSaldo: MovimientoDetailDto[] = [];
  movimientosNuevos: MovimientoDetailDto[] = [];
  movimientosBonificaciones: MovimientoDetailDto[] = [];
  data!: boolean;
  getPendingDebtInquilinoResponse: GetPendingDebtInquilinoResponse = new GetPendingDebtInquilinoResponse;
  lstPeriodosContratoConPunitorio: PeriodoContratoDetailDto[] = []
  contrato!: ContratoDetailDto;
  //Totales
  totalMontoPunitorios: number = 0
  totalMontoAlquiler: number = 0
  totalDetallesServiciosPropiedad: number = 0
  totalMontoMovimientos: number = 0
  totalMontoBonificaciones: number = 0
  totalDeudaInquilino: number = 0
  totalEntregaInquilino: number = 0;
  totalSaldoAnterior: number = 0
  totalMontoDeuda: number = 0
  //Contadores
  cantidadDetalleServicios: number = 0;
  cantidadPunitorio: number = 0;
  contadorDetallesServicioPropiedad: number = 0
  cantidadMovimientos: number = 0
  cantidadBonificaciones: number = 0
  deudaActual: number = 0;
  get f() { return this.nuevoLiquidacionFormGroup.controls; }

  //#endregion

  constructor
    (
      private _formBuilder: FormBuilder,
      private spinner: NgxSpinnerService,
      private snackBar: SnackBarService,
      private contratoService: ContratosService,
      private dialog: MatDialog,
      public dialogRef: MatDialogRef<DialogNuevoReciboComponent>,
      private inquilinosServices: InquilinosService,
      public datepipe: DatePipe,
      @Optional() @Inject(MAT_DIALOG_DATA) public datos: any
    ) {
    this.nuevoLiquidacionFormGroup = this._formBuilder.group({
      PeriodoMes: ['', Validators.required],
      PeriodoAnio: ['', Validators.required],
      BtnEditarMontos: ['', Validators.required],
      MontoPagoInquilino: ['', Validators.required],
      FechaPagoInquilino: ['', Validators.required],
      FormasPagoInquilino: ['', Validators.required],
      ComprobantePagoInquilino: [''],
    });
  }

  ngOnInit(): void {
    this.contrato = this.datos.contrato
    this.getPendingDebtInquilino(this.contrato.id)
  }

  //#region  Recibo
  calcularTotales() {
    this.totalDeudaInquilino = Math.round(this.totalMontoAlquiler*100)/100 + Math.round(this.totalMontoPunitorios*100)/100 + Math.round(this.totalDetallesServiciosPropiedad*100)/100 + Math.round(this.totalMontoMovimientos*100)/100 - Math.round(this.totalMontoBonificaciones*100)/100
    return this.totalDeudaInquilino
  }

  reiniciaContadores() {
    this.movimientos = []
    this.movimientosSaldo = [];
    this.movimientosNuevos = [];
    this.movimientosBonificaciones = [];
    this.lstPeriodosContratoConPunitorio = []
    //Totales
    this.totalMontoPunitorios = 0
    this.totalMontoAlquiler = 0
    this.totalDetallesServiciosPropiedad = 0
    this.totalMontoMovimientos = 0
    this.totalMontoBonificaciones = 0
    this.totalDeudaInquilino = 0
    this.totalEntregaInquilino = 0;
    this.totalSaldoAnterior = 0
    this.totalMontoDeuda = 0
    //Contadores
    this.cantidadDetalleServicios = 0;
    this.cantidadPunitorio = 0;
    this.contadorDetallesServicioPropiedad = 0
    this.cantidadMovimientos = 0
    this.cantidadBonificaciones = 0
  }

  getResumenRecibo() {

    //Periodos Contrato
    let periodosContrato = this.getPendingDebtInquilinoResponse.periodosContrato.filter(
      element => element.seleccionPago == true);
    let totalPeriodosContrato = periodosContrato.reduce((sum, element) => sum + element.importeAlquilerSinPunitorio + element.importePunitorioEntrega, 0);

    //Detalles Servicio Propiedad
    let detallesServiciosPropiedad = this.getPendingDebtInquilinoResponse.detallesServiciosPropiedad.filter(element => element.seleccionPago == true);
    let totalDetallesServiciosPropiedad = Math.round(this.totalDetallesServiciosPropiedad*100)/100

    //Movimientos
    let movimientos = this.getPendingDebtInquilinoResponse.movimientos.filter(
      element => element.seleccionPago == true
        && element.id != 0
        && element.importe != 0);
    let totalMovimientos = movimientos.reduce((sum, element) => sum + element.importe, 0);

    //Movimientos Nuevos
    let movimientosNuevos = this.movimientosNuevos.filter(
      element => element.seleccionPago == true
        && element.tipoMovimiento.id != TiposMovimiento.Bonificaciones
        && element.tipoMovimiento.id != TiposMovimiento.SaldoAFavor
        && element.tipoMovimiento.id != TiposMovimiento.SaldoAdeuda

    );
    let totalMovimientosNuevos = movimientosNuevos.reduce((sum, element) => sum + element.importe, 0);

    //Movimientos Saldo Favor
    let movimientosSaldoFavor = this.movimientosSaldo.filter(
      element => element.tipoMovimiento.id == TiposMovimiento.SaldoAFavor)
    let totalMovimientosSaldoFavor = Math.round(movimientosSaldoFavor.reduce((sum, element) => sum + element.importe, 0)*100)/100;

    //Movimientos Saldo Favor
    let movimientosSaldoDeuda = this.movimientosSaldo.filter(
      element => element.tipoMovimiento.id == TiposMovimiento.SaldoAdeuda)
    let totalMovimientosSaldoDeuda = Math.round(movimientosSaldoDeuda.reduce((sum, element) => sum + element.importe, 0)*100)/100 ;

    //Movimientos Bonificaciones
    let movimientosBonificaciones = this.movimientosBonificaciones.filter(
      element => element.seleccionPago == true);
    let totalBonificaciones = Math.round( movimientosBonificaciones.reduce((sum, element) => sum + element.importe, 0)*100)/100;


    //Pagos
    let pagos = this.pagos
    let totalPagos = Math.round(this.pagos.reduce((sum, element) => sum + element.importe, 0)*100)/100 ;


    //Total Detalle, Servivio y Periodo
    let totalConceptos = Math.round(totalPeriodosContrato*100)/100 + Math.round(totalDetallesServiciosPropiedad*100)/100 + Math.round(totalMovimientos*100)/100 + Math.round(totalMovimientosNuevos*100)/100
    let total = Math.round(totalPagos*100)/100  + Math.round(totalBonificaciones*100)/100  +Math.round(totalMovimientosSaldoFavor*100)/100   - Math.round(totalMovimientosSaldoDeuda*100)/100  - Math.round(totalConceptos*100)/100

    //Nuevo Momiviento Saldo
    let movimientosNuevosSaldo = []
    if (total != 0) {
      let movimiento = new MovimientoDetailDto
      movimiento.descripcion = ""
      movimiento.id = 0
      movimiento.seleccionPago = true
      movimiento.periodoMes = new Date().getMonth() + 1
      movimiento.periodoAnio = new Date().getFullYear()
      movimiento.periodo = 0
      let fechaEmision = isFalsy(this.datepipe.transform(new Date, 'yyyy-MM-dd')) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
      movimiento.fechaEmision = isFalsy(fechaEmision) ? "" : fechaEmision;
      let fechaPago = this.datepipe.transform(new Date, 'yyyy-MM-dd') ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
      movimiento.fechaPago = isFalsy(fechaPago) ? "" : fechaPago;


      if (total < 0) {
        movimiento.tipoMovimiento = { id: TiposMovimiento.SaldoAdeuda, descripcion: "Saldo deuda", resta: true, editable: false, esAnulable: true }
        movimiento.importe = Math.round(total*100)/100
      }

      if (total > 0) {
        movimiento.tipoMovimiento = { id: TiposMovimiento.SaldoAFavor, descripcion: "Saldo a favor", resta: false, editable: false,esAnulable: true }
        movimiento.importe = Math.round(total*100)/100

      }
      if (total != 0) {
        movimientosSaldoFavor.forEach(element => {
          element.anulado = true
        });
        movimientosSaldoDeuda.forEach(element => {
          element.anulado = true
        });
      }
      movimientosNuevosSaldo.push(movimiento)
    }

    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "1000px"
    dialogConfig.maxWidth = '100vw'
    dialogConfig.maxHeight = '100vh'
    //dialogConfig.height= '60%'
    dialogConfig.width = '70%'
    dialogConfig.panelClass = 'full-screen-modal'
    dialogConfig.data = {
      contrato: this.contrato as ContratoDetailDto,
      //Periodos
      periodosContrato: periodosContrato,
      totalMontoAlquiler: this.totalMontoAlquiler,
      //DetallesServiciosPropiedad
      detallesServiciosPropiedad: detallesServiciosPropiedad,
      totalDetallesServiciosPropiedad: totalDetallesServiciosPropiedad,
      //Movimientos
      movimientos: movimientos,
      totalMontoMovimientos: totalMovimientos,

      movimientosSaldoFavor: movimientosSaldoFavor,
      movimientosSaldoDeuda: movimientosSaldoDeuda,
      movimientosNuevosSaldo: movimientosNuevosSaldo,

      movimientosNuevos: movimientosNuevos,

      //Bonificaciones
      movimientosBonificaciones: movimientosBonificaciones,
      totalMontoBonificaciones: totalBonificaciones,
      //Pagos
      pagos: pagos,
      totalEntregaInquilino: totalPagos,
      //Totales
    }


    let dialogRef = this.dialog.open(DialogVisualizaReciboComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      switch (result.event) {
        case AccionesDialog.Aceptar:
          this.dialogRef.close({ event: AccionesDialog.Cancelar });
          break;
        case AccionesDialog.Cancelar:
          break;
        default:
          this.dialogRef.close({ event: AccionesDialog.Cancelar });
          break;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  getPendingDebtInquilino(idContrato: number) {
    this.reiniciaContadores()
    this.spinner.show("spNuevoRecibo")
    this.inquilinosServices.getPendingDebtInquilino(idContrato).subscribe(
      data => {
        this.spinner.hide("spNuevoRecibo");
        this.getPendingDebtInquilinoResponse = data

        this.agregarmovimientosSaldo()
        this.totalSaldoAnterior = InquilinoController.calcularSaldoAnterior(data)
        let contratoDto = new ContratoDto
        contratoDto.diaVencimientoPago = this.contrato.diaVencimientoPago
        contratoDto.montoPunitorio = Math.round(this.contrato.montoPunitorio*100)/100
        this.deudaActual = Math.round(InquilinoController.calcularSaldoActual(data, contratoDto)*100)/100
      },
      error => {
        this.spinner.hide("spNuevoRecibo");
        this.snackBar.showError(error, "Error");
      }
    )
  }

  //#endregion Recibo

  //#region DetalleServicioPropiedad

  editarAdicionalesInquilino() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "40%";
    dialogConfig.data = this.cargarListadoVencimientos(this.getPendingDebtInquilinoResponse.detallesServiciosPropiedad)

    let dialogRef = this.dialog.open(DialogEditarAdicionalesInquilinoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      switch (result.event) {
        case AccionesDialog.Agregar:

          if (result.data as DetalleServicioPropiedadDetailDto[]) {
            this.getPendingDebtInquilinoResponse.detallesServiciosPropiedad = result.data
          }
          this.cancularMontoDetalleServiciosPropiedad()
          break;

        case AccionesDialog.Cancelar:
          //this.getPendingDebtInquilinoResponse.detallesServiciosPropiedad = result.data
          break;
        default:
          break;
      }
      this.contadorDetalleServiciosAPagar()
    });
  }

  cancularMontoDetalleServiciosPropiedad() {
    this.cantidadDetalleServicios = 0
    this.totalDetallesServiciosPropiedad = 0

    //Contador de Detelles de Servicios seleccionados
    this.getPendingDebtInquilinoResponse.detallesServiciosPropiedad.filter(x => x.seleccionPago == true).forEach(
      element => {
        this.totalDetallesServiciosPropiedad += element.vencimientos.filter(y => y.seleccionPago == true).reduce((sum, element) => sum + Number(element.importeVencimiento), 0)
        this.cantidadDetalleServicios += element.vencimientos.filter(y => y.seleccionPago == true).length
      }
    )
  }

  cargarListadoVencimientos(lstDetalleServicioPropiedadDto: DetalleServicioPropiedadDetailDto[]): DetalleServicioPropiedadDetailDto[] {
    return lstDetalleServicioPropiedadDto = InquilinoController.cargarListadoVencimientos(lstDetalleServicioPropiedadDto)
  }

  contadorDetalleServiciosAPagar() {
    return this.getPendingDebtInquilinoResponse.detallesServiciosPropiedad.filter(x => x.seleccionPago == true).length
  }

  //#endregion DetalleServicioPropiedad

  //#region  Movimientos

  agregarmovimientosSaldo() {
    if (this.getPendingDebtInquilinoResponse.movimientos.length > 0) {
      this.movimientosSaldo = this.getPendingDebtInquilinoResponse.movimientos.filter(InquilinoController.esMovimientoTipoSaldo)
    }
  }

  editarMovimientosInquilino() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = this.getPendingDebtInquilinoResponse.movimientos
    dialogConfig.width = "650px";
    let dialogRef = this.dialog.open(DialogEditarMovimientosComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == AccionesDialog.Aceptar) {
        this.movimientosNuevos = []
        this.cantidadMovimientos = 0
        this.totalMontoMovimientos = 0
        this.getPendingDebtInquilinoResponse.movimientos = result.data as MovimientoDetailDto[]
        this.getPendingDebtInquilinoResponse.movimientos.forEach(element => {
          if (element.seleccionPago == true) {
            if (element.tipoMovimiento.resta)
              element.importe * (-1)
            this.totalMontoMovimientos += element.importe
            this.cantidadMovimientos++
            if (element.id == 0)
              this.movimientosNuevos.push(element)
          }
        });
      }

      if (result.event == AccionesDialog.Cancelar) {
        this.getPendingDebtInquilinoResponse.movimientos = result.data
      }

    });
  }
  //#endregion Movimientos

  //#region  Bonificaciones

  cancularMontoBonificaciones(data: MovimientoDetailDto[]) {
    this.cantidadBonificaciones = data.filter(x => x.seleccionPago == true).length
    this.totalMontoBonificaciones = data.filter(x => x.seleccionPago == true).reduce((sum, element) => sum + Number(element.importe), 0)
    this.movimientosBonificaciones = []
    data.filter(x => x.seleccionPago == true).forEach(element => {
      let movimientoDetailDto = new MovimientoDetailDto
      movimientoDetailDto.descripcion = element.descripcion == "" ? "" : element.descripcion
      movimientoDetailDto.id = Math.floor(Math.random() * (10000 - 10 + 1) + 1)
      movimientoDetailDto.seleccionPago = true
      movimientoDetailDto.periodoMes = new Date().getMonth() + 1
      movimientoDetailDto.periodoAnio = new Date().getFullYear()
      movimientoDetailDto.periodo = 0
      let fechaEmision = isFalsy(this.datepipe.transform(new Date, 'yyyy-MM-dd')) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
      movimientoDetailDto.fechaEmision = isFalsy(fechaEmision) ? "" : fechaEmision;
      let fechaPago = isFalsy(this.datepipe.transform(new Date, 'yyyy-MM-dd')) ? "" : this.datepipe.transform(new Date, 'yyyy-MM-dd')
      movimientoDetailDto.fechaPago = isFalsy(fechaPago) ? "" : fechaPago;
      movimientoDetailDto.tipoMovimiento = { id: TiposMovimiento.Bonificaciones, descripcion: "Bonificación", resta: false, editable: false, esAnulable: true }
      movimientoDetailDto.importe = Math.round(element.importe*100)/100
      movimientoDetailDto.bonificacion = element.bonificacion

      this.movimientosBonificaciones.push(movimientoDetailDto)
    });
  }

  editarBonificaciones() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = this.movimientosBonificaciones
    dialogConfig.width = "700px";
    let dialogRef = this.dialog.open(DialogEditarBonificacionesComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == AccionesDialog.Aceptar) {
        this.cancularMontoBonificaciones(result.data as MovimientoDetailDto[])
      }

      if (result.event == AccionesDialog.Cancelar) {
        this.movimientosBonificaciones = result.data as MovimientoDetailDto[]
      }

    });
  }
  //#endregion Bonificaciones

  //#region PeriodosContrato

  getDeudaPeriodosContrato(idContrato: number) {
    this.spinner.show("spNuevoRecibo");
    this.contratoService.getPeriodosContratoByIdContrato(idContrato).subscribe(
      data => {
        this.spinner.hide("spNuevoRecibo");
        this.getPendingDebtInquilinoResponse.periodosContrato = data
      },
      error => {
        this.spinner.hide("spNuevoRecibo");
        this.snackBar.showError(error, "Error");
      }
    )
  }

  contadorPeriodoContratoAPagar() {
    return this.getPendingDebtInquilinoResponse.periodosContrato.filter(x => x.seleccionPago == true).length
  }

  editarPeriodosContrato() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "90%";
    dialogConfig.data = { periodos: this.getPendingDebtInquilinoResponse.periodosContrato, contrato: this.contrato }
    let dialogRef = this.dialog.open(DialogEditarPeriodosContratoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      switch (result.event) {
        case AccionesDialog.Agregar:
          this.getPendingDebtInquilinoResponse.periodosContrato.forEach(element => {
            element.seleccionPago = false
          });
          if (result.data) {
            this.getPendingDebtInquilinoResponse.periodosContrato.forEach(element => {
              for (let index = 0; index < result.data.length; index++) {
                let element2 = result.data[index];
                if (element.id == element2.id) {
                  element.seleccionPago = true
                  break
                }
              }
            });
            this.totalMontoAlquiler = InquilinoController.calcularMontoEntregadoAlquiler(result.data)
          }
          break;
        case AccionesDialog.Cancelar:
          break;

        default:
          break;
      }


    });
  }
  //#endregion PeriodosContrato

  //#region Pagos
  agregarPago() {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.width = "600px"
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      event: AccionesDialog.Agregar,
      saldo: this.totalEntregaInquilino - this.calcularTotales() + this.totalSaldoAnterior,
      idPersona: this.datos.contrato.inquilino.persona.id
    }

    let dialogRef = this.dialog.open(DialogNuevoPagoComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Cancelar) {
      }
      if (result.event == AccionesDialog.Agregar) {
        this.pagos.push(result.data as PagoDto)
        this.dataSource.data.push(result.data as PagoDto)
        this.dataSource.data = this.dataSource.data.filter((value: any, key: any) => {
          return true;
        });
      }
      this.totalEntregaInquilino = this.pagos.reduce((sum, element) => sum + Number(element.importe), 0);

      this.data = this.dataSource.data.length >= 1 ? true : false
    });
  }

  eliminarPago(pago: PagoDto) {
    this.dataSource.data = this.dataSource.data.filter((value, key: any) => {
      return value != pago
    });
    this.pagos.forEach((element, index) => {
      if (element == pago)
        this.pagos.splice(index, 1);
    });
    this.totalEntregaInquilino = this.pagos.reduce((sum, element) => sum + Number(element.importe), 0);
    this.data = this.dataSource.data.length >= 1 ? true : false
  }

  //#endregion

}
