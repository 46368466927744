import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxSpinnerService } from 'ngx-spinner';
import { DescargarComprobantesComponent } from 'src/app/views/comprobantes/descargar-comprobantes/descargar-comprobantes.component';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { GetSummaryInquilinoResponse } from '@models/inquilino/getSummaryInquilinoResponse.model';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-dialog-generar-resumen',
  templateUrl: './dialog-generar-resumen.component.html',
  styleUrls: ['./dialog-generar-resumen.component.css'],
  providers: [DatePipe]
})
export class DialogGenerarResumenComponent implements OnInit {

  formBuilder: FormBuilder = new FormBuilder;
  formGenerarResumen: FormGroup;
  persona!: PersonaDetailDto;
  showCorreo: boolean = false;
  get f() { return this.formGenerarResumen.controls; }
  contrato!: ContratoDetailDto;
  constructor(
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<DialogGenerarResumenComponent>,
    public inquilinoService: InquilinosService,
    public personaService: PersonasService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private toastService: HotToastService
  ) {

    this.formGenerarResumen = this.formBuilder.group({
      FechaResumen: [new Date, Validators.compose([Validators.required])],
      EnvioMail: [false],
      CorreoInquilino: [""],
      IncluyePeriodoPosterior: [false]
    });
  }

  ngOnInit(): void {
    this.contrato = this.data.contrato;

  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  enviarResumenMail() { }

  generarResumen() {
    const idContrato = this.contrato.id
    const fechaGeneracion = this.datepipe.transform(this.formGenerarResumen.controls["FechaResumen"].value, 'YYYY-MM-dd');
    const enableSendMail = this.formGenerarResumen.controls['EnvioMail'].value
    const enableIncluyePeriodoPosterior = this.formGenerarResumen.controls['IncluyePeriodoPosterior'].value
    const emailInquilino = this.formGenerarResumen.controls['CorreoInquilino'].value == "" ? null : this.formGenerarResumen.controls['CorreoInquilino'].value

    if (fechaGeneracion != null && enableSendMail == false && enableIncluyePeriodoPosterior == false)
      this.getSummaryInquilino(idContrato, fechaGeneracion, enableIncluyePeriodoPosterior)

    if (fechaGeneracion != null && enableSendMail == true && enableIncluyePeriodoPosterior == false)
      this.getSummaryInquilinoAndSendEmail(idContrato, fechaGeneracion, enableIncluyePeriodoPosterior, emailInquilino)

    if (fechaGeneracion != null && enableSendMail == false && enableIncluyePeriodoPosterior == true)
      this.getSummaryInquilino(idContrato, fechaGeneracion, enableIncluyePeriodoPosterior)

    if (fechaGeneracion != null && enableSendMail == true && enableIncluyePeriodoPosterior == true)
      this.getSummaryInquilinoAndSendEmail(idContrato, fechaGeneracion, enableIncluyePeriodoPosterior, emailInquilino)

  }

  getSummaryInquilino(idContrato: number, fechaGeneracion: string, incluirPeriodoPosterior: boolean) {
    this.spinner.show("spResumen");
    this.inquilinoService.getSummaryInquilino(idContrato, fechaGeneracion, incluirPeriodoPosterior)
      .subscribe(
        async data => {
          this.spinner.hide("spResumen");
          if (!isFalsy(data))
            this.openDialogDescargarRecibo(data)
        },
        error => {
          this.spinner.hide("spResumen");
          this.snackBar.showError(error, "Error");
        })
  }

  getSummaryInquilinoAndSendEmail(idContrato: number, fechaGeneracion: string, incluirPeriodoPosterior: boolean, email: string | null) {
    this.spinner.show("spResumen");
    this.inquilinoService.getSummaryInquilinoAndSendEmail(idContrato, fechaGeneracion, incluirPeriodoPosterior, email)
      .subscribe(
        async data => {
          this.spinner.hide("spResumen");
          this.snackBar.showSuccess("Correo enviado correctamente.", "Error");
        },
        error => {
          this.spinner.hide("spResumen");
          this.snackBar.showError(error, "Error");
        })
  }

  checkEnvioMail(event: any) {
    if (event == true) {
      this.showCorreo = true
      this.getEmailPersonaByIdInquilino(this.contrato.inquilino.id)
    }
    else {
      this.formGenerarResumen.controls["CorreoInquilino"].setValue("")
      this.showCorreo = false
    }

  }

  getEmailPersonaByIdInquilino(idInquilino: number) {
    this.spinner.show("spBusquedaCorreo");
    this.inquilinoService.getEmailPersonaByIdInquilino(idInquilino).subscribe(
      data => {
        this.spinner.hide("spBusquedaCorreo");
        if (!isFalsy(data))
          this.formGenerarResumen.controls["CorreoInquilino"].setValue(data.replace(/['"]+/g, ''))
      },
      error => {
        this.spinner.hide("spBusquedaCorreo");
        this.snackBar.showError(error, "Error");
      })
  }


  openDialogDescargarRecibo(getSummaryInquilinoResponse: GetSummaryInquilinoResponse) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "400px";
    let archivoDto = new ArchivoDto
    archivoDto.content = getSummaryInquilinoResponse.content
    archivoDto.nombre = getSummaryInquilinoResponse.fileName
    dialogConfig.data = archivoDto

    let dialogRef = this.dialog.open(DescargarComprobantesComponent,
      dialogConfig);
    this.dialogRef.close({ event: AccionesDialog.Cancelar })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.event == AccionesDialog.Aceptar) {
        this.dialogRef.close({ event: AccionesDialog.Cancelar })
      }
    });
  }
}
