import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { PropietariosModule } from 'src/app/modules/propietarios.module';
import { CreateMovimientoComponent } from '../views/movimientos/create-movimiento/create-movimiento.component';
import { DialogCreateMovimientoComponent } from '../views/movimientos/dialog-create-movimiento/dialog-create-movimiento.component';
import { DialogUpdateMovimientoComponent } from '../views/movimientos/dialog-update-movimiento/dialog-update-movimiento.component';
import { ListMovimientosComponent } from '../views/movimientos/list-movimientos/list-movimientos.component';
import { NavPanelMovimientosComponent } from '../views/movimientos/nav-panel-movimientos/nav-panel-movimientos.component';
import { PersonasModule } from './personas.module';
import { PropiedadesModule } from './propiedades.module';
import { CardContratoComponent } from '@views/contratos/card-contrato/card-contrato.component';


@NgModule({
  declarations: [
    NavPanelMovimientosComponent,
    CreateMovimientoComponent,
    ListMovimientosComponent,
    DialogCreateMovimientoComponent,
    DialogUpdateMovimientoComponent,
    CardContratoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PersonasModule,
    PropiedadesModule,
    PropietariosModule,

  ],
  exports:[
    NavPanelMovimientosComponent,
    CreateMovimientoComponent,
    ListMovimientosComponent,
    DialogCreateMovimientoComponent,
    DialogUpdateMovimientoComponent,
    CardContratoComponent
    ]

})
export class MovimientoModule { }
