import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog, RangoFechasValores } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { isFalsy } from 'utility-types';
import { DatePipe } from '@angular/common';
import { PeriodoContratoDetailDto } from '@models/periodos-contrato/periodoContratoDetailDto.model';
import { Genericos } from '@models/base/genericos.model';
import { PeriodosContratoService } from '@services/periodos-contrato/periodos-contrato.service';
import { PeriodoContratoFilter } from '@models/periodos-contrato/periodoContratoFilter.model';
import { DialogVerPeriodoContratoDetailDtoComponent } from '../dialog-ver-periodo-contrato-detail-dto/dialog-ver-periodo-contrato-detail-dto.component';
import { BaseDto } from '@models/base/baseDto.model';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DATE_FORMATS = {
  default: {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      monthYearAriaLabel: 'MMM YYYY',
      yearLabel: 'YYYY',
      yearAriaLabel: 'YYYY',
    },
  },
  monthYear: {
    parse: {
      dateInput: 'MM/YYYY',
    },
    display: {
      dateInput: 'MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      monthYearAriaLabel: 'MMM YYYY',
      yearLabel: 'YYYY',
      yearAriaLabel: 'YYYY',
    },
  },
};

@Component({
  selector: 'app-periodo-contrato',
  templateUrl: './periodo-contrato.component.html',
  styleUrls: ['./periodo-contrato.component.css'],
  providers: [DatePipe,
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS.default },
  ],
})
export class PeriodoContratoComponent implements OnInit {

  dataSource = new MatTableDataSource<PeriodoContratoDetailDto>();
  obj!: undefined
  displayedColumns: string[] = [];
  data = false
  lstRangoFecha = Genericos.lstRangoFecha
  form: FormGroup;
  formBuilder = new FormBuilder
  rangoFechaAjuste: number = RangoFechasValores.Hoy // Hoy
  rangoFechaVencimiento: number = RangoFechasValores.Hoy // Hoy
  rangoFechaLiquidacion: number = RangoFechasValores.Hoy // Hoy
  rangoFechaPago: number = RangoFechasValores.Hoy // Hoy
  @ViewChild(MatTable, { static: true }) table!: MatTable<PeriodoContratoDetailDto>;

  private paginator!: MatPaginator;
  private sort!: MatSort;
  lstTiposAjusteContrato!: TipoAjusteContratoDto[];
  contrato: any;
  contratoDetailDto: BaseDto | null = null;
  lstPeriodosContrato!: PeriodoContratoDetailDto[];
  get f() { return this.form.controls; }


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }



  constructor(
    public dialog: MatDialog,
    private periodosContratoService: PeriodosContratoService,
    private snackBar: SnackBarService,
    private tipoAjusteContratoService: TipoAjusteContratoService,

    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private datepipe: DatePipe,

  ) {

    this.form = this.formBuilder.group({
      TiposAjusteContrato: [Validators.compose([Validators.required,])],
      FechaAjusteDesde: [],
      FechaAjusteHasta: [],
      FechaVencimientoDesde: [],
      FechaVencimientoHasta: [],
      FechaLiquidacionDesde: [],
      FechaLiquidacionHasta: [],
      FechaPagoDesde: [],
      FechaPagoHasta: [],
      PeriodoMes: [],
      PeriodoAnio: [],
    })

    breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['contrato','periodo', 'accion'] :
        ['contrato', 'periodo','fechaVencimiento', 'fechaAjuste', 'valorAjuste', 'indiceAjusteContrato', 'importeAlquiler', 'fechaPago', 'accion']
    });
  }


  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }

    this.dataSource.filterPredicate = (data, filter) => {
      const transformedData = (data.fechaPago + '').toLowerCase(); // Ajusta 'columnToFilter' según tus necesidades
      return transformedData.includes(filter);
    };

    this.dataSource.filterPredicate = (data, filter) => {
      const transformedData = (data.fechaAjuste + '').toLowerCase(); // Ajusta 'columnToFilter' según tus necesidades
      return transformedData.includes(filter);
    };
  }

  ngOnInit() {
    this.getAllTipoAjusteContrato();

  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllByFilter(periodoContratoFilter: PeriodoContratoFilter) {
    this.spinner.show()
    this.periodosContratoService.getPeriodosContratoListadoByFilter(periodoContratoFilter)
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstPeriodosContrato = data

          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }


  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.form.controls["Periodo"].value;
    ctrlValue.year(normalizedYear.year());
    // this.date.setValue(ctrlValue);
    this.form.controls["Periodo"].setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.controls["Periodo"].value;
    ctrlValue.month(normalizedMonth.month());
    this.form.controls["Periodo"].setValue(ctrlValue);
    datepicker.close();
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  selectRangoFechaAjuste(event: any) {
    this.rangoFechaAjuste = event.value
    if (this.rangoFechaAjuste == RangoFechasValores.Todos) {
      this.form.controls["FechaAjusteDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaAjuste)))
      this.form.controls["FechaAjusteHasta"].setValue(new Date((new Date().getTime() + this.rangoFechaAjuste)))
    }
    else {
      this.form.controls["FechaAjusteDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaAjuste)))
      this.form.controls["FechaAjusteHasta"].setValue(new Date((new Date().getTime())))
    }
  }

  selectRangoFechaVencimiento(event: any) {
    this.rangoFechaVencimiento = event.value
    if (this.rangoFechaAjuste == RangoFechasValores.Todos) {
      this.form.controls["FechaVencimientoDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaVencimiento)))
      this.form.controls["FechaVencimientoHasta"].setValue(new Date((new Date().getTime() + this.rangoFechaVencimiento)))
    }
    else {
      this.form.controls["FechaVencimientoDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaVencimiento)))
      this.form.controls["FechaVencimientoHasta"].setValue(new Date((new Date().getTime())))
    }
  }

  selectRangoFechaLiquidacion(event: any) {
    this.rangoFechaLiquidacion = event.value
    if (this.rangoFechaAjuste == RangoFechasValores.Todos) {
      this.form.controls["FechaLiquidacionDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaLiquidacion)))
      this.form.controls["FechaLiquidacionHasta"].setValue(new Date((new Date().getTime() + this.rangoFechaLiquidacion)))
    }
    else {
      this.form.controls["FechaLiquidacionDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaLiquidacion)))
      this.form.controls["FechaLiquidacionHasta"].setValue(new Date((new Date().getTime())))
    }
  }

  selectRangoFechaPago(event: any) {
    this.rangoFechaPago = event.value
    if (this.rangoFechaPago == RangoFechasValores.Todos) {
      this.form.controls["FechaPagoDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaPago)))
      this.form.controls["FechaPagoHasta"].setValue(new Date((new Date().getTime() + this.rangoFechaPago)))
    }
    else {
      this.form.controls["FechaPagoDesde"].setValue(new Date((new Date().getTime() - this.rangoFechaPago)))
      this.form.controls["FechaPagoHasta"].setValue(new Date((new Date().getTime())))
    }
  }

  getTotalAlquiler(): number {
    let valor = 0
    valor = this.lstPeriodosContrato.map(t => t.importeAlquiler).reduce((acc, value) => acc + value, 0) as number;
    return valor
  }

  openDialog(periodoContratoDetail: PeriodoContratoDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = periodoContratoDetail,
      dialogConfig.width = "50%"

    const dialogRef = this.dialog.open(DialogVerPeriodoContratoDetailDtoComponent, dialogConfig);
  }

  selectContrato(baseDto: BaseDto | null) {
    if (!isFalsy(baseDto))
      this.contratoDetailDto = baseDto
  }


  filtrar() {
    let periodoContratoFilter = new PeriodoContratoFilter

    //Fijos
    let fechaAjusteDesde = this.datepipe.transform(this.form.controls["FechaAjusteDesde"].value, 'yyyy-MM-dd') as string;
    let fechaAjusteHasta = this.datepipe.transform(this.form.controls["FechaAjusteHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    if (fechaAjusteDesde) {
      fechaAjusteDesde = fechaAjusteDesde + ' 00:00:00';
      fechaAjusteHasta = fechaAjusteHasta + ' 23:59:59';
    }

    //Fijos
    let fechaVencimientoDesde = this.datepipe.transform(this.form.controls["FechaVencimientoDesde"].value, 'yyyy-MM-dd') as string;
    let fechaVencimientoHasta = this.datepipe.transform(this.form.controls["FechaVencimientoHasta"].value, 'yyyy-MM-dd') as string;
    if (fechaVencimientoDesde) {
      // Ajustar la hora para la fecha desde
      fechaVencimientoDesde = fechaVencimientoDesde + ' 00:00:00';
      fechaVencimientoHasta = fechaVencimientoHasta + ' 23:59:59';
    }

    //Fijos
    let fechaLiquidacionDesde = this.datepipe.transform(this.form.controls["FechaLiquidacionDesde"].value, 'yyyy-MM-dd') as string;
    let fechaLiquidacionHasta = this.datepipe.transform(this.form.controls["FechaLiquidacionHasta"].value, 'yyyy-MM-dd') as string;
    if (fechaLiquidacionDesde) {
      // Ajustar la hora para la fecha desde
      fechaLiquidacionDesde = fechaLiquidacionDesde + ' 00:00:00';
      fechaLiquidacionHasta = fechaLiquidacionHasta + ' 23:59:59';
    }

    //Fijos
    let fechaPagoDesde = this.datepipe.transform(this.form.controls["FechaPagoDesde"].value, 'yyyy-MM-dd') as string;
    let fechaPagoHasta = this.datepipe.transform(this.form.controls["FechaPagoHasta"].value, 'yyyy-MM-dd') as string;
    if (fechaPagoDesde) {
      // Ajustar la hora para la fecha desde
      fechaPagoDesde = fechaPagoDesde + ' 00:00:00';
      fechaPagoHasta = fechaPagoHasta + ' 23:59:59';
    }


    periodoContratoFilter.periodoMes = this.form.controls["PeriodoMes"].value
    periodoContratoFilter.periodoAnio = this.form.controls["PeriodoAnio"].value

    //Variables
    let idsTipoAjusteContrato = this.form.controls["TiposAjusteContrato"].value

    periodoContratoFilter.fechaAjusteDesde = fechaAjusteDesde
    periodoContratoFilter.fechaAjusteHasta = fechaAjusteHasta
    periodoContratoFilter.idsTipoAjusteContrato = idsTipoAjusteContrato
    //periodoContratoFilter.idContrato  = this.contrato.IdContrato
    periodoContratoFilter.fechaVencimientoDesde = fechaVencimientoDesde
    periodoContratoFilter.fechaVencimientoHasta = fechaVencimientoHasta
    periodoContratoFilter.fechaLiquidacionDesde = fechaLiquidacionDesde
    periodoContratoFilter.fechaLiquidacionHasta = fechaLiquidacionHasta
    periodoContratoFilter.fechaPagoDesde = fechaPagoDesde
    periodoContratoFilter.fechaPagoHasta = fechaPagoHasta

    if(this.contratoDetailDto)
      periodoContratoFilter.idContrato = this.contratoDetailDto.id

    this.spinner.show()
    this.periodosContratoService.getPeriodosContratoListadoByFilter(periodoContratoFilter)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )
  }

  clearDateControls() {
    this.form.patchValue({
      FechaAjusteDesde: null,
      FechaAjusteHasta: null,
      FechaVencimientoDesde: null,
      FechaVencimientoHasta: null,
      FechaLiquidacionDesde: null,
      FechaLiquidacionHasta: null,
      FechaPagoDesde: null,
      FechaPagoHasta: null,
      PeriodoMes: null,
      PeriodoAnio: null
    });

  }


  getAllTipoAjusteContrato() {
    this.spinner.show("spBusquedaTipoAjusteContrato")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.lstTiposAjusteContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
          // Obtener todos los IDs de los tipos de ajuste
          const idsSeleccionados = this.lstTiposAjusteContrato.map(tipo => tipo.id);

          // Establecer el valor del FormControl después de la creación del formulario
          this.form.get('TiposAjusteContrato')?.setValue(idsSeleccionados);
        },
        error => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion
}
