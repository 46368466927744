<app-btn-nuevo-usuario></app-btn-nuevo-usuario>
<br/>
<br/>
<mat-card *appRole="['Usuarios.Ver']" >
    <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-slide-toggle [(ngModel)]="isChecked" style="margin-left: 10px;" color="accent" (click)="checked(isChecked)">
        Activos
    </mat-slide-toggle>

    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>

    <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->


        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nombre de usuario </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario">
                {{usuario.name | lowercase}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="nombreCompleto">
            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Completo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario"><span>
              {{usuario.persona.nombreCompleto}}
              <button mat-icon-button matTooltip="Editar persona" (click)="editarPersona(usuario)" color="primary">
                <mat-icon>rate_review</mat-icon>
              </button></span>
            </mat-cell>
        </ng-container>
        <!-- Fecha Nacimiento  Column -->
        <ng-container matColumnDef="fechaNacimiento">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Nacimiento </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario"> {{usuario.persona.fechaNacimiento | date:'dd/MM/yyyy'}}</mat-cell>
        </ng-container>
        <!-- Correo  Column -->
        <ng-container matColumnDef="correo">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario">
                <a *ngIf="usuario.persona.correo" href="mailto:{{usuario.persona.correo}}">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-envelope-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z" />
                </svg> {{usuario.persona.correo}} </a>
            </mat-cell>
        </ng-container>
        <!-- Permiso  Column -->
        <ng-container matColumnDef="rol">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Rol </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario"> {{usuario.rol.descripcion}}</mat-cell>
        </ng-container>
        <!-- Estado  Column -->
        <ng-container matColumnDef="estado">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario">
                <div *ngIf="usuario.estado == false">
                    <span class="badge bg-success text-light">Activo</span>
                </div>
                <div *ngIf="usuario.estado == true">
                    <span class="badge bg-danger text-light">Baja</span>
                </div>
            </mat-cell>
        </ng-container>

        <!-- Accion Column -->
        <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let usuario" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button> -->
                    <button mat-menu-item [routerLink]="['/usuarios',usuario.id]">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                    <button mat-menu-item (click)="openDialog('Eliminar',usuario)">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                </mat-menu>
            </mat-cell>
        </ng-container>



        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


    </table>
    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
</mat-card>
