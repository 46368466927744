import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { ChartDto } from '@models/dashboard/graficos/chartDto.model';
import { GraficosService } from '@services/graficos/graficos.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})

export class BarChartComponent implements OnInit, AfterViewInit {

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'pie';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: SingleDataSet = [];
  constructor(
    private graficosService: GraficosService
  ) {
  }

  ngOnInit(): void {
    this.graficosService.enviarChartObservable.subscribe(
      x => {
        this.barChartData = x.chartData
        this.barChartLabels = x.chartLabels
        // this.barChartType = x.chartType
        // this.barChartLegend = x.chartLegend
        // this.barChartPlugins = x.chartPlugins
        // this.barChartOptions = x.barChartOptions
      }
    )
  }

  ngAfterViewInit(): void {
    this.graficosService.enviarChartObservable.subscribe(
      x => {
        this.barChartData = x.chartData
        this.barChartLabels = x.chartLabels
        this.barChartType = x.chartType
        this.barChartLegend = x.chartLegend
        this.barChartPlugins = x.chartPlugins
        this.barChartOptions = x.barChartOptions
      }
    )
  }
}
