import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreatePermisoRequest } from '@models/seguridad/permisos/createPermisoRequest.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { UpdatePermisoRequest } from '@models/seguridad/permisos/updatePermisoRequest.motel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<PermisoDto[]> {
    return this.http.get<PermisoDto[]>("/api/Permiso/GetAll");
  }
  getById(id: number): Observable<PermisoDto> {
    return this.http.get<PermisoDto>("/api/Permiso/GetById?id=" + id);
  }

  create(createPermisoRequest: CreatePermisoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Permiso/Create", createPermisoRequest);
  }

  update(updatePermisoRequest: UpdatePermisoRequest): Observable<any> {
    return this.http.put<any>("/api/Permiso/Update", updatePermisoRequest);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>("/api/Permiso/Delete?id="+id);
  }
}
