<div class="row content" *appRole="['Documentos.Ver']">
    <div class="col-sm-2">
        <div class="list-group">
            <div class="list-group">
                <button type="button" class="list-group-item list-group-item-action active">
                    <div class="with-icon">
                    <mat-icon>folder</mat-icon> <span class="aligned-with-icon">Documentos</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-10">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false" #mapanel="matExpansionPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Datos documento</b>
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">folder</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
