<div *appRole="['MisGarantias.Ver']">

<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

<table mat-table [dataSource]="dataSource" [hidden]=!data class="mat-elevation-z8">
    <!-- <ng-container matColumnDef="id">
        <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto"> {{garantiaSummaryDto.id}} </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="persona">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Persona </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto">
            <a (click)="editaPersonaGarante(garantiaSummaryDto.persona.id)">
                <b>{{garantiaSummaryDto.persona.descripcion}} </b></a>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tipoGarantia">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Tipo Garantia </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto"> {{garantiaSummaryDto.tipoGarantia.descripcion}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="nroFolio">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Nro. Folio </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto"> {{garantiaSummaryDto.nroFolio}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="razonSocial">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Razon Social </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto"> {{garantiaSummaryDto.razonSocial}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="cuitCuil">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Cuit-Cuil </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto"> {{garantiaSummaryDto.cuitCuil}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="sueldo">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Sueldo </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantiaSummaryDto"> {{garantiaSummaryDto.sueldo | currency}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="validacion">
        <mat-header-cell mat-header-cell *matHeaderCellDef> Validada </mat-header-cell>
        <mat-cell mat-cell *matCellDef="let garantia">
            <mat-slide-toggle [disabled]="true" [checked]="garantia.activo"></mat-slide-toggle>
        </mat-cell>
    </ng-container>

    <!-- Accion Column -->
    <ng-container matColumnDef="accion">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let garantiaSummaryDto" class="action-link">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item color="primary">
              <a [routerLink]="['/garantias',garantiaSummaryDto.id]"  matTooltip="Editar"
              ><mat-icon color="primary" >edit</mat-icon>Editar</a>
            </button>
          </mat-menu>
      </mat-cell>
  </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>

</table>
<div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
</div>
<mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
</mat-paginator>
</div>
