import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { BonificacionDto } from '@models/bonificaciones/bonificacionDto.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { BonificacionesService } from '@services/bonificaciones/bonificaciones.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.mode';
import { DatePipe } from '@angular/common';
import { MovimientosService } from '@services/movimientos/movimientos.service';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogCreateMovimientoComponent } from '../dialog-create-movimiento/dialog-create-movimiento.component';
import { AccionesDialog } from '@models/base/identificadores.model';
import { isFalsy } from 'utility-types';
import { UtilidadesController } from 'src/app/controllers/utilidades.controller';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';
import { MatSelectChange } from '@angular/material/select';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-create-movimiento',
  templateUrl: './create-movimiento.component.html',
  styleUrls: ['./create-movimiento.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    [DatePipe],
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateMovimientoComponent implements OnInit {
  @Input() contratoDetailDto!: ContratoDetailDto
  lstTiposMovimiento!: TipoMovimientoDto[];
  lstBonificaciones!: BonificacionDto[];
  movimientoForm: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  propiedad!: BaseDto | null;
  inquilinoDto!: InquilinoDto | null;
  propietario!: PersonaPropietarioDto | null;
  classContrato = "full-width-x100"
  get f() { return this.movimientoForm.controls; }
  step = 0;
  selectedTipoMovimiento:TipoMovimientoDto = new TipoMovimientoDto
  constructor(
    private dialogRef: MatDialogRef<DialogCreateMovimientoComponent>,
    private bonificacionService: BonificacionesService,
    private tiposMoviemientoService: TiposMoviemientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public datepipe: DatePipe,
    private movimientosService: MovimientosService,
    private contratoService: ContratosService

  ) {
    this.movimientoForm = this.formBuilder.group({
      //Movimiento
      TipoMovimiento: ["", Validators.compose([Validators.required])],
      Descripcion: [""],
      Importe: ["", Validators.compose([Validators.required])],
      Periodo: [moment()],
      NroContrato: [""],
    })
  }

  ngOnInit(): void {
    this.getAllTiposMovimientos()
    if (!isFalsy(this.contratoDetailDto))
      this.getContrato(this.contratoDetailDto.id)
  }

  onSubmit() {
    let createMovimientoRequest = this.setCreateMovimientoRequest()
    if (createMovimientoRequest) {
      this.createMovimiento(createMovimientoRequest)
    }
  }

  setCreateMovimientoRequest(): CreateMovimientoRequest {
    let tipoMovimientoId = this.movimientoForm.controls["TipoMovimiento"].value
    let descripcion = this.movimientoForm.controls["Descripcion"].value == "" ? null : this.movimientoForm.controls["Descripcion"].value
    let importe = this.movimientoForm.controls["Importe"].value == "" ? null : parseFloat(this.movimientoForm.controls["Importe"].value)
    let periodoAnio = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'yyyy')
    let periodoMes = this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM') == "" ? null : this.datepipe.transform(this.movimientoForm.controls["Periodo"].value, 'MM')
    let periodo = 0
    let contratoId = isFalsy(this.contratoDetailDto) ? null : this.contratoDetailDto.id
    let propiedadId = isFalsy(this.propiedad) ? null : this.propiedad.id
    let inquilinoId = isFalsy(this.inquilinoDto) ? null : this.inquilinoDto.id
    let propietarioId = isFalsy(this.propietario) ? null : this.propietario.id_Propietario
    let fechaEmision = this.datepipe.transform(new Date, 'yyyy-MM-dd');

    let createMovimientoRequest: CreateMovimientoRequest = new CreateMovimientoRequest
    createMovimientoRequest.descripcion = descripcion
    createMovimientoRequest.idTipoMovimiento = tipoMovimientoId
    createMovimientoRequest.importe = importe
    createMovimientoRequest.periodo = periodo
    createMovimientoRequest.periodoAnio = isFalsy(periodoAnio) ? null : parseInt(periodoAnio)
    createMovimientoRequest.periodoMes = isFalsy(periodoMes) ? null : parseInt(periodoMes)
    createMovimientoRequest.idContrato = contratoId
    createMovimientoRequest.idPropiedad = propiedadId
    createMovimientoRequest.idInquilino = inquilinoId
    createMovimientoRequest.idPropietario = propietarioId
    createMovimientoRequest.fechaEmision = fechaEmision
    createMovimientoRequest.fechaPago = null
    createMovimientoRequest.idBonificacion = null
    createMovimientoRequest.idMovimientoRef = null
    createMovimientoRequest.idPeriodoContrato = null

    return createMovimientoRequest

  }
  createMovimiento(createMovimientoRequest: CreateMovimientoRequest) {
    this.spinner.show("spMovimiento")
    this.movimientosService.create(createMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showSuccess("Movimiento creado correctamente.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  //#region Servicios

  getAllTiposMovimientos() {
    this.spinner.show("spBusquedaTiposMovimiento")
    this.tiposMoviemientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.lstTiposMovimiento = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spBusquedaTiposMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getContrato(id: number) {
    this.spinner.show("spBusquedaContrato")
    this.contratoService.getById(id)
      .subscribe(
        async data => {
          this.spinner.hide("spBusquedaContrato")
          this.contratoDetailDto = data
        },
        error => {
          this.spinner.hide("spBusquedaContrato")
          this.snackBar.showError(error, "Error");
        })
  }

  getBonificaciones() {
    this.bonificacionService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("")
          this.lstBonificaciones = data
        },
        error => {
          this.spinner.hide("")
          this.snackBar.showError(error, "Error");
        })
  }

  selectPropiedad(event: BaseDto | null) {
    this.propiedad = event
  }

  selectContrato(baseDto: BaseDto) {
    this.getContrato(baseDto.id)
  }

  selectInquilino(event: InquilinoDto | null) {
    this.inquilinoDto = event
  }

  selectPropietario(event: PersonaPropietarioDto | null) {
    this.propietario = event
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.year(normalizedYear.year());
    // this.date.setValue(ctrlValue);
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.movimientoForm.controls["Periodo"].value;
    ctrlValue.month(normalizedMonth.month());
    this.movimientoForm.controls["Periodo"].setValue(ctrlValue);
    datepicker.close();
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  trackByItems(index: number, item: any): any { return item; }

}


  //#endregion


