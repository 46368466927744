<mat-button-toggle-group [formControl]="matButtonToggleGroup" name="matButtonToggleGroup" aria-label="Font Style">
    <mat-button-toggle value="general">General</mat-button-toggle>
    <mat-button-toggle value="contratos">Contratos</mat-button-toggle>
    <mat-button-toggle value="propiedades">Propiedades</mat-button-toggle>
    <mat-button-toggle value="clientes">Clientes</mat-button-toggle>
    <mat-button-toggle value="caja">Caja</mat-button-toggle>
</mat-button-toggle-group>

<!-- General -->
<div>
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'general'">
        <app-dashboard-general></app-dashboard-general>
    </div>
</div>

<!-- Contratos -->
<div>
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'contratos'">
        <app-dashboard-contratos></app-dashboard-contratos>
    </div>
</div>

<!-- Propiedades -->
<div>
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'propiedades'">
        <app-dashboard-propiedades></app-dashboard-propiedades>
    </div>
</div>

<!-- Caja -->
<div>
    <div *ngIf="mostrarDashboard && matButtonToggleGroup.value === 'caja'">
        <app-dashboard-caja></app-dashboard-caja>
    </div>
</div>