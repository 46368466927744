<div class="list-group">
    <button type="button" [routerLinkActive]="['active']" routerLink='/configuracion' class="list-group-item list-group-item-action">
      <div class="with-icon">
      <mat-icon >settings_applications</mat-icon><span class="aligned-with-icon"> Parametrización</span>
      </div>
    </button>

    <button type="button" [routerLinkActive]="['active']" routerLink='/empresa' class="list-group-item list-group-item-action">
      <div class="with-icon">
      <mat-icon >apartment</mat-icon><span class="aligned-with-icon">Empresa</span>
      </div>
    </button>
</div>