import { AfterViewInit, Component,  OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UsuariosService } from '@services/usuarios/usuarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PersonasService } from '@services/personas/personas.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit, AfterViewInit {

  data= false
  displayedColumns: string[] = [];
  public usuarios: UsuarioDto[] = [];
  dataSource = new MatTableDataSource<UsuarioDto>();
  isLoading: boolean = true;
  errorMessage: string = "";

  isChecked: boolean = false;

  filterValues = {
    name: '',
    estado: '',
  };

  constructor(
    private usuariosService: UsuariosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private personasService:PersonasService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["name","accion"] :
        ["name", "nombreCompleto", "fechaNacimiento", "correo", "rol", "estado","accion"];
    });


  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  ngOnInit(): void {
    this.listadoUsuarios();
    this.dataSource.filterPredicate = this.createFilter();
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = (data: any, filter: string) => {
      let searchTerms = JSON.parse(filter);
      return data.name.toLowerCase().indexOf(searchTerms.name) !== -1
        && data.estado.toString().toLowerCase().indexOf(searchTerms.estado) !== -1
    }
    return filterFunction;
  }

  checked(isChecked: boolean) {
    if (isChecked == true) {
      this.dataSource.filter = "";
    } else {
      this.filterValues.estado = 'false'
      this.dataSource.filter = JSON.stringify(this.filterValues);
    }
  }

  applyFilter(event: any) {
    this.filterValues.name = (event.target as HTMLInputElement).value;
    this.dataSource.filter = JSON.stringify(this.filterValues);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  listadoUsuarios() {
    this.spinner.show()
    this.usuariosService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = data.sort((a, b) => a.name.toString().localeCompare(b.name.toString())) as UsuarioDto[]
          this.data = this.dataSource.data.length >= 1 ?  true : false
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        })
  }

  editarPersona(usuario:any){
    this.getPersonaById(usuario.persona.id)
  }

  getPersonaById(idPersona: number) {
    this.spinner.show()
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide()
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }


  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%",
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id:number){
    this.spinner.show()
    this.usuariosService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.listadoUsuarios();
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        });
  }
}
