<div class="list-group" *appRole="['Propiedades.Ver']">
    <button type="button" [ngClass]="{'active': propiedadNueva== true}" [routerLinkActive]="['active']" [routerLink]="['/propiedades/'+idPropiedad ]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon>roofing</mat-icon> <span class="aligned-with-icon">Propiedad</span>
    </div>
</button>
<div *appRole="['Servicios.Ver']">
  <button type="button" *ngIf="!propiedadNueva" [routerLinkActive]="['active']" [routerLink]="['/serviciospropiedad/servicio/'+idPropiedad]" class="list-group-item list-group-item-action">
  <div class="with-icon">
    <mat-icon>water_drop</mat-icon> <span class="aligned-with-icon">Servicios</span>

  </div>
</button></div>

<div *appRole="['DetalleServicios.Ver']">
  <button type="button" *ngIf="!propiedadNueva" [routerLinkActive]="['active']" [routerLink]="['/detalleservicios/'+idPropiedad]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon>doorbell</mat-icon> <span class="aligned-with-icon">Detalle Servicios</span>
    </div>
</button>
</div>

<div *appRole="['Documentos.Ver']">

  <button type="button"   *ngIf="!propiedadNueva" [routerLinkActive]="['active']" [routerLink]="['/documentospropiedad/'+idPropiedad]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon>folder</mat-icon> <span class="aligned-with-icon">Documentos</span>
    </div>
  </button>
</div>
<div *appRole="['Contratos.Ver']">
  <button type="button" *ngIf="!propiedadNueva"   [routerLinkActive]="['active']" [routerLink]="['/contratospropiedad/'+idPropiedad]" class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon>gavel</mat-icon> <span class="aligned-with-icon">Contratos</span>
    </div>
  </button>
</div>

</div>
