import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { LocalidadDto } from '@models/localidad-provincia/localidadDto.model';
import { LocalidadBasicDto } from '@models/localidad-provincia/localidadBasicDto.model';
import { ProvinciaDto } from '@models/localidad-provincia/provinciaDto.model';
import { LocalidadesService } from '@services/localidades/localidades.service';
import { ProvinciasService } from '@services/provincias/provincias.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-buscar-localidad',
  templateUrl: './buscar-localidad.component.html',
  styleUrls: ['./buscar-localidad.component.css']
})

export class BuscarLocalidadComponent implements OnInit {
  localidadSelect: EventEmitter<LocalidadDto> = new EventEmitter()
  localidades: LocalidadDto[] = []
  @Input() localidad: LocalidadDto = new LocalidadDto;
  @Input() localidadBasicDto: LocalidadBasicDto = new LocalidadBasicDto;
  @Output() eventoLocalidad = new EventEmitter<LocalidadDto>();
  idProvincia: number = 0;
  idProvinciaLocal?: number;
  lstProvinciaDto: ProvinciaDto[] = [];
  formBuilder: FormBuilder = new FormBuilder;
  localidadForm: FormGroup;
  localidadSelec: number = 0;
  filteredLocalidades?: Observable<any[]>;
  filteredProvincia?: Observable<any[]>;
  provincia!: ProvinciaDto;
  ProvinciaSelec!: ProvinciaDto;
  provinciaSelect!: number;
  get f() { return this.localidadForm.controls; }
  @ViewChild( 'typehead', {read:MatAutocompleteTrigger})  autoTrigger!: MatAutocompleteTrigger;


  constructor(
    private localidadService: LocalidadesService,
    private provinciasService: ProvinciasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService
  ) {

    this.localidadForm = this.formBuilder.group({
      Provincia: ['', Validators.compose([Validators.required])],
      Localidad: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {

    this.localidadForm.controls["Localidad"].disable()

    if (this.localidadBasicDto.id != 0) {
      this.localidad.id = this.localidadBasicDto.id
      this.localidad.departamento.provincia.id = this.localidadBasicDto.provincia.id
      this.getProvincias(this.localidad)
    }
    else {
      this.getAllProvincias()
    }

    if (this.localidad != null)
      this.localidad.id != 0 ? this.getProvincias(this.localidad) : this.getAllProvincias()

  }

  ngOnChanges(): void {
    if (this.idProvincia != 0)
      this.idProvinciaLocal = this.idProvincia

    if (this.localidadBasicDto.id != 0) {
      this.localidad.id = this.localidadBasicDto.id
      this.localidad.departamento.provincia.id = this.localidadBasicDto.provincia.id
      this.localidad.id != 0 ? this.getProvincias(this.localidad) : this.getAllProvincias()
    }
    this.filterLocalidades()
    this.filterProvincias()
  }

  selectValue(event: any) {
    this.eventoLocalidad.emit(event)
  }

  getLocalidadesByProvincia(idProvincia: number) {
    this.spinner.show("spLocalidades")
    this.localidadService.getLocalidadesByProvincia(idProvincia)
      .subscribe(
        data => {
          this.localidades = data
          this.localidadForm.controls["Localidad"].enable()

          this.filterLocalidades()

          this.spinner.hide("spLocalidades")
        },
        error => {
          this.spinner.hide("spLocalidades")
        });
  }


  findLocalidad(val: string) {
    for (let i = 0; i < this.localidades.length; i++) {
      if (this.localidades[i].descripcion.toUpperCase() == val.toUpperCase()) {
        this.localidadSelec = this.localidades[i].id
      }
    }
    return this.localidades.filter((localidad) => new RegExp(val, 'gi').test(localidad.descripcion));
  }

  findProvincia(val: string) {
    for (let i = 0; i < this.lstProvinciaDto.length; i++) {
      if (this.lstProvinciaDto[i].descripcion == val) {
        this.provinciaSelect = this.lstProvinciaDto[i].id
      }
    }
    return this.lstProvinciaDto.filter((provincia) => new RegExp(val, 'gi').test(provincia.descripcion));
  }

  getLocalidades(idProvincia: number, idLocalidad: number) {
    this.spinner.show("spLocalidades")
    this.localidadService.getLocalidadesByProvincia(idProvincia)
      .subscribe(
        data => {
          this.spinner.hide("spLocalidades")
          this.localidades = data;

          const localidad = data.find(x => x.id == idLocalidad) as LocalidadDto
          this.localidadForm.controls["Localidad"].setValue(localidad.descripcion)
          this.localidadForm.controls["Localidad"].enable()
          this.filterLocalidades()
        },
        error => {
          this.spinner.hide("spLocalidades")
          this.snackBar.showError(error, "Error");
        });
  }

  getProvincias(localidad: LocalidadDto) {
    this.spinner.show("spProvincia")
    this.provinciasService.getAllProvincias()
      .subscribe(
        data => {
          this.spinner.hide("spProvincia")
          this.lstProvinciaDto = data

          const provincia = data.find(x => x.id == localidad.departamento.provincia.id) as ProvinciaDto
          this.localidadForm.controls["Provincia"].setValue(provincia.descripcion)

          this.getLocalidades(localidad.departamento.provincia.id, localidad.id)
          this.filterProvincias()
        },
        error => {
          this.spinner.hide("spProvincia")
          this.snackBar.showError(error, "Error");
        });
  }

  getAllProvincias() {
    this.spinner.show("spProvincia")
    this.provinciasService.getAllProvincias()
      .subscribe(
        data => {
          this.spinner.hide("spProvincia")
          this.lstProvinciaDto = data;
          //const santaFe = data.find(x => x.id == 82) as ProvinciaDto
          //Seteo provincia por defecto
          //this.getLocalidadesByProvincia(82)
          //this.localidadForm.controls["Provincia"].setValue(santaFe.descripcion);

          this.filterProvincias()
        },
        error => {
          this.spinner.hide("spProvincia")
          this.snackBar.showError(error, "Error");
        });
  }

  filterLocalidades() {
    this.filteredLocalidades = this.localidadForm.controls["Localidad"].valueChanges.pipe(
      startWith(''),
      map((term: any) => this.findLocalidad(term))
    );
  }

  filterProvincias() {
    this.filteredProvincia = this.localidadForm.controls["Provincia"].valueChanges.pipe(
      startWith(''),
      map((term: any) => this.findProvincia(term))
    );
  }

  trackByItems(index: number, item: any): any { return item; }
}
