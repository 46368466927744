import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { PersonasService } from '@services/personas/personas.service';
import { PropietariosService } from '@services/propietarios/propietarios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-persona-propietaria',
  templateUrl: './buscar-persona-propietaria.component.html',
  styleUrls: ['./buscar-persona-propietaria.component.css']
})
export class BuscarPersonaPropietariaComponent implements OnInit {

  propietarioSelect: EventEmitter<BaseDto> = new EventEmitter()
  public filteredPropietarios!: Observable<PersonaPropietarioDto[]>;
  propietarios: PersonaPropietarioDto[] = []
  entitySelect: PersonaPropietarioDto | null =  null
  sinPropiedad: FormControl = new FormControl(false);
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<PersonaPropietarioDto>();
  @Input() propietarioEntrada: PersonaPropietarioDto = new PersonaPropietarioDto;
  @Input() idPersonaInput: number = 0;
  @Input() showDocumento: boolean = true
  @Input() disable: boolean = false
  @Input() checkSinPropiedad: boolean = false


  constructor(
    private personasService: PersonasService,
    private propietarioService: PropietariosService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.eventEntity = new EventEmitter<PersonaPropietarioDto>();

    this.sinPropiedad.valueChanges.subscribe((valor: boolean) => {
      this.getAllPersonaPropietarios();
    });
  }

  ngOnInit(): void {
    this.sinPropiedad.setValue(this.checkSinPropiedad)

    this.getAllPersonaPropietarios();
    if (this.idPersonaInput != 0) {
      this.getPersonaById(this.idPersonaInput)
      this.entityControl.setValue(this.propietarioEntrada.descripcion)
    }
  }

  selectValue(event: any) {
    if (isFalsy(event)){
      this.entitySelect=null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }


  findOption(val: string) {
    this.spinner.show("spBusquedaPropietaria")
    for (let i = 0; i < this.propietarios.length; i++) {
      if (this.propietarios[i].descripcion.toUpperCase() == val.toUpperCase()) {
        this.eventEntity.emit(this.propietarios[i])
        this.entitySelect = this.propietarios[i]
      }
    }
    this.spinner.hide("spBusquedaPropietaria")
    return this.propietarios.filter(propietario => propietario.descripcion.toLowerCase().includes(val.toLowerCase()));
  }


  getAllPersonaPropietarios() {
    this.spinner.show("spBusquedaPropietaria");
    this.personasService.getAllPersonasPropietarios()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaPropietaria")
          const conPropiedad = this.sinPropiedad.value;
          this.propietarios = data.filter(x => x.titular == conPropiedad)
          this.propietarios = this.propietarios.sort((a, b) => a.descripcion.localeCompare(b.descripcion));

          this.filteredPropietarios = this.entityControl.valueChanges.pipe(
            startWith(''),
            map((term: any) => this.findOption(term))
          );
        },
        error => {
          this.spinner.hide("spBusquedaPropietaria");
          this.snackBar.showError(error, "Error");
        });
  }

  getPersonaById(idPersona: any) {
    this.spinner.show("spBusquedaPropietaria");
    this.propietarioService.getByIdPersona(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaPropietaria");
          const personaPropietarioDto = new PersonaPropietarioDto
          personaPropietarioDto.id = data.persona.id
          personaPropietarioDto.descripcion = data.persona.descripcion
          personaPropietarioDto.id_Propietario = data.id
          this.propietarioEntrada = personaPropietarioDto
        },
        error => {
          this.spinner.hide("spBusquedaPropietaria");
          this.snackBar.showError(error, "Error");
        }
      );
  }

  editarPersona(idPersona:number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spBusquedaPropietaria")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaPropietaria")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spBusquedaPropietaria")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }
}
