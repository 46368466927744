<div class="container-fluid">
    <mat-card *appRole="['Comprobantes.Crear']">
        <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>

        <div class="row">
            <div class="col-12">
                <form [formGroup]="form">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-3">
                                    <mat-form-field class="full-width">
                                        <mat-label>Ingrese rango de fechas</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde" />
                                            <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta" />
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field>
                                        <mat-label>Rangos rapidos</mat-label>
                                        <mat-select placeholder="Seleccione opcion" formControlName="RangoFecha" (selectionChange)="selectRangoFecha($event)">
                                            <mat-option [value]="fecha.id" *ngFor="let fecha of lstRangoFecha;let i=index;trackBy:trackByItems">
                                                {{ fecha.descripcion | titlecase }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <mat-form-field class="example-full-width">
                                        <ngx-spinner [zIndex]="100" name="spBusquedaTiposComprobantes" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                        <mat-label>Tipos Comprobantes</mat-label>
                                        <mat-select placeholder="Seleccione opcion" formControlName="TiposComprobantes">
                                            <mat-option [value]="tipoComprobante.id" *ngFor="let tipoComprobante of lstTiposComprobantes;let i=index;trackBy:trackByItems">
                                                {{ tipoComprobante.descripcion | titlecase }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <mat-form-field class="example-full-width">
                                        <ngx-spinner [zIndex]="100" name="spBusquedaEstadosComprobante" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                        <mat-label>Estados Comprobante</mat-label>
                                        <mat-select placeholder="Seleccione opcion" formControlName="EstadosComprobante">
                                            <mat-option [value]="estadoComprobante.id" *ngFor="let estadoComprobante of lstEstadosComprobante;let i=index;trackBy:trackByItems">
                                                {{ estadoComprobante.descripcion | titlecase }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-contrato (eventEntity)="selectContrato($event)"></app-buscar-contrato>
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-propiedad (eventEntity)="selectPropiedad($event)"></app-buscar-propiedad>&nbsp;&nbsp;
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-persona-propietaria (eventEntity)="selectPropietario($event)" [showDocumento]="false">
                                    </app-buscar-persona-propietaria>&nbsp;&nbsp;
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-persona-inquilino (eventEntity)="selectInquilino($event)">
                                    </app-buscar-persona-inquilino>
                                    &nbsp;&nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button mat-stroked-button color="primary" (click)="buscarComprobantes(numeroContrato)">
                    <mat-icon>filter_alt</mat-icon>
                    Filtrar
                  </button>&nbsp; &nbsp;

                                    <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                                        Filtros Avanzados </mat-slide-toggle>&nbsp; &nbsp;

                                    <button mat-stroked-button color="primary" *ngIf="checkedFiltroAvanzado" (click)="clearForm()">
                    <mat-icon>clear_all</mat-icon>
                    Limpiar
                  </button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- <button mat-button color="primary" [hidden]="!dataVistaCaja || !data" [matMenuTriggerFor]="acciones">
Acciones
</button>
<mat-menu #acciones="matMenu">
<button mat-menu-item [matMenuTriggerFor]="mostrarColumnas">
  Ver/Ocultar Columnas
</button>
</mat-menu> -->

                <mat-menu #mostrarColumnas="matMenu">
                    <form [formGroup]="formColumnas">
                        <mat-checkbox class="checkBoxDinamic" *ngFor="let cd of columnDefinitions;let i=index;trackBy:trackByItems" [formControlName]="cd.def">{{ cd.label }}</mat-checkbox>
                    </form>
                </mat-menu>

                <button mat-button color="primary" [hidden]="!dataVistaCaja || !data" [matMenuTriggerFor]="menuVistas">
          <mat-icon>grid_view</mat-icon>
        </button>
                <mat-menu #menuVistas="matMenu">
                    <button mat-menu-item (click)="
    vistaSimple = false; vistaCaja = true; vistaCajaCompleta = false;vistaDetalle=false
  ">
            <mat-icon [ngClass]="vistaCaja ? 'color-primary' : ''">point_of_sale</mat-icon>Vista caja
          </button>
                    <button mat-menu-item (click)="
    vistaSimple = false; vistaCaja = false; vistaCajaCompleta = true ;vistaDetalle=false
  ">
      <mat-icon [ngClass]="vistaCajaCompleta ? 'color-primary' : ''">view_list</mat-icon>Vista detalle de caja    </button>
                </mat-menu>
                <!-- Vista Caja -->
                <div *ngIf="vistaCaja">
                    <table mat-table [dataSource]="dataSourceCaja" [hidden]="!dataVistaCaja" table matSort matSortStart="desc" class="mat-elevation-z8">
                        <!-- Tipo COmprobante Column -->
                        <ng-container matColumnDef="vctipoComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef><span matTooltip="Tipo comprobante">T</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <mat-icon color="primary" style="font-size: medium" *ngIf="element.tipoComprobante === 'Liquidacion'" matTooltip="Liquidación propietario">roofing
                                </mat-icon>
                                <mat-icon color="primary" style="font-size: medium" *ngIf="element.tipoComprobante === 'Recibo'" matTooltip="Recibo de inquilino">person</mat-icon>
                                <mat-icon color="primary" style="font-size: medium" *ngIf="element.tipoComprobante === 'Resumen'" matTooltip="Resumen de inquilino">vpn_key</mat-icon>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        </ng-container>

                        <!-- Estado -->
                        <ng-container matColumnDef="vcestado">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span matTooltip="Estado">E</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <mat-icon color="warn" style="font-size: medium" *ngIf="element.estado === 'Anulado'" matTooltip="Anulada">block</mat-icon>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vcfechaEmision">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Fecha Emisión</span>
                            </mat-header-cell>
                            <mat-cell td *matCellDef="let element">
                                <span>{{ element.fechaEmision | date: "dd/MM/yyyy" }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> <span><strong>Total</strong></span> </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vcnumeroComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Comprobante</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.numeroComprobante }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vcdescripcion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                                <span> Descripción</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{
                  isFalsy(element.descripcion)
                  ? ""
                  : (element.descripcion | titlecase)
                  }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vcefectivo">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Efectivo</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.efectivo | currency }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef>
                                <span><strong>{{ getTotalEfectivo() | currency }}</strong></span></mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccheques">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span> Cheques</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.cheques | currency }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef>
                                <span><strong>{{ getTotalCheques() | currency }}</strong></span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vcdepositos">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Depósito</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.depositos | currency }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef>
                                <span><strong>{{ getTotalDepositos() | currency }}</strong></span></mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vctransferencias">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Transferencia</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.transferencias | currency }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef>
                                <span><strong>{{ getTotalTransferencias() | currency }}</strong></span></mat-cell>
                        </ng-container>

                        <!-- Total Comprobante Column -->
                        <ng-container matColumnDef="vctotal">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span><strong>Total</strong></span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.total | currency }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef>
                                <span> <strong>{{ getTotal() | currency }}</strong></span></mat-cell>
                        </ng-container>

                        <!-- Total Comprobante Column -->
                        <ng-container matColumnDef="vcfechaAcreditacion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Fec. Acred.</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{
                  isFalsy(element.fechaAcreditacion)
                  ? ""
                  : (element.fechaAcreditacion | date: "dd/MM/yyyy")
                  }}</span>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef></mat-cell>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="vcaccion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Acción</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element" class="action-link">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                                <mat-menu #menu="matMenu">
                                    <button *appRole="['Comprobantes.Ver']" mat-menu-item (click)="openDialog(element.comprobanteDetailDto)">
                    <mat-icon>visibility</mat-icon>
                    Ver
                  </button>
                                    <button [disabled]="element.estado === 'Anulado'" *appRole="['Comprobantes.Eliminar']" mat-menu-item (click)="
            openDialogCancelVoucher(element.comprobanteDetailDto)
          ">
                    <mat-icon>do_not_disturb_on</mat-icon>
                    Anular
                  </button>
                                    <button *appRole="['Comprobantes.Enviar']" mat-menu-item (click)="
            openDialogEnviarCorreoComprobante(
              element.comprobanteDetailDto
            )
          ">
                    <mat-icon>send</mat-icon>
                    <span>Enviar</span>
                  </button>
                                    <button *appRole="['Comprobantes.Descargar']" mat-menu-item (click)="descargarComprobante(element.comprobanteDetailDto)">
                    <mat-icon>file_download</mat-icon>
                    Descargar
                  </button>
                                </mat-menu>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        </ng-container>

                        <mat-header-row mat-header-row *matHeaderRowDef="columnDefinitionsVistaCaja"></mat-header-row>
                        <mat-row mat-row *matRowDef="let row; columns: columnDefinitionsVistaCaja"></mat-row>
                        <mat-footer-row mat-footer-row *matFooterRowDef="columnDefinitionsVistaCaja"></mat-footer-row>
                    </table>

                    <div class="no-data-table">
                        <span class="with-icon" *ngIf="dataSourceCaja.data.length <= 0">
              <mat-icon>search_off</mat-icon>No se encontraron datos para
              mostrar.
            </span>
                    </div>
                    <mat-paginator showFirstLastButtons class="paginator" [hidden]="!dataVistaCaja" [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
                    </mat-paginator>
                </div>

                <!-- Vista Caja Completa -->
                <div *ngIf="vistaCajaCompleta">
                    <table mat-table [dataSource]="dataSourceCajaCompleta" [hidden]="!dataVistaCaja" table matSort matSortStart="desc" class="mat-elevation-z8">
                        <!-- Tipo COmprobante Column -->
                        <ng-container matColumnDef="vcctipoComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef><span matTooltip="Tipo comprobante">T</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <mat-icon color="primary" style="font-size: medium" *ngIf="element.tipoComprobante === 'Liquidación'" matTooltip="Liquidación propietario">roofing
                                </mat-icon>
                                <mat-icon color="primary" style="font-size: medium" *ngIf="element.tipoComprobante === 'Recibo'" matTooltip="Recibo de inquilino">person</mat-icon>
                                <mat-icon color="primary" style="font-size: medium" *ngIf="element.tipoComprobante === 'Resumen'" matTooltip="Resumen de inquilino">vpn_key</mat-icon>
                            </mat-cell>
                        </ng-container>

                        <!-- Estado -->
                        <ng-container matColumnDef="vccestado">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span matTooltip="Estado">E</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <mat-icon color="warn" style="font-size: medium" *ngIf="element.estado === 'Anulado'" matTooltip="Anulada">block</mat-icon>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccfechaEmision">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Fecha Emisión</span>
                            </mat-header-cell>
                            <mat-cell td *matCellDef="let element">
                                <span>{{ element.fechaEmision | date: "dd/MM/yyyy" }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccnumeroComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Comprobante</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.numeroComprobante }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccdescripcion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Descripción</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.descripcion | titlecase }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccimporteComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Importe Cte.</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.importeComprobante | currency }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccformaPago">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Forma Pago</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.formaPago | titlecase }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccimportePago">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Importe Pago</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.importePago | currency }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccfechaAcreditacion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Fec. Acred.</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{
                  isFalsy(element.fechaAcreditacion)
                  ? ""
                  : (element.fechaAcreditacion | date: "dd/MM/yyyy")
                  }}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="vccaccion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Acción</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element" class="action-link">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="openDialog(element.comprobanteDetailDto)">
                    <mat-icon>visibility</mat-icon>
                    Ver
                  </button>
                                    <button mat-menu-item [disabled]="element.estado == 'Anulado'" (click)="
            openDialogCancelVoucher(element.comprobanteDetailDto)
          " *appRole="['Comprobantes.Eliminar']">
                    <mat-icon>do_not_disturb_on</mat-icon>
                    Anular
                  </button>
                                    <button mat-menu-item (click)="
            openDialogEnviarCorreoComprobante(
              element.comprobanteDetailDto
            )
          " *appRole="['Comprobantes.Enviar']">
                    <mat-icon>send</mat-icon>
                    Enviar
                  </button>
                                    <button mat-menu-item (click)="descargarComprobante(element.comprobanteDetailDto)" *appRole="['Comprobantes.Descargar']">
                    <mat-icon>file_download</mat-icon>
                    Descargar
                  </button>
                                </mat-menu>
                            </mat-cell>
                            <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                        </ng-container>

                        <mat-header-row mat-header-row *matHeaderRowDef="columnDefinitionsVistaCajaCompleta"></mat-header-row>
                        <mat-row mat-row *matRowDef="let row; columns: columnDefinitionsVistaCajaCompleta" [ngClass]="row.cabecera ? 'mat-colum-head-color' : ''"></mat-row>
                    </table>

                    <div class="no-data-table">
                        <span class="with-icon" *ngIf="dataSourceCaja.data.length <= 0">
              <mat-icon>search_off</mat-icon>No se encontraron datos para
              mostrar.
            </span>
                    </div>
                    <mat-paginator showFirstLastButtons class="paginator" [hidden]="!dataSourceCajaCompleta" [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </mat-card>
</div>
