<app-btn-nuevo-propiedad *appRole="['Propiedades.Crear']"></app-btn-nuevo-propiedad>
<br>
<br>
<mat-card *appRole="['Propiedades.Ver']">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-lg-2">
        <mat-form-field class="example-full-width">
          <ngx-spinner [zIndex]="100" name="spBusquedaEstadosPropiedad" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Estado propiedad</mat-label>
          <mat-select placeholder="Seleccione opción" multiple formControlName="EstadosPropiedad">
            <mat-option [value]="item.id" *ngFor="let item of lstEstadosContrato; trackBy: trackByItems">
              {{ item.descripcion | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field class="example-full-width">
          <ngx-spinner [zIndex]="100" name="spBusquedaTiposPropiedad" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Tipos propiedad</mat-label>
          <mat-select placeholder="Seleccione opción" multiple formControlName="TiposPropiedad">
            <mat-option [value]="item.id" *ngFor="let item of lstTiposPropiedad; trackBy: trackByItems">
              {{ item.descripcion | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-2">
        <mat-form-field class="example-full-width">
          <ngx-spinner [zIndex]="100" name="spBusquedaPropiedadClase" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Clase propiedad</mat-label>
          <mat-select placeholder="Seleccione opción" multiple formControlName="ClasesPropiedad">
            <mat-option [value]="item.id" *ngFor="let item of lstPropiedadClase; trackBy: trackByItems">
              {{ item.descripcion | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-3">
        <mat-form-field class="full-width">
          <mat-label>Fecha creación</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [disabled]="true" formControlName="FechaAltaDesde" placeholder="Fecha desde">
            <input matEndDate [disabled]="true" formControlName="FechaAltaHasta" placeholder="Fecha hasta">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="col-lg-1 d-flex align-items-center">
        <mat-checkbox formControlName="Activo">Activo</mat-checkbox>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-2">
        <button mat-stroked-button color="primary" (click)="filtrarPropiedades()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>
      </div>
    </div>
  </form>

  <ngx-spinner name="spListadoPropiedad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
    [fullScreen]="false">
  </ngx-spinner>
  <div class="row" *ngIf="dataSource.data.length >= 1">
    <div class="col-lg-2">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="ubicacionFilter" placeholder="Filtro Ubicación">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="col-lg-2">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="localidadFilter" placeholder="Filtro Localidad">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" class="mat-elevation-z8">

    <ng-container matColumnDef="ubicacion">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ubicación</mat-header-cell>
      <mat-cell *matCellDef="let propiedad">
        <ng-container *ngIf="propiedad.ubicacion">
          <span>{{ propiedad.ubicacion | uppercase }}</span>
          <span *ngIf="propiedad.piso">, {{ propiedad.piso | uppercase }}</span>
          <span *ngIf="propiedad.departamento"> - {{ propiedad.departamento | uppercase }}</span>
        </ng-container>
        <ng-container *ngIf="!propiedad.ubicacion">
          <span>Sin ubicación</span>
        </ng-container>
      </mat-cell>
    </ng-container>


    <!-- Propietario -->
    <ng-container matColumnDef="propietario">
      <mat-header-cell *matHeaderCellDef> Propietario </mat-header-cell>
      <mat-cell *matCellDef="let propiedad">
        <ng-container *ngIf="propiedad?.propietario?.length > 0; else noPropietarios">
          <p *ngFor="let prop of propiedad.propietario">{{prop.descripcion | titlecase }}</p>
        </ng-container>
        <ng-template #noPropietarios>
          <span><strong>No hay propietarios</strong></span>
        </ng-template>
      </mat-cell>
    </ng-container>


    <!-- Localidad-->
    <ng-container matColumnDef="localidad">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Localidad </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad">
        <span>{{isFalsy(propiedad.localidad) ? "":
        propiedad.localidad.descripcion | titlecase }}</span></mat-cell>
    </ng-container>

    <!-- Tipo Propiedad-->
    <ng-container matColumnDef="tipoPropiedad">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Tipo Propiedad </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad">
        <span>{{isFalsy(propiedad.tipoPropiedad) ? "":
        propiedad.tipoPropiedad.descripcion | titlecase}}</span></mat-cell>
    </ng-container>


    <!-- Clase Propiedad-->
    <ng-container matColumnDef="propiedadClase">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Clase Propiedad </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad">
        <span>{{isFalsy(propiedad.propiedadClase) ? "":
        propiedad.propiedadClase.descripcion | titlecase}}</span></mat-cell>
    </ng-container>

    <!-- Estado propiedad -->
    <ng-container matColumnDef="estadoPropiedad">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Estado Propiedad
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad">
        <div *ngIf="propiedad.estadoPropiedad.id == estadosPropiedadEnum.ConContrato">
          <span class="badge bg-success text-light">{{isFalsy(propiedad.estadoPropiedad) ? "" :
            propiedad.estadoPropiedad.descripcion}}</span>
        </div>
        <div *ngIf="propiedad.estadoPropiedad.id == estadosPropiedadEnum.SinContrato">
          <span class="badge bg-info text-light">{{isFalsy(propiedad.estadoPropiedad) ? "" :
            propiedad.estadoPropiedad.descripcion}}</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Fecha Alta -->
    <ng-container matColumnDef="fechaAlta">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Alta </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad">
        <span>{{propiedad.fechaAlta | date:('dd/MM/yyyy')}}</span></mat-cell>
    </ng-container>

    <!-- Activo -->
    <ng-container matColumnDef="activo">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Activo </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad">
        <div *ngIf="propiedad.activo==true">
          <span class="badge bg-success text-light">Si</span>
        </div>
        <div *ngIf="propiedad.activo==false">
          <span class="badge bg-danger text-light">No</span>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Accion Column -->
    <ng-container matColumnDef="accion">
      <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let propiedad" class="action-link">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button> -->
          <button *appRole="['Propiedades.Modificar']" mat-menu-item [routerLink]="['/propiedades/'+propiedad.id]">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
          <button *appRole="['Propiedades.Eliminar']" mat-menu-item (click)="openDialog('Eliminar',propiedad)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


  </table>
  <div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos
      para mostrar.</span>
  </div>
  <mat-paginator showFirstLastButtons [pageSize]="20" [hidden]=!data [pageSizeOptions]="[5, 10, 20,50,100,200]">
  </mat-paginator>
</mat-card>
