import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ContratosService } from "@services/contratos/contratos.service";
import { FormasPagoService } from "@services/formas-pago/formas-pago.service";
import { BuscarFormaPagoComponent } from "@views/busquedas/buscar-forma-pago/buscar-forma-pago.component";
import { AccionesContratoComponent } from "@views/contratos/acciones-contrato/acciones-contrato.component";
import { ArchivosTemporalesContratoComponent } from "@views/contratos/archivos-temporales-contrato/archivos-temporales-contrato.component";
import { BtnNuevoContratoComponent } from "@views/contratos/btn-nuevo-contrato/btn-nuevo-contrato.component";
import { ListadoComprobantesContratoComponent } from "@views/contratos/comprobantes-contrato/listado-comprobantes-contrato/listado-comprobantes-contrato.component";
import { ContratosPersonasComponent } from "@views/contratos/contratos-personas/contratos-personas.component";
import { ContratosComponent } from "@views/contratos/contratos.component";
import { ContratoComponent } from "@views/contratos/form-contrato/contrato.component";
import { DialogAdendaContratoComponent } from "@views/contratos/form-editar-contrato/dialog-adenda-contrato/dialog-adenda-contrato.component";
import { DialogGenerarResumenComponent } from "@views/contratos/form-editar-contrato/dialog-generar-resumen/dialog-generar-resumen.component";
import { DialogLiquidacionesGeneradasComponent } from "@views/contratos/form-editar-contrato/dialog-nueva-liquidacion/dialog-liquidaciones-generadas/dialog-liquidaciones-generadas.component";
import { DialogNuevaLiquidacionComponent } from "@views/contratos/form-editar-contrato/dialog-nueva-liquidacion/dialog-nueva-liquidacion.component";
import { DialogVisualizaLiquidacionComponent } from "@views/contratos/form-editar-contrato/dialog-nueva-liquidacion/dialog-visualiza-liquidacion/dialog-visualiza-liquidacion.component";
import { DialogNuevoPeriodoOAdendaComponent } from "@views/contratos/form-editar-contrato/dialog-nuevo-periodo-o-adenda/dialog-nuevo-periodo-o-adenda.component";
import { DialogRescisionContratoComponent } from "@views/contratos/form-editar-contrato/dialog-rescision-contrato/dialog-rescision-contrato.component";
import { FormEditarContratoComponent } from "@views/contratos/form-editar-contrato/form-editar-contrato.component";
import { DialogNuevoPeriodoComponent } from "@views/contratos/form-nuevo-contrato/dialog-nuevo-periodo/dialog-nuevo-periodo.component";
import { FormNuevoContratoComponent } from "@views/contratos/form-nuevo-contrato/form-nuevo-contrato.component";
import { ListadoContratosComponent } from "@views/contratos/listado-contrato/listado-contratos.component";
import { ListadoPeriodosHistoricosComponent } from "@views/contratos/listado-periodos-historicos/listado-periodos-historicos.component";
import { MovimientosContratoComponent } from "@views/contratos/movimientos-contrato/movimientos-contrato.component";
import { NavPanelContratoComponent } from "@views/contratos/nav-panel-contrato/nav-panel-contrato.component";
import { PeriodoshistoricosComponent } from "@views/contratos/periodoshistoricos/periodoshistoricos.component";
import { DialogTipocontratoQuienpagaServicioComponent } from "@views/contratos/tipocontrato-quienpaga-servicio/dialog-tipocontrato-quienpaga-servicio/dialog-tipocontrato-quienpaga-servicio.component";
import { TipocontratoQuienpagaServicioComponent } from "@views/contratos/tipocontrato-quienpaga-servicio/tipocontrato-quienpaga-servicio/tipocontrato-quienpaga-servicio.component";
import { ContratosPropiedadComponent } from "@views/propiedades/contratos-propiedad/contratos-propiedad.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ArchivoTemporalModule } from "./archivo-temporal.module";
import { BancosModule } from "./bancos.module";
import { ComprobantesModule } from "./comprobantes.module";
import { CuentasContableModule } from "./cuentas-contables.module";
import { InquilinosModule } from "./inquilinos.module";
import { LocalidadModule } from "./localidad.module";
import { MovimientoModule } from "./movimiento.module";
import { PersonasModule } from "./personas.module";
import { PropiedadesModule } from "./propiedades.module";
import { SharedModule } from "./shared.module";
import { IndicesAjusteContratoModule } from './indices-ajuste-contrato.module';
import { DialogEditarAdicionalesInquilinoComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-adicionales-inquilino/dialog-editar-adicionales-inquilino.component";
import { DialogNuevoVencimientoServicioComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-adicionales-inquilino/dialog-nuevo-vencimiento-servicio/dialog-nuevo-vencimiento-servicio.component";
import { DialogEditarBonificacionesComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-bonificaciones/dialog-editar-bonificaciones.component";
import { DialogNuevoMovimientoBonificacionComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-bonificaciones/dialog-nuevo-movimiento-bonificacion/dialog-nuevo-movimiento-bonificacion.component";
import { DialogEditarMovimientosComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-movimientos/dialog-editar-movimientos.component";
import { DialogNuevoMovimientoComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-movimientos/dialog-nuevo-movimiento/dialog-nuevo-movimiento.component";
import { DialogEditarImportePeriodoComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-periodos-contrato/dialog-editar-importe-periodo/dialog-editar-importe-periodo.component";
import { DialogEditarImportePunitorioComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-periodos-contrato/dialog-editar-importe-punitorio/dialog-editar-importe-punitorio.component";
import { DialogEditarPeriodosContratoComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-editar-periodos-contrato/dialog-editar-periodos-contrato.component";
import { DialogNuevoPagoComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-nuevo-pago/dialog-nuevo-pago.component";
import { DialogNuevoReciboComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-nuevo-recibo/dialog-nuevo-recibo.component";
import { DialogVisualizaReciboComponent } from "@views/contratos/dialog-nuevo-recibo/dialog-visualiza-recibo/dialog-visualiza-recibo.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LocalidadModule,
    PersonasModule,
    PropiedadesModule,
    InquilinosModule,
    IndicesAjusteContratoModule,
    Ng2SearchPipeModule,
    ComprobantesModule,
    ArchivoTemporalModule,
    MovimientoModule,
    CuentasContableModule,
    BancosModule
  ],
  declarations: [
    ContratosComponent,
    ContratoComponent,
    ListadoContratosComponent,
    FormNuevoContratoComponent,
    BtnNuevoContratoComponent,
    DialogNuevoPeriodoComponent,
    FormEditarContratoComponent,
    NavPanelContratoComponent,
    DialogNuevaLiquidacionComponent,
    ContratosPersonasComponent,
    DialogAdendaContratoComponent,
    DialogNuevoReciboComponent,
    PeriodoshistoricosComponent,
    ListadoPeriodosHistoricosComponent,
    DialogNuevoReciboComponent,
    DialogNuevoPagoComponent,
    BuscarFormaPagoComponent,
    DialogEditarPeriodosContratoComponent,
    DialogEditarAdicionalesInquilinoComponent,
    DialogEditarImportePeriodoComponent,
    DialogEditarImportePunitorioComponent,
    DialogNuevoVencimientoServicioComponent,
    DialogEditarMovimientosComponent,
    DialogNuevoMovimientoBonificacionComponent,
    DialogEditarBonificacionesComponent,
    DialogNuevoMovimientoComponent,
    DialogVisualizaReciboComponent,
    ListadoComprobantesContratoComponent,
    TipocontratoQuienpagaServicioComponent,
    DialogTipocontratoQuienpagaServicioComponent,
    DialogGenerarResumenComponent,
    DialogRescisionContratoComponent,
    DialogVisualizaLiquidacionComponent,
    DialogLiquidacionesGeneradasComponent,
    ContratosPropiedadComponent,
    ArchivosTemporalesContratoComponent,
    MovimientosContratoComponent,
    DialogNuevoPeriodoOAdendaComponent,
    AccionesContratoComponent
  ],
  exports: [
    ContratosComponent,
    ContratoComponent,
    ListadoContratosComponent,
    FormNuevoContratoComponent,
    BtnNuevoContratoComponent,
    DialogNuevoPeriodoComponent,
    FormEditarContratoComponent,
    NavPanelContratoComponent,
    DialogNuevaLiquidacionComponent,
    ContratosPersonasComponent,
    DialogAdendaContratoComponent,
    DialogNuevoReciboComponent,
    PeriodoshistoricosComponent,
    ListadoPeriodosHistoricosComponent,
    BuscarFormaPagoComponent,
    DialogNuevoPagoComponent,
    ContratosPropiedadComponent,
    ArchivosTemporalesContratoComponent,
    MovimientosContratoComponent,
    DialogNuevoPeriodoOAdendaComponent,
    AccionesContratoComponent

  ],
  providers: [
    FormasPagoService,
    ContratosService,
    HttpClient
  ]
})

export class ContratosModule { }
