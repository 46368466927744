<div class="position-relative">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
  <h1 mat-dialog-title><strong>Generar resumenes mensuales</strong></h1>
  <form [formGroup]="formGenerarCalculo" class="position-relative">
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      <span>
        Este proceso genera los resúmenes de todos los contratos activos en el sistema. Se realizaran los cálculos de
        deudas correspondiente al periodo corriente y luego se enviara el mismo por PDF al Inquilino.
      </span>
      <br>
      <br>
      <mat-slide-toggle formControlName="AcceptTerms">¿Confirma proceso?</mat-slide-toggle>
      <br>
      <br>
    </div>
    <mat-divider></mat-divider>
    <div mat-dialog-actions>
      <button mat-raised-button *appRole="['Contratos.GenerarResumen']"
        [disabled]="!formGenerarCalculo.valid || esResumenGenerado" (click)="generarCalculo()" color="primary">
        <mat-icon>change_circle</mat-icon> Iniciar Proceso
      </button>
      <button [disabled]="esResumenGenerado"  mat-raised-button (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
    </div>
  </form>
</div>
