<div>

<ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

<div class="row" *appRoll="['Documentos.Adjuntar']">
    <div class="col-lg-4">
        <mat-card *ngIf="personaDetailDto">
            <mat-card-subtitle>Datos inquilino</mat-card-subtitle>
            <mat-label><b>Nombre completo:</b> {{personaDetailDto.nombreCompleto}}</mat-label>
            <br>
            <mat-label><b>Nro. Documento:</b> {{personaDetailDto.nroDocumento}}</mat-label>
        </mat-card>
    </div>
    <div class="col-lg-8">
        <mat-card>
            <ngx-spinner name="spDocumento" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <mat-card-subtitle>Documentos Garantia</mat-card-subtitle>
            <div class="row">
                <div class="col-lg-3">
                    <div style="padding: 5%;" class="with-icon">
                        <mat-icon>portrait</mat-icon><span>Foto DNI Frente</span>
                    </div>
                </div>
                <div class="col-lg-9">
                    <app-file-upload (eventoArchivo)="selectArchivoDNIFrente($event)"></app-file-upload>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <div style="padding: 5%;" class="with-icon">
                        <mat-icon>image</mat-icon><span>Foto DNI Dorso</span>
                    </div>
                </div>
                <div class="col-lg-9">
                    <app-file-upload (eventoArchivo)="selectArchivoDNIDorso($event)"></app-file-upload>
                </div>
            </div>


        </mat-card>
    </div>
</div>
<br>
<mat-card>

    <mat-card-subtitle>Datos persona garantia</mat-card-subtitle>
    <form [formGroup]="formPersona">
        <div class="container-fluid">
            <!-- Content here -->
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Nombre y Apellido</mat-label>
                        <input matInput type="text" maxlength="50" style="text-transform: capitalize;" id="NombreCompleto" formControlName="NombreCompleto" />
                        <mat-error *ngIf="f.NombreCompleto.errors?.required">Ingrese <strong> nombre completo.</strong>
                        </mat-error>
                        <mat-error *ngIf="f.NombreCompleto.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Tipo Documento</mat-label>
                        <mat-select required #SelectTipoDocuemtno placeholder="Seleccione opcion" formControlName="TiposDocumento" (selectionChange)="selectTipoDocumento($event)">
                            <mat-option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of lstTipoDocumentoDto ;let i=index;trackBy:trackByItems">
                                {{tipoDocumento.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="f.TiposDocumento.errors?.required">Este campo es <strong>requerido</strong>.
                        </mat-error>
                        <mat-hint>Habilita nro. documento</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Nro. Documento</mat-label>
                        <input matInput name="NroDocumento" type="text" maxlength="15" #NroDocumento oninput="this.value=this.value.toUpperCase()" formControlName="NroDocumento" />
                        <mat-error *ngIf="f.NroDocumento.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        <mat-error *ngIf="f.NroDocumento.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Correo</mat-label>
                        <input matInput class="example-right-align" name="Correo" type="email" formControlName="Correo" />
                        <mat-error *ngIf="f.Correo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        <mat-error *ngIf="f.Correo.errors?.email">Correo invalido.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <app-buscar-localidad (eventoLocalidad)="seleccionaLocalidad($event)">
                    </app-buscar-localidad>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Ubicación</mat-label>
                        <input matInput type="tel" id="Ubicacion" formControlName="Ubicacion" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field>
                        <mat-label>Telefono Principal</mat-label>
                        <input matInput type="tel" id="TelefonoPrincipal" formControlName="TelefonoPrincipal" />
                        <mat-error *ngIf="f.TelefonoPrincipal.errors?.required">Este campo es <strong>requerido</strong>. </mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field>
                        <mat-label>Telefono Laboral</mat-label>
                        <input matInput type="tel" id="TelefonoLaboral" formControlName="TelefonoLaboral" />
                    </mat-form-field>
                </div>
                <div class="col"></div>
                <div class="col"></div>
            </div>
        </div>
    </form>
</mat-card>

<br>

<mat-card>
    <ngx-spinner name="spDtoGta" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>

    <mat-card-subtitle>Datos garantia</mat-card-subtitle>

    <mat-card-content>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <form [formGroup]="formTipoGarantia">
                        <mat-form-field>
                            <mat-label>Tipo de Garantia</mat-label>
                            <mat-select (selectionChange)="selectTipoGarantia($event.value)" formControlName="TipoGarantia">
                                <mat-option [value]="tipoGarantia.id" *ngFor="let tipoGarantia of lstTiposGarantiaModel ;let i=index;trackBy:trackByItems">
                                    {{tipoGarantia.descripcion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="fG.TipoGarantia.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPersona">
                    <form [formGroup]="formPersonaGarantia">
                        <mat-form-field>
                            <mat-label>Empleador</mat-label>
                            <input matInput name="Empleador" type="text " formControlName="Empleador" />
                            <mat-error *ngIf="fPer.Empleador.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPersona">
                    <form [formGroup]="formPersonaGarantia">
                        <mat-form-field>
                            <mat-label>Empleador CUIT/CUIL</mat-label>
                            <input matInput name="CuitCuil" type="text" placeholder="00-00000000-0" formControlName="CuitCuil" />
                            <mat-error *ngIf="fPer.CuitCuil.errors?.pattern">Formato invalido.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPersona">
                    <form [formGroup]="formPersonaGarantia">
                        <mat-form-field>
                            <mat-label>Sueldo</mat-label>
                            <span matPrefix>$ &nbsp;</span>
                            <input matInput name="Sueldo" placeholder="0.00" type="number" formControlName="Sueldo" />
                            <mat-error *ngIf="fPer.Sueldo.errors?.pattern">Formato invalido.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col" *ngIf="mostrarPropiedad">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Ubicación</mat-label>
                            <input matInput name="UbicacionPropiedad" type="text" formControlName="UbicacionPropiedad" />
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPropiedad">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Localidad</mat-label>
                            <input matInput name="LocalidadPropiedad" type="text" formControlName="LocalidadPropiedad" />
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPropiedad">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Indivisa</mat-label>
                            <span matPrefix>% &nbsp;</span>
                            <input matInput appPercentageDirective min="1" max="100" [(ngModel)]="indivisa" placeholder="100" name="Indivisa" type="number" formControlName="Indivisa" />
                            <mat-hint>Dueño de propiedad al %{{indivisa}}</mat-hint>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPropiedad">
                    <form [formGroup]="formPropiedad">
                        <section class="example-section">
                            <mat-checkbox formControlName="PropiedadHorizontal">Horizontal</mat-checkbox>
                        </section>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col" *ngIf="mostrarPropiedad">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Tipo de Folio</mat-label>
                            <mat-select (selectionChange)="selectTipoFolio($event.value)" formControlName="TipoFolio">
                                <mat-option [value]="tipoFolio.id" *ngFor="let tipoFolio of lstTiposFolios ;let i=index;trackBy:trackByItems">
                                    {{tipoFolio.descripcion}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="fPro.TipoFolio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPropiedad  && idTipoFolio===1 || idTipoFolio===2">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Nro. Folio</mat-label>
                            <input matInput name="NroFolio" type="text" formControlName="NroFolio" />
                            <mat-error *ngIf="fPro.NroFolio.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPropiedad && idTipoFolio===1">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Tomo</mat-label>
                            <input matInput name="Tomo" type="text" formControlName="Tomo" />
                            <mat-error *ngIf="fPro.Tomo.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarPropiedad && idTipoFolio===1">
                    <form [formGroup]="formPropiedad">
                        <mat-form-field>
                            <mat-label>Número</mat-label>
                            <input matInput name="Numero" type="text" formControlName="Numero" />
                            <mat-error *ngIf="fPro.Numero.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
            </div>
            <div class="row">
                <div class="col" *ngIf="mostrarFianza">
                    <form [formGroup]="formFianza">
                        <mat-form-field>
                            <mat-label>Razon Social</mat-label>
                            <input matInput name="RazonSocial" type="text " formControlName="RazonSocial" />
                            <mat-error *ngIf="fTL.RazonSocial.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col" *ngIf="mostrarFianza">
                    <form [formGroup]="formFianza">
                        <mat-form-field>
                            <mat-label>CUIT</mat-label>
                            <input matInput name="Cuit" type="text" placeholder="00-00000000-0" formControlName="Cuit" />
                            <mat-error *ngIf="fTL.Cuit.errors?.pattern">Formato invalido.</mat-error>
                        </mat-form-field>
                    </form>
                </div>
                <div class="col"></div>
                <div class="col"></div>
            </div>
        </div>

    </mat-card-content>
</mat-card>

<br>
<mat-card-actions >
    <div class="col-auto my-1" *appRole="['MisGarantias.Crear']">

        <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPropiedad" name="Agregar" [disabled]="!formTipoGarantia.valid || !formPersona.valid ||  !formPropiedad.valid" (click)="onSubmit()">Agregar</button>
        <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarPersona" name="Agregar" [disabled]="!formTipoGarantia.valid || !formPersona.valid || !formPersonaGarantia.valid" (click)="onSubmit()">Agregar</button>
        <button mat-raised-button color="primary" style="margin-right:3px;" *ngIf="mostrarFianza" name="Agregar" [disabled]="!formTipoGarantia.valid || !formPersona.valid || !formFianza.valid " (click)="onSubmit()">Agregar</button>

        <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="onReset()">Cancelar</button>
    </div>
</mat-card-actions>
</div>
