import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePasswordRequest } from '@models/usuarios/changePasswordRequest.model';
import { CreateUserRequest } from '@models/usuarios/createUserRequest.model';
import { CreateUserResponse } from '@models/usuarios/createUserResponse.model';
import { DeleteUserRequest } from '@models/usuarios/deleteUserRequest.model';
import { ForgotPasswordRequest } from '@models/usuarios/forgotPasswordRequest.model';
import { UpdateRolInUserRequest } from '@models/usuarios/updateRolInUserRequest.model';
import { UpdateUserRequest } from '@models/usuarios/updateUserRequest.model';
import { UpdateUserResponse } from '@models/usuarios/updateUserResponse.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { LoginService } from '../login/login.service';
import { isFalsy } from 'utility-types';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';

const baseUrl = 'https://inmobiliariamassoud.clientes.mariniti.com.ar/';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {


  lstUsuarioDto: UsuarioDto[] = [];

  // private readonly urlBase = `${environment.apiUrl}`;
  userString: any;

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient,
  ) {
  }

  getUserByName(userName: string): Observable<UsuarioDto> {
    return this.http.get<UsuarioDto>("/api/Usuarios/GetUserByUserName?userName=" + userName);
  }


  getAllDataOfUserByUserName(userName: string): Observable<UsuarioDto> {
    return this.http.get<UsuarioDto>("/api/Usuarios/getAllDataOfUserByUserName?userName=" + userName);
  }

  getUserById(id: number): Observable<UsuarioDto> {
    return this.http.get<UsuarioDto>("/api/Usuarios/GetUserById?IdUser=" + id);
  }

  getAll(): Observable<UsuarioDto[]> {
    return this.http.get<UsuarioDto[]>("/api/Usuarios/GetAll");
  }

  addUser(createUserRequest: CreateUserRequest): Observable<CreateUserResponse> {
    return this.http.post<CreateUserResponse>("/api/Usuarios/Create", createUserRequest);
  }

  updateUser(updateUserRequest: UpdateUserRequest): Observable<UpdateUserResponse> {
    return this.http.post<UpdateUserRequest>("/api/Usuarios/Update", updateUserRequest);
  }

  deleteUser(deleteUserRequest: DeleteUserRequest): Observable<boolean> {
    return this.http.post<boolean>("/api/Usuarios/Delete", deleteUserRequest);
  }


  recoverPassword(forgotPasswordRequest:ForgotPasswordRequest): Observable<MessageSuccessfullDto>{
    return this.http.post<MessageSuccessfullDto>("/api/Usuarios/ForgotPassword",forgotPasswordRequest)
  }

  changePassword(changePasswordRequest: ChangePasswordRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Usuarios/ChangePassword", changePasswordRequest)
  }

  updateRolInUser(updateRolInUserRequest: UpdateRolInUserRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Usuarios/UpdateRolInUser", updateRolInUserRequest)
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Usuarios/Delete?id=" + id);
    else
      return of(null)
  }

}
