import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateDetalleServicioPropiedadRequest } from '@models/servicios/detalle-servicio-propiedad/createDetalleServicioPropiedadRequest.model';
import { CreateDetalleServicioPropiedadResponse } from '@models/servicios/detalle-servicio-propiedad/createDetalleServicioPropiedadResponse.model';
import { DetalleServicioPropiedadDetailDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadDetailDto.model';
import { DetalleServicioPropiedadSummaryDto } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadSummaryDto.model';
import { DetalleServicioPropiedadGroupByServicioPropiedadResponse } from '@models/servicios/detalle-servicio-propiedad/detalleServicioPropiedadGroupByServicioPropiedadResponse.model';
import { UpdateDetalleServicioPropiedadRequest } from '@models/servicios/detalle-servicio-propiedad/updateDetalleServicioPropiedadRequest.model';
import { UpdateMontosPorBrocheRequest } from '@models/servicios/detalle-servicio-propiedad/updateMontosPorBrocheRequest.mode';
import { UpdateMontosPorBrocheResponse } from '@models/servicios/detalle-servicio-propiedad/updateMontosPorBrocheResponse.model';
import { UpdateStatusRequest } from '@models/servicios/detalle-servicio-propiedad/updateStatusRequest.model';
import { isFalsy } from 'utility-types';
import { UploadFileRequest } from '@models/servicios/detalle-servicio-propiedad/uploadFileRequest.model';
import { UploadFileResponse } from '@models/servicios/detalle-servicio-propiedad/uploadFileResponse.model';

@Injectable({
  providedIn: 'root'
})
export class DetalleServicioPropiedadService {

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  getAll(): Observable<DetalleServicioPropiedadDetailDto[]> {
    return this.http.get<DetalleServicioPropiedadDetailDto[]>("/api/DetalleServicioPropiedad/GetAll");
  }

  GetById(idDetalleServicioPropiedad: number): Observable<DetalleServicioPropiedadDetailDto> {
    return this.http.get<DetalleServicioPropiedadDetailDto>("/api/DetalleServicioPropiedad/GetById?id=" + idDetalleServicioPropiedad);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/DetalleServicioPropiedad/Delete?id=" + id);
    else
      return of(null)
  }

  getAllByIdServicioPropiedad(idServicioPropiedad: number): Observable<DetalleServicioPropiedadSummaryDto[]> {
    if (isFalsy(idServicioPropiedad))
      return this.http.get<DetalleServicioPropiedadSummaryDto[]>("/api/DetalleServicioPropiedad/GetAllByIdServicioPropiedad?idServicioPropiedad=" + idServicioPropiedad);
    else
      return this.http.get<DetalleServicioPropiedadSummaryDto[]>("/api/DetalleServicioPropiedad/GetAllByIdServicioPropiedad");
  }

  getAllByIdPropiedad(idPropiedad: number): Observable<DetalleServicioPropiedadDetailDto[]> {
    return this.http.get<DetalleServicioPropiedadDetailDto[]>("/api/DetalleServicioPropiedad/GetAllByIdPropiedad?idPropiedad=" + idPropiedad);
  }

  create(createDetalleServicioPropiedadRequest: CreateDetalleServicioPropiedadRequest): Observable<CreateDetalleServicioPropiedadResponse> {
    return this.http.post<CreateDetalleServicioPropiedadResponse>("/api/DetalleServicioPropiedad/CreateCollection", createDetalleServicioPropiedadRequest);
  }

  update(updateDetalleServicioPropiedadRequest: UpdateDetalleServicioPropiedadRequest): Observable<any> {
    return this.http.put("/api/DetalleServicioPropiedad/Update", updateDetalleServicioPropiedadRequest);
  }

  updateMontosPorBrocheRequest(updateMontosPorBrocheRequest: UpdateMontosPorBrocheRequest): Observable<UpdateMontosPorBrocheResponse> {
    return this.http.put<UpdateMontosPorBrocheResponse>("/api/DetalleServicioPropiedad/UpdateMontosPorBroche", updateMontosPorBrocheRequest);
  }

  getDetalleServicioPropiedadGroupByServicioPropiedad(dateFrom: string, dateTo: string, id_EstadoDetalleServicioPropiedad: number, id_Servicio: number, id_Propiedad: number, id_QuienPaga: number): Observable<DetalleServicioPropiedadGroupByServicioPropiedadResponse> {
    let cadena = ""
    let idEstadoDetalleServicioPropiedad = id_EstadoDetalleServicioPropiedad == 0 ? null : id_EstadoDetalleServicioPropiedad
    let idServicio = id_Servicio == 0 ? null : id_Servicio
    let idPropiedad = id_Propiedad == 0 ? null : id_Propiedad
    let idQuienPaga = id_QuienPaga == 0 ? null : id_QuienPaga

    if (dateFrom != null && dateFrom != "" && dateTo != null && dateTo != "")
      cadena = "?dateFrom=" + dateFrom + "&dateTo=" + dateTo
    if (idEstadoDetalleServicioPropiedad != null && idEstadoDetalleServicioPropiedad != 0)
      cadena += "&idEstadoDetalleServicioPropiedad=" + idEstadoDetalleServicioPropiedad
    if (!isFalsy(idServicio))
      cadena += "&idServicio=" + idServicio
    if (!isFalsy(idPropiedad))
      cadena += "&idPropiedad=" + idPropiedad
    if (!isFalsy(idQuienPaga))
      cadena += "&idQuienPaga=" + idQuienPaga

    if (cadena != "")
      return this.http.get<DetalleServicioPropiedadGroupByServicioPropiedadResponse>("/api/DetalleServicioPropiedad/GetDetalleServicioPropiedadGroupByServicioPropiedad" + cadena);
    else
      return this.http.get<DetalleServicioPropiedadGroupByServicioPropiedadResponse>("/api/DetalleServicioPropiedad/GetDetalleServicioPropiedadGroupByServicioPropiedad?dateFrom=" + dateFrom + "&dateTo=" + dateTo);

  }

  updatestatus(updateStatusRequest: UpdateStatusRequest): Observable<any> {
    return this.http.patch("/api/DetalleServicioPropiedad/UpdateStatus", updateStatusRequest);
  }

  updateStatusAutomatic(): Observable<any> {
    return this.http.patch("/api/DetalleServicioPropiedad/UpdateStatusAutomatic", {});
  }

  uploadFile(uploadFileRequest: UploadFileRequest): Observable<UploadFileResponse> {
    return this.http.post<UploadFileResponse>("/api/DetalleServicioPropiedad/UploadFile", uploadFileRequest);
  }
}
