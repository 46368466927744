<div class="row content" *appRole="['Configuracion.Ver']">
    <div class="col-sm-2">
        <app-nav-panel-configuracion></app-nav-panel-configuracion>
    </div>
    <div class="col-sm-10" >
        <mat-accordion class="example-headers-align">
            <!-- Parametros Generales -->
            <mat-expansion-panel [disabled]="false" [expanded]='false' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Parametros generales
                    </mat-panel-title>
                    <mat-panel-description>
                        Configuración de los parametros generales
                        <mat-icon>settings</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="formParametrosGenerales">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Código Alquiler</mat-label>

                                    <input matInput type="text" formControlName="CodigoAlquiler" matTooltip="Código Alquiler">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.CodigoAlquiler != null" [matTooltip]="parametroSistemaDescripcion.CodigoAlquiler">info</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Duración Archivos temporales</mat-label>

                                    <input matInput type="numer" formControlName="DuracionMesesArchivosTemporales">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.DuracionMesesArchivosTemporales != null" [matTooltip]="parametroSistemaDescripcion.DuracionMesesArchivosTemporales">info</mat-icon>

                                    <mat-error *ngIf="paramGeneral.DuracionMesesArchivosTemporales.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-auto my-1">
                            <button type="submit" name="Guardar" *appRole="['Configuracion.Ver']" (click)="onSubmitParametrosGenerales()" [disabled]="!formParametrosGenerales.valid" mat-stroked-button color="primary" value="Guardar">
                              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Guardar</button>
                        </div>
                    </form>
                </ng-template>
            </mat-expansion-panel>
            <!-- Parametros Contables -->
            <mat-expansion-panel [disabled]="false" [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Parametros Contables
                    </mat-panel-title>
                    <mat-panel-description>
                        Configuración de los parametros contables
                        <mat-icon>account_balance</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="formParametrosContables">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Iva</mat-label>

                                    <input matInput type="text" formControlName="Iva">

                                    <span matPrefix>%&nbsp;</span>

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.Iva != null" [matTooltip]="parametroSistemaDescripcion.Iva">info</mat-icon>

                                    <mat-error *ngIf="paramContable.Iva.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Caja</mat-label>

                                    <input matInput type="text" formControlName="Caja">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.Caja != null" [matTooltip]="parametroSistemaDescripcion.Caja">info</mat-icon>

                                    <mat-error *ngIf="paramContable.Caja.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard" class="full-width-x2">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Cuenta Contable Defecto</mat-label>

                                    <mat-select placeholder="Seleccione opcion" formControlName="CuentaContableDefecto" matTooltip="Cuenta Contable Defecto">
                                        <mat-option [value]="cuentaContable.id" *ngFor="let cuentaContable of lstCuentasContable;let i=index;trackBy:trackByItems">
                                            {{cuentaContable.descripcion}}</mat-option>
                                    </mat-select>

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.CuentaContableDefecto != null" [matTooltip]="parametroSistemaDescripcion.CuentaContableDefecto">info</mat-icon>

                                    <mat-error *ngIf="paramContable.CuentaContableDefecto.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">

                        </div>
                        <div class="col-auto my-1">
                            <button type="submit" name="Guardar" *appRole="['Configuracion.Ver']" (click)="onSubmitParametrosContables()" [disabled]="!formParametrosContables.valid" mat-stroked-button color="primary" style="margin-right:3px;" value="Guardar">
                              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Guardar</button>
                        </div>

                    </form>
                </ng-template>
            </mat-expansion-panel>
            <!-- Comisiones -->
            <mat-expansion-panel [disabled]="false" [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Comisiones
                    </mat-panel-title>
                    <mat-panel-description>
                        Asignación de las diferentes comisiones
                        <mat-icon>payments</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="formParametrosComisiones">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Comisión Ganancia Propietario</mat-label>

                                    <input matInput type="number" formControlName="ComisionGananciaPropietario" matTooltip="Comisión Ganancia Propietario">
                                    <span matPrefix>%&nbsp;</span>

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.ComisionGananciaPropietario != null" [matTooltip]="parametroSistemaDescripcion.ComisionGananciaPropietario">info</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Comisión Ganancia Inmobiliaria</mat-label>

                                    <input matInput type="number" formControlName="ComisionGananciaInmobiliaria" matTooltip="Comisión Ganancia Inmobiliaria">
                                    <span matPrefix>%&nbsp;</span>

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.ComisionGananciaInmobiliaria != null" [matTooltip]="parametroSistemaDescripcion.ComisionGananciaInmobiliaria">info</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

                        <br>
                        <div class="col-auto my-1">
                            <button name="Guardar" *appRole="['Configuracion.Ver']" (click)="onSubmitParametrosComisiones()" [disabled]="!formParametrosComisiones.valid" mat-stroked-button color="primary" style="margin-right:3px;" value="Guardar">
                              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Guardar</button>
                        </div>
                    </form>
                </ng-template>
            </mat-expansion-panel>
            <!-- Parametros Notificaciones -->
            <mat-expansion-panel [disabled]="false" [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Parametros Notificaciones
                    </mat-panel-title>
                    <mat-panel-description>Configuración de alertas del sistema
                        <mat-icon>notifications_none</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="formNotificaciones">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Día Generación Resumen</mat-label>

                                    <input matInput type="number" matTooltip="Día Generación Resumen" formControlName="FechaGenResumen">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.FechaGenResumen != null" [matTooltip]="parametroSistemaDescripcion.FechaGenResumen">info</mat-icon>

                                    <mat-error *ngIf="paramNotificaciones.FechaGenResumen.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">

                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Día Generación Liquidación</mat-label>

                                    <input matInput type="number" matTooltip="Día Generación Liquidación" formControlName="FechaGenLiquizacion">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.FechaGenLiquizacion != null" [matTooltip]="parametroSistemaDescripcion.FechaGenLiquizacion">info</mat-icon>

                                    <mat-error *ngIf="paramNotificaciones.FechaGenLiquizacion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Dias anteriores al finalizar contrato.</mat-label>

                                    <input matInput type="number" formControlName="DiasFinalizaContrato" matTooltip="Dias anteriores al finalizar contrato.">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.DiasFinalizaContrato != null" [matTooltip]="parametroSistemaDescripcion.DiasFinalizaContrato">info</mat-icon>

                                    <mat-error *ngIf="paramNotificaciones.DiasFinalizaContrato.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Cantidad de meses para deuda de propiedad.</mat-label>

                                    <input matInput type="number" formControlName="CantidadMesesDeudaPropiedad" matTooltip="Cantidad de meses para deuda de propiedad.">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.CantidadMesesDeudaPropiedad != null" [matTooltip]="parametroSistemaDescripcion.CantidadMesesDeudaPropiedad">info</mat-icon>

                                    <mat-error *ngIf="paramNotificaciones.CantidadMesesDeudaPropiedad.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Duración de alertas.</mat-label>

                                    <input matInput type="number" formControlName="DuracionAlertas" matTooltip="Dias anteriores al finalizar contrato.">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.DuracionAlertas != null" [matTooltip]="parametroSistemaDescripcion.DuracionAlertas">info</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-auto my-1">
                            <button type="submit" name="Guardar" *appRole="['Configuracion.Ver']" (click)="onSubmitParametrosNotificaciones()" [disabled]="!formNotificaciones.valid" mat-stroked-button color="primary" style="margin-right:3px;" value="Guardar">
                              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Guardar</button>
                        </div>

                    </form>
                </ng-template>
            </mat-expansion-panel>
            <!-- Parametros Correo -->
            <mat-expansion-panel [disabled]="false" [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Parametros Correo
                    </mat-panel-title>
                    <mat-panel-description>
                        Configuración de correo del sistema
                        <mat-icon>email</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="formParametrosCorreo">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Servidor</mat-label>

                                    <input matInput type="text" formControlName="SmtpServer">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.SmtpServer != null" [matTooltip]="parametroSistemaDescripcion.SmtpServer">info</mat-icon>

                                    <mat-error *ngIf="paramCorreo.SmtpServer.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Puerto</mat-label>

                                    <input matInput type="number" formControlName="Port">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.Port != null" [matTooltip]="parametroSistemaDescripcion.Port">info</mat-icon>

                                    <mat-error *ngIf="paramCorreo.Port.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Cuenta</mat-label>

                                    <input matInput type="email" formControlName="Sender">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.Sender != null" [matTooltip]="parametroSistemaDescripcion.Sender">info</mat-icon>

                                    <mat-error *ngIf="paramCorreo.Sender.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Usuario</mat-label>

                                    <input matInput type="email" formControlName="Username">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.Username != null" [matTooltip]="parametroSistemaDescripcion.Username">info</mat-icon>

                                    <mat-error *ngIf="paramCorreo.Username.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-label>Contraseña</mat-label>

                                    <input matInput type="password" formControlName="Password">

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.Password != null" [matTooltip]="parametroSistemaDescripcion.Password">info</mat-icon>

                                    <mat-error *ngIf="paramCorreo.Password.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <section style="margin-top: 5%;">
                                    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>

                                    <mat-slide-toggle formControlName="SslTls" name="SslTls" class="example-margin">
                                        SSL/TLS
                                    </mat-slide-toggle>

                                    <mat-icon color="primary" matSuffix *ngIf="parametroSistemaDescripcion.SslTls != null" [matTooltip]="parametroSistemaDescripcion.SslTls">info</mat-icon>
                                </section>
                            </div>
                        </div>
                        <div class="col-auto my-1">
                            <button type="submit" name="Guardar" *appRole="['Configuracion.Ver']" (click)="onSubmitParametrosCorreo()" [disabled]="!formParametrosCorreo.valid" mat-stroked-button color="primary" style="margin-right:3px;" value="Guardar">
                              <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Guardar</button>
                        </div>

                    </form>
                </ng-template>
            </mat-expansion-panel>

            <!-- Parametros Comprobantes -->
            <mat-expansion-panel [disabled]="false" [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Parametros Comprobantes
                    </mat-panel-title>
                    <mat-panel-description>
                        Configuración de comprobantes
                        <mat-icon>article</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <form [formGroup]="formComprobantes">
                        <div class="row">
                            <div class="col-lg-12">
                                <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                <mat-tab-group>
                                    <mat-tab label="Nota Resumen">
                                        <br>
                                        <ngx-editor-menu [editor]="editorResumen"> </ngx-editor-menu>
                                        <ngx-editor [editor]="editorResumen" formControlName="NotaResumen" [disabled]="false" [placeholder]="'Escriba aqui...'">
                                        </ngx-editor>
                                        <mat-hint matSuffix *ngIf="parametroSistemaDescripcion.NotaResumen != null">{{parametroSistemaDescripcion.NotaResumen}}</mat-hint>
                                    </mat-tab>
                                    <mat-tab label="Nota Recibo">
                                        <br>
                                        <ngx-editor-menu [editor]="editorRecibo"> </ngx-editor-menu>
                                        <ngx-editor [editor]="editorRecibo" formControlName="NotaRecibo" [disabled]="false" [placeholder]="'Escriba aqui...'"></ngx-editor>
                                        <mat-hint matSuffix *ngIf="parametroSistemaDescripcion.NotaRecibo != null">{{parametroSistemaDescripcion.NotaRecibo}}</mat-hint>
                                    </mat-tab>
                                    <mat-tab label="Nota Liquidación">
                                        <br>
                                        <ngx-editor-menu [editor]="editorLiquidacion"> </ngx-editor-menu>
                                        <ngx-editor [editor]="editorLiquidacion" formControlName="NotaLiquidacion" [disabled]="false" [placeholder]="'Escriba aqui...'"></ngx-editor>
                                        <mat-hint matSuffix *ngIf="parametroSistemaDescripcion.NotaLiquidacion != null">{{parametroSistemaDescripcion.NotaLiquidacion}}</mat-hint>
                                    </mat-tab>
                                </mat-tab-group>


                            </div>
                        </div>
                        <div class="row">

                        </div>
                        <br>
                        <div class="col-auto my-1">
                            <button type="submit" name="Guardar" *appRole="['Configuracion.Ver']" (click)="onSubmitParametrosComprobantes()" [disabled]="!formComprobantes.valid" mat-stroked-button color="primary" style="margin-right:3px;" value="Guardar">
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Guardar</button>
                        </div>

                    </form>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</div>
