import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { ChartsModule } from 'ng2-charts';
import { BarChartComponent } from '../views/dashboard/graficosEjemplos/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from '../views/dashboard/graficosEjemplos/doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from '../views/dashboard/graficosEjemplos/line-chart/line-chart.component';
import { RadarChartComponent } from '../views/dashboard/graficosEjemplos/radar-chart/radar-chart.component';

import { InquilinosModule } from './inquilinos.module';
import { NotificacionesModule } from './notificaciones.module';
import { PersonasModule } from './personas.module';
import { PropiedadesModule } from './propiedades.module';
import { DashboardContratosComponent } from '../views/dashboard/dashboard-contratos/dashboard-contratos.component';
import { DashboardPropiedadesComponent } from '../views/dashboard/dashboard-propiedades/dashboard-propiedades.component';
import { DashboardGeneralComponent } from '../views/dashboard/dashboard-general/dashboard-general.component';
import { DashboardComponent } from '../views/dashboard/dashboard/dashboard.component';
import { DashboardCajaComponent } from '../views/dashboard/dashboard-caja/dashboard-caja/dashboard-caja.component';
import { ContratosModule } from './contratos.module';
//Charts



@NgModule({
  imports:[
      CommonModule,
      SharedModule,
      ChartsModule,
      PersonasModule,
      PropiedadesModule,
      InquilinosModule,
      ContratosModule,
      NotificacionesModule
  ],
  declarations:[
    //Chart
    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    DashboardComponent,
    DashboardContratosComponent,
    DashboardPropiedadesComponent,
    DashboardGeneralComponent,
    DashboardCajaComponent
  ],
  exports:[
    //Chart
    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    DashboardComponent,
    DashboardContratosComponent,
    DashboardGeneralComponent,
    DashboardCajaComponent
  ]
})
export class DashboardModule { }
