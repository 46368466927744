import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { GarantiasService } from '@services/garantias/garantias.service';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-garantias-inquilino-public',
  templateUrl: './garantias-inquilino-public.component.html',
  styleUrls: ['./garantias-inquilino-public.component.css']
})
export class GarantiasInquilinoPublicComponent implements OnInit {
  data = false
  @Input() idPersonaInquilino: any
  idPersonaLocal!: number;
  dataSource = new MatTableDataSource<GarantiaSummaryDto>();
  displayedColumns: string[] = [];
  myTrackById: any;
  personaDetailDto!: PersonaDetailDto;
  user!: UsuarioDto;

  constructor(
    private garantiaService: GarantiasService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private personasService: PersonasService,
    private router: Router,
    private loginService: LoginService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["persona", "tipoGarantia", "validacion", "accion"] :
        ["persona", "tipoGarantia", "nroFolio", "razonSocial", "cuitCuil", "sueldo", "validacion", "accion"]
    });
  }

  ngOnInit(): void {

   this.user = this.loginService.getCurrentUser()
    this.getAllGarantias(this.user.persona.id)
  }


  ngOnChange() {
  }

  getAllGarantias(idPersona: number) {
    this.spinner.show()
    this.garantiaService.getAllGarantiasByInquilino(idPersona)
      .subscribe(
        data => {
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.spinner.hide()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  editaPersonaGarante(idPersona: number) {
    this.getPersonaById(idPersona)
  }

  getPersonaById(idPersona: number) {
    this.spinner.show("spPersona")
    this.personasService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personasService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }
}
