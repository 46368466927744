import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LoginService } from '@services/login/login.service';
import { AccionesDialog, ParametrosConfiguracion, Roles } from '@models/base/identificadores.model';
import { FormControl } from '@angular/forms';
import { ProcesosService } from '@services/procesos/procesos.service';
import { HotToastService, ToastConfig } from '@ngneat/hot-toast';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ContadorContratoDto } from '@models/dashboard/contadorContratoDto.model';
import { ParametrosSistemaService } from '@services/parametro-sistema/parametros-sistema.service';
import { NotificacionDto } from '@models/notificaciones/notificacionDto.model';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';

export class ContadorEntidades {
  public propiedades!: number
  public propietarios!: number
  public inquilinos!: number
  public garantias!: number
  public contratos!: number
  public usuarios!: number
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public mostrarDashboard = false
  matButtonToggleGroup = new FormControl();
  contadoresEntidades!: any[];
  contadorContratoDto!: ContadorContratoDto;
  notificaciones: NotificacionDto[] = [];
  constructor(
    private loginService: LoginService,
    private procesosService: ProcesosService,
    private toastService: HotToastService,
    private spinner: NgxSpinnerService,
    private parametrosSistemaService: ParametrosSistemaService,
    private snackBar: SnackBarService,
    private notificacionesService: NotificacionesService
  ) {
    let toasConfig = new ToastConfig
    toasConfig.autoClose = false
    toasConfig.reverseOrder= true,
    toasConfig.position= 'bottom-right'
    this.toastService.defaultConfig = toasConfig
    const roles = this.loginService.getIdUserRol()
    this.matButtonToggleGroup.setValue('general')
    switch (roles) {
      case Roles.Usuarios:
        this.mostrarDashboard = false
        break;
      case Roles.Administradores:
        this.mostrarDashboard = true
        break;
      case Roles.Clientes:
        this.mostrarDashboard = false
        break;
      case Roles.SuperAdministradores:
        this.mostrarDashboard = true
        break;
      default:
        this.mostrarDashboard = true
        break;
    }
  }

  ngOnInit() {
    if (this.loginService.getIdUserRol() != Roles.Clientes) {
      this.procesosIniciales()
      this.notificacionesParametrosConfiguracion()
      this.setParametrosSistemaLocalStorage()
    }
  }

  //Procesos Iniciales
  private procesosIniciales() {
    this.procesosService.endContratos()
      .pipe(
        this.toastService.observe(
          {
            loading: 'Aguarde por favor. Cargando procesos iniciales.',
            success: (s) => 'Procesos realizados con exito. ',
            error: (e) => 'Ups! ' + e,
          }
        ),
        catchError((error) => of(error,
          this.snackBar.showError(error, "Error"),
          this.toastService.close())))
      .subscribe(
        data => {
          this.toastService.close()
        });
  }

  notificacionesParametrosConfiguracion() {
    this.getValorParametro(ParametrosConfiguracion.FechaGenResumen)
    this.getValorParametro(ParametrosConfiguracion.FechaGenLiquizacion)
  }

  setParametrosSistemaLocalStorage(){
    this.parametrosSistemaService.setParametrosSistemaLocalStorage();
  }

  getValorParametro(id: number) {
    this.spinner.show()
    this.parametrosSistemaService.getById(id)
      .subscribe(
        data => {
          const fecha = new Date();
          let hoy = moment(fecha).format("D");
          let notificacionDto = new NotificacionDto
          if (data.valor == hoy) {
            switch (id) {
              case ParametrosConfiguracion.FechaGenResumen:
                notificacionDto.mensaje = "Generar resúmen para inquilinos."
                break;
              case ParametrosConfiguracion.FechaGenLiquizacion:
                notificacionDto.mensaje = "Generar liquidación para propietarios."
                break;
              default:
                break;
            }
            this.notificacionesService.sendNotificacion(notificacionDto)
          }
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      );
  }


}

