<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<h1 mat-dialog-title>{{head}}</h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    {{body}}
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    {{action}}
</div>
