<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
  </button>
<h1 mat-dialog-title><strong>Calcular nuevo valores de periodos</strong></h1>
<form [formGroup]="formGenerarCalculo">
    <div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">

        <ngx-spinner [zIndex]="100" name="spLoadProcess" class="ngx-spinner" bdColor="rgba(255,255,255,0.8)" size="large" color="#009688" type="ball-atom" [fullScreen]="false">
            <p> Aguarde por favor... </p>
        </ngx-spinner>
        <span>Se calculara para el mes y año actual, los nuevos importes de los periodos tomando como valor actual los ultimos indice de cada tipo de ajuste:
    </span>
        <div class="row">
            <br>
            <div class="col-lg-6">

              <mat-form-field class="example-full-width">
                <ngx-spinner name="spBusquedaTipoAjusteContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Tipos de Ajustes Contrato</mat-label>
                <mat-select placeholder="Seleccione opcion" multiple formControlName="TiposAjusteContrato">
                  <mat-option [value]="tipoAjusteContrato.id" *ngFor="let tipoAjusteContrato of lstTiposAjusteContrato;let i=index;trackBy:trackByItems">
                      {{ tipoAjusteContrato.acronimo | titlecase }}</mat-option>
              </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
                <ngx-spinner name="spIndiceAjusteContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <span *ngIf="indiceAjusteContrato"><b>Fecha: </b>{{indiceAjusteContrato.fecha | date:"dd/MM/yyyy"}} - <b>Valor: </b>{{indiceAjusteContrato.valor}} - <b>Tipo Ajuste: </b>{{indiceAjusteContrato.tipoAjusteContrato?.acronimo}}</span>
            </div>
        </div>
        <br>
        <mat-slide-toggle formControlName="AcceptTerms"> Confirma proceso?</mat-slide-toggle>
    </div>
    <br>
    <mat-divider></mat-divider>
    <div mat-dialog-actions>
        <button mat-raised-button *appRole="['Icl.Calcular']" [disabled]="enProceso || !formGenerarCalculo.valid" (click)="generarCalculo()" color="primary">
       <mat-icon>change_circle</mat-icon> Iniciar Proceso
    </button>
        <button mat-raised-button [disabled]="enProceso || !formGenerarCalculo.valid" (click)="closeDialog()" mat-dialog-close color="warn">Cancelar</button>
    </div>
</form>
