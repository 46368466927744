import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'app-dialog-generar-resumen-mensual',
  templateUrl: './dialog-generar-resumen-mensual.component.html',
  styleUrls: ['./dialog-generar-resumen-mensual.component.css']
})
export class DialogGenerarResumenMensualComponent implements OnInit {

  formGenerarCalculo: FormGroup;
  confirmaProceso: boolean = false
  esResumenGenerado: boolean = false
  incluirPeriodoPosterior!: boolean;
  get f() { return this.formGenerarCalculo.controls; }
  constructor
    (
      private _formBuilder: FormBuilder,
      private snackBar: SnackBarService,
      private inquilinosService: InquilinosService,
      public dialogRef: MatDialogRef<DialogGenerarResumenMensualComponent>,
      private toastService: HotToastService,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {

    this.formGenerarCalculo = this._formBuilder.group({
      AcceptTerms: [false, Validators.requiredTrue],
    });
  }

  ngOnInit(): void {
  }

  generarCalculo() {
    this.esResumenGenerado = true
    this.incluirPeriodoPosterior = true
    this.inquilinosService.getSummaryInquilinoMasivoAndSendEmail(this.incluirPeriodoPosterior).pipe(
      this.toastService.observe(
        {
          loading: 'Aguarde por favor...',
          success: (s) => 'Proceso finalizado con exito. ',
        }
      ))
      .subscribe(
        data => {
          this.dialogRef.close({ event: AccionesDialog.Aceptar });
        },
        error => {
          this.snackBar.showError(error, "Error");
        });
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }
}
