<div class="container-fluid">
    <mat-card *appRole="['Comprobantes.Crear']">
        <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>

        <div class="row">
            <div class="col-12">
                <form [formGroup]="form">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-3">
                                    <mat-form-field class="full-width">
                                        <mat-label>Ingrese rango de fechas</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde" />
                                            <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta" />
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field>
                                        <mat-label>Rangos rapidos</mat-label>
                                        <mat-select placeholder="Seleccione opcion" formControlName="RangoFecha" (selectionChange)="selectRangoFecha($event)">
                                            <mat-option [value]="fecha.id" *ngFor="let fecha of lstRangoFecha;let i=index;trackBy:trackByItems">
                                                {{ fecha.descripcion | titlecase }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <mat-form-field class="example-full-width">
                                        <ngx-spinner [zIndex]="100" name="spBusquedaTiposComprobantes" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                        <mat-label>Tipos Comprobantes</mat-label>
                                        <mat-select placeholder="Seleccione opcion" formControlName="TiposComprobantes">
                                            <mat-option [value]="tipoComprobante.id" *ngFor="let tipoComprobante of lstTiposComprobantes;let i=index;trackBy:trackByItems">
                                                {{ tipoComprobante.descripcion | titlecase }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <mat-form-field class="example-full-width">
                                        <ngx-spinner [zIndex]="100" name="spBusquedaEstadosComprobante" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                        <mat-label>Estados Comprobante</mat-label>
                                        <mat-select placeholder="Seleccione opcion" formControlName="EstadosComprobante">
                                            <mat-option [value]="estadoComprobante.id" *ngFor="let estadoComprobante of lstEstadosComprobante;let i=index;trackBy:trackByItems">
                                                {{ estadoComprobante.descripcion | titlecase }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-contrato (eventEntity)="selectContrato($event)"></app-buscar-contrato>
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-propiedad (eventEntity)="selectPropiedad($event)"></app-buscar-propiedad>&nbsp;&nbsp;
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-persona-propietaria (eventEntity)="selectPropietario($event)" [showDocumento]="false">
                                    </app-buscar-persona-propietaria>&nbsp;&nbsp;
                                </div>
                                <div class="col-3" *ngIf="checkedFiltroAvanzado">
                                    <app-buscar-persona-inquilino (eventEntity)="selectInquilino($event)">
                                    </app-buscar-persona-inquilino>
                                    &nbsp;&nbsp;
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <button mat-stroked-button color="primary" (click)="buscarComprobantes(numeroContrato)">
                  <mat-icon>filter_alt</mat-icon>
                  Filtrar
                </button>&nbsp; &nbsp;

                                    <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                                        Filtros Avanzados </mat-slide-toggle>&nbsp; &nbsp;

                                    <button mat-stroked-button color="primary" *ngIf="checkedFiltroAvanzado" (click)="clearForm()">
                  <mat-icon>clear_all</mat-icon>
                  Limpiar
                </button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button mat-button color="primary" [hidden]="!data" [matMenuTriggerFor]="menuVistas">
        <mat-icon>grid_view</mat-icon>
      </button>
                <mat-menu #menuVistas="matMenu">
                    <button mat-menu-item (click)="
  vistaSimple = true;vistaDetalle=false
">
          <mat-icon [ngClass]="vistaSimple ? 'color-primary' : ''">grading</mat-icon>Vista simple
        </button>
                    <button mat-menu-item (click)="
        vistaSimple = false;vistaDetalle=true
  ">
      <mat-icon [ngClass]="vistaDetalle ? 'color-primary' : ''">view_list</mat-icon>Vista detallada
    </button>
                </mat-menu>
                <button mat-button color="primary" [hidden]="!showDataVistaComprobanteDetalle" [matMenuTriggerFor]="accionesVD" *ngIf="vistaDetalle">
                  Acciones
                </button>
                <mat-menu #accionesVD="matMenu">
                    <!-- <button mat-menu-item (click)="exportarTabla()">
                    Exportar
                  </button> -->
                    <button mat-menu-item (click)="toggle()">
                    Expandir/Comprimir
                  </button>
                </mat-menu>
                <!-- Vista Simple -->
                <div *ngIf="vistaSimple">
                    <table mat-table [dataSource]="dataSourceVistaSimple" [hidden]="!dataVistaSimple" table matSort matSortStart="desc" class="mat-elevation-z8">
                        <!-- Tipo COmprobante Column -->
                        <ng-container matColumnDef="tipoComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span matTooltip="Tipo comprobante">T</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <mat-icon color="primary" style="font-size: medium" *ngIf="
        element.comprobanteDetailDto.cabeceraComprobante.tipoComprobante.descripcion ===
        'Liquidacion'
      " matTooltip="Liquidación propietario">roofing</mat-icon>
                                <mat-icon color="primary" style="font-size: medium" *ngIf="
        element.comprobanteDetailDto.cabeceraComprobante.tipoComprobante.descripcion ===
        'Recibo'
      " matTooltip="Recibo de inquilino">person</mat-icon>
                                <mat-icon color="primary" style="font-size: medium" *ngIf="
        element.comprobanteDetailDto.cabeceraComprobante.tipoComprobante.descripcion ===
        'Resumen'
      " matTooltip="Resumen de inquilino">vpn_key</mat-icon>
                            </mat-cell>
                        </ng-container>

                        <!-- Estado -->
                        <ng-container matColumnDef="estado">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <div matTooltip="Estado"><span>E</span></div>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <mat-icon color="warn" style="font-size: medium" *ngIf="
        element.comprobanteDetailDto.cabeceraComprobante.estadoComprobante.descripcion ===
        'Anulado'
      " matTooltip="Anulada">block</mat-icon>
                            </mat-cell>
                        </ng-container>

                        <!-- Numero Comprobante Column -->
                        <ng-container matColumnDef="numeroComprobante">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Comprobante</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{ element.comprobanteDetailDto.cabeceraComprobante.numeroComprobante }}</span>
                            </mat-cell>
                        </ng-container>

                        <!-- Propiedad Column -->
                        <ng-container matColumnDef="fechaEmision">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Emisión</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{
                element.comprobanteDetailDto.cabeceraComprobante.fechaEmision | date: "dd/MM/yyyy"
                }}</span>
                            </mat-cell>
                        </ng-container>

                        <!-- Contrato Column -->
                        <ng-container matColumnDef="contrato">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Nro. Contrato</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <a color="primary" *ngIf="element.comprobanteDetailDto.cabeceraComprobante.contrato" matTooltip="Ver contrato completo." [routerLink]="[
        '/contratos',
        element.comprobanteDetailDto.cabeceraComprobante.contrato.id
      ]">
                                    <span>{{
                  isFalsy(element.comprobanteDetailDto.cabeceraComprobante.contrato)
                  ? ""
                  : element.comprobanteDetailDto.cabeceraComprobante.contrato.numeroContrato
                  }}</span> </a>&nbsp;
                                <mat-icon matSuffix color="primary" *ngIf="element.comprobanteDetailDto.cabeceraComprobante.contrato" matTooltip="Detalle contrato" class="icon-medium" [matMenuTriggerFor]="matMenuContrato">info
                                </mat-icon>
                                <mat-menu #matMenuContrato="matMenu">
                                    <div class="matMenu">
                                        <p>
                                            <strong>Fecha Comienzo: </strong>
                                            <span class="badge bg-success text-light">
                      {{
                      isFalsy(element.comprobanteDetailDto.cabeceraComprobante.contrato)
                      ? ""
                      : (element.comprobanteDetailDto.cabeceraComprobante.contrato.fechaComienzo
                      | date: "dd/MM/yyyy")
                      }}</span>
                                        </p>
                                        <p>
                                            <strong>Fecha Finalizacion: </strong><span class="badge bg-danger text-light">{{
                      isFalsy(element.comprobanteDetailDto.cabeceraComprobante.contrato)
                      ? ""
                      : (element.comprobanteDetailDto.cabeceraComprobante.contrato.fechaVencimiento
                      | date: "dd/MM/yyyy")
                      }}</span>
                                        </p>
                                        <p>
                                            <strong>Estado:</strong> {{ isFalsy(element.comprobanteDetailDto.cabeceraComprobante.contrato) ? "" : element.comprobanteDetailDto.cabeceraComprobante.contrato.estado .descripcion }}
                                        </p>
                                    </div>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <!-- Propiedad Column -->
                        <!-- <ng-container matColumnDef="propiedad">
      <mat-header-cell mat-header-cell *matHeaderCellDef>
        <span>Propiedad</span>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let element">
        <span>{{
        isFalsy(element.comprobanteDetailDto.cabeceraComprobante.propiedad)
        ? ""
        : (element.comprobanteDetailDto.cabeceraComprobante.propiedad.ubicacion
        | titlecase)
        }}</span>
      </mat-cell>
    </ng-container> -->

                        <!-- Propietario Column -->
                        <ng-container matColumnDef="propietario">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Propietario</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{
                isFalsy(element.comprobanteDetailDto.cabeceraComprobante.propietario)
                ? ""
                : (element.comprobanteDetailDto.cabeceraComprobante.propietario.persona.descripcion
                | titlecase)
                }}</span>
                            </mat-cell>
                        </ng-container>

                        <!-- Propietario Column -->
                        <ng-container matColumnDef="inquilino">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Inquilino</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element">
                                <span>{{
                isFalsy(element.comprobanteDetailDto.cabeceraComprobante.inquilino)
                ? ""
                : (element.comprobanteDetailDto.cabeceraComprobante.inquilino.persona.descripcion
                | titlecase)
                }}</span>
                            </mat-cell>
                        </ng-container>

                        <!-- Total Debe -->
                        <ng-container matColumnDef="totalDebe">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Total Debe</span>
                            </mat-header-cell>
                            <mat-cell style="color: red" mat-cell *matCellDef="let element">
                                <span>
                <strong>{{
                  element.totalDebe
                  | currency
                  }}</strong></span>
                            </mat-cell>
                        </ng-container>

                        <!-- Total Haber -->
                        <ng-container matColumnDef="totalHaber">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Total Haber</span>
                            </mat-header-cell>
                            <mat-cell style="color: green" mat-cell *matCellDef="let element">
                                <span>
                <strong>{{
                  element.totalHaber
                  | currency
                  }}</strong></span>
                            </mat-cell>
                        </ng-container>

                        <!-- Total Pago Column -->
                        <ng-container matColumnDef="totalPago">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Total Pago</span>
                            </mat-header-cell>
                            <mat-cell style="color: green" mat-cell *matCellDef="let element">
                                <mat-icon class="icon-medium" *ngIf="element.comprobanteDetailDto.cabeceraComprobante.pagos.length >= 1" matTooltip="Detalle Pagos" [matMenuTriggerFor]="matMenuPagos">info</mat-icon>
                                <mat-menu #matMenuPagos="matMenu">
                                    <mat-list>
                                        <mat-list-item *ngFor="let pago of element.comprobanteDetailDto.cabeceraComprobante.pagos;let i=index;trackBy:trackByItems">
                                            <mat-icon matListIcon>payments</mat-icon>
                                            <p matLine>
                                                <span style="font-size: small">{{ pago.fechaPago | date: "dd/MM/yyyy" }}
                      </span>
                                            </p>
                                            <p matLine *ngIf="pago.importe > 0">
                                                <span style="font-size: medium">
                        <strong>{{
                          isFalsy(pago.importe) ? 0 : (pago.importe | currency)
                          }}</strong>
                      </span>
                                            </p>
                                            <p matLine *ngIf="pago.descripcion != ''">
                                                <span style="font-size: small">{{
                        pago.descripcion
                        }}</span>
                                            </p>
                                        </mat-list-item>
                                    </mat-list>
                                </mat-menu>
                                &nbsp;
                                <span><strong>{{
                  element.totalPago | currency
                  }}</strong></span>
                            </mat-cell>
                        </ng-container>

                        <!-- Total Comprobante Column -->
                        <ng-container matColumnDef="total">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Total</span>
                            </mat-header-cell>
                            <mat-cell style="color: blue" mat-cell *matCellDef="let element">
                                <span>
                <strong>{{
                  element.totalPago + element.totalHaber - element.totalDebe
                  | currency
                  }}</strong></span>
                            </mat-cell>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="accion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef>
                                <span>Acción</span>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element" class="action-link">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                                <mat-menu #menu="matMenu">
                                    <button *appRole="['Comprobantes.Ver']" mat-menu-item (click)="openDialog(element.comprobanteDetailDto)">
                  <mat-icon>visibility</mat-icon>
                  Ver
                </button>
                                    <button [disabled]="element.comprobanteDetailDto.cabeceraComprobante.estadoComprobante.descripcion === 'Anulado'" *appRole="['Comprobantes.Eliminar']" mat-menu-item (click)="openDialogCancelVoucher(element.comprobanteDetailDto)">
                  <mat-icon>do_not_disturb_on</mat-icon>
                  Anular
                </button>
                                    <button *appRole="['Comprobantes.Enviar']" mat-menu-item (click)="openDialogEnviarCorreoComprobante(element.comprobanteDetailDto)">
                  <mat-icon>send</mat-icon>
                  Enviar
                </button>
                                    <button mat-menu-item *appRole="['Comprobantes.Descargar']" (click)="descargarComprobante(element.comprobanteDetailDto)">
                  <mat-icon>file_download</mat-icon>
                  Descargar
                </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <!-- <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row> -->
                        <mat-header-row *matHeaderRowDef="columns"> </mat-header-row>
                        <mat-row *matRowDef="let row; columns: columns"></mat-row>
                    </table>

                    <div class="no-data-table">
                        <span class="with-icon" *ngIf="dataSourceVistaSimple.data.length == 0">
            <mat-icon>search_off</mat-icon>No se encontraron datos para
            mostrar.
          </span>
                    </div>
                    <mat-paginator showFirstLastButtons class="paginator" [hidden]="!data" [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
                    </mat-paginator>
                </div>
                <!-- Vista Detalle -->
                <div *ngIf="vistaDetalle">
                    <div class="row">
                        <div class="col-12">

                            <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                            </ngx-spinner>
                            <!-- Vista Detalle -->
                            <table id="htmlData" mat-table [dataSource]="dataSourceComprobanteDetalle" [hidden]="!showDataVistaComprobanteDetalle" multiTemplateDataRows class="table-comprobane-detail mat-elevation-z8">

                                <ng-container matColumnDef="vdestado">
                                    <th mat-header-cell fxFlex="10" *matHeaderCellDef>
                                        <span><strong>Estado</strong></span>
                                    </th>
                                    <td mat-cell fxFlex="10" *matCellDef="let element">
                                        <span>{{isFalsy(element.estado) ? "": element.estado | titlecase}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vdfechaemision">
                                    <th mat-header-cell fxFlex="10" *matHeaderCellDef>
                                        <span><strong>Fecha Emisión</strong></span>
                                    </th>
                                    <td mat-cell fxFlex="10" *matCellDef="let element">
                                        <span>{{isFalsy(element.fechaEmision) ? "": element.fechaEmision  | date:'dd/MM/yyyy' }}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vdnumerocomprobante">
                                    <th mat-header-cell fxFlex="15" *matHeaderCellDef>
                                        <span><strong>Comprobante</strong></span>
                                    </th>
                                    <td mat-cell fxFlex="15" *matCellDef="let element">
                                        <span>{{isFalsy(element.numeroComprobante) ? "": element.numeroComprobante}}</span>
                                    </td>
                                </ng-container>

                                <ng-container fxFlex="20" matColumnDef="vdpropiedad">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <span><strong>Propiedad</strong></span>
                                    </th>
                                    <td mat-cell fxFlex="20" *matCellDef="let element">
                                        <span>{{isFalsy(element.propiedad) ? "": element.propiedad | titlecase}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vdinquilino">
                                    <th mat-header-cell fxFlex="15" *matHeaderCellDef>
                                        <span><strong>Inquilino</strong></span>
                                    </th>
                                    <td mat-cell fxFlex="15" *matCellDef="let element">
                                        <span>{{isFalsy(element.inquilino) ? "": element.inquilino | titlecase}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vdpropietario">
                                    <th mat-header-cell fxFlex="15" *matHeaderCellDef>
                                        <span><strong>Propietario</strong></span>
                                    </th>
                                    <td mat-cell fxFlex="15" *matCellDef="let element">
                                        <span>{{isFalsy(element.propietario) ? "": element.propietario | titlecase}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="vdaccion">
                                    <th fxFlex="5" mat-header-cell *matHeaderCellDef>
                                        <span>Acción</span>
                                    </th>
                                    <td fxFlex="5" mat-cell *matCellDef="let element" class="action-link">
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
                                        <mat-menu #menu="matMenu">
                                            <button *appRole="['Comprobantes.Ver']" mat-menu-item (click)="openDialog(element.comprobanteDetailDto)">
          <mat-icon>visibility</mat-icon>
          Ver
        </button>
                                            <button [disabled]="element.estado === 'Anulado'" *appRole="['Comprobantes.Eliminar']" mat-menu-item (click)="
        openDialogCancelVoucher(element.comprobanteDetailDto)
      ">
          <mat-icon>do_not_disturb_on</mat-icon>
          Anular
        </button>
                                            <button *appRole="['Comprobantes.Enviar']" mat-menu-item (click)="
        openDialogEnviarCorreoComprobante(
          element.comprobanteDetailDto
        )
      ">
          <mat-icon>send</mat-icon>
          Enviar
        </button>
                                            <button *appRole="['Comprobantes.Descargar']" mat-menu-item (click)="descargarComprobante(element.comprobanteDetailDto)">
          <mat-icon>file_download</mat-icon>
          <span>Descargar</span>
        </button>
                                        </mat-menu>
                                    </td>
                                    <mat-cell mat-footer-cell *matFooterCellDef> </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="expand">
                                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
      </button>
                                    </td>
                                </ng-container>

                                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                <ng-container matColumnDef="expandedDetail">
                                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                                        <div class="example-element-detail" [@detailExpand]="(element == expandedElement || allRowsExpanded) ? 'expanded' : 'collapsed'">
                                            <table mat-table [dataSource]="element.conceptos" class="table-comprobante-detail tabla-expanded-detail" table matSort matSortStart="desc">

                                                <ng-container matColumnDef="vdconcepto">
                                                    <th fxFlex="25" mat-header-cell *matHeaderCellDef>
                                                        <span>Concepto</span>
                                                    </th>
                                                    <td fxFlex="25" mat-cell *matCellDef="let element">
                                                        <span>{{isFalsy(element.descripcion) ? "": element.descripcion | titlecase}}</span>
                                                    </td>
                                                    <td fxFlex="25" mat-footer-cell *matFooterCellDef> <span><strong>Total</strong></span> </td>
                                                </ng-container>

                                                <ng-container matColumnDef="vdperiodo">
                                                    <th fxFlex="15" mat-header-cell *matHeaderCellDef>
                                                        <span>Periodo</span>
                                                    </th>
                                                    <td fxFlex="15" mat-cell *matCellDef="let element">
                                                        <span>{{isFalsy(element.periodo) ? "": element.periodo}}</span>
                                                    </td>
                                                    <td fxFlex="15" mat-footer-cell *matFooterCellDef> </td>
                                                </ng-container>

                                                <ng-container matColumnDef="vdcuenta">
                                                    <th fxFlex="15" mat-header-cell *matHeaderCellDef>
                                                        <span>Cuenta</span>
                                                    </th>
                                                    <td fxFlex="15" mat-cell *matCellDef="let element">
                                                        <span>{{isFalsy(element.cuenta) ? "": element.cuenta}}</span>
                                                    </td>
                                                    <td fxFlex="15" mat-footer-cell *matFooterCellDef> </td>
                                                </ng-container>

                                                <ng-container matColumnDef="vddebe">
                                                    <th fxFlex="15" mat-header-cell *matHeaderCellDef>
                                                        <span>Debe</span>
                                                    </th>
                                                    <td fxFlex="15" mat-cell *matCellDef="let element">
                                                        <span>{{isFalsy(element.debe) ? "": element.debe | currency}}</span>
                                                    </td>
                                                    <td fxFlex="15" mat-footer-cell *matFooterCellDef><span><strong> {{getTotalVistaDetalle(element.conceptos) | currency}} </strong></span></td>
                                                </ng-container>

                                                <ng-container matColumnDef="vdhaber">
                                                    <th fxFlex="15" mat-header-cell *matHeaderCellDef>
                                                        <span>Haber</span>
                                                    </th>
                                                    <td fxFlex="15" mat-cell *matCellDef="let element">
                                                        <span>{{isFalsy(element.haber) ? "": element.haber | currency}}</span>
                                                    </td>
                                                    <td fxFlex="15" mat-footer-cell *matFooterCellDef> <span><strong>{{getTotalHaberDetalle(element.conceptos) | currency}}</strong> </span></td>
                                                </ng-container>
                                                <ng-container matColumnDef="vdtotal">
                                                    <th fxFlex="15" mat-header-cell *matHeaderCellDef>
                                                        <span></span>
                                                    </th>
                                                    <td fxFlex="15" mat-cell *matCellDef="let element">
                                                    </td>
                                                    <td fxFlex="15" mat-footer-cell *matFooterCellDef>
                                                        <span><strong>{{getTotalDetalle(element.conceptos) | currency}}</strong></span>
                                                    </td>
                                                </ng-container>

                                                <tr mat-header-row *matHeaderRowDef="columnsToDisplayDetalle"></tr>
                                                <tr mat-row *matRowDef="let element; columns: columnsToDisplayDetalle;"></tr>
                                                <tr mat-footer-row *matFooterRowDef="columnsToDisplayDetalle"></tr>
                                            </table>
                                        </div>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                                <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                            </table>

                            <div class="no-data-table">
                                <span class="with-icon" [hidden]="showDataVistaComprobanteDetalle">
    <mat-icon>search_off</mat-icon>No se encontraron datos para
    mostrar.
  </span>
                            </div>
                            <mat-paginator showFirstLastButtons class="paginator" [hidden]="!showDataVistaComprobanteDetalle" [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100, 200]">
                            </mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>
