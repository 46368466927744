import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.mode';
import { MovimientoDetailDto } from '@models/movimiento/movimientoDetailDto.model';
import { MovimientoDto } from '@models/movimiento/movimientoDto.model';
import { UpdateMovimientoRequest } from '@models/movimiento/updateMovimientoRequestUpdate.mode';
import { isFalsy } from 'utility-types';

type NewType = Observable<any>;

@Injectable({
  providedIn: 'root'
})
export class MovimientosService {

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http: HttpClient) { }

  create(createMovimientoRequest: CreateMovimientoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Movimiento/Create", createMovimientoRequest);
  }

  update(updateMovimientoRequest: UpdateMovimientoRequest): Observable<any> {
    return this.http.put<any>("/api/Movimiento/Update", updateMovimientoRequest);
  }

  getAll(): Observable<MovimientoDetailDto[]> {
    return this.http.get<MovimientoDetailDto[]>("/api/Movimiento/GetAll");
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Movimiento/Delete?id=" + id);
    else
      return of(null)
  }

  getAllByFilter(fechaEmsionDesde: string, fechaEmisionHasta: string, numeroContrato: number | null, idTipoMovimiento: number | null, idInquilino: number | null, idContrato: number | null, idPropiedad: number | null, idPropietario: number | null, idPeriodoContrato: number | null): Observable<MovimientoDetailDto[]> {

    let filtro = ""

    if (fechaEmsionDesde != null && fechaEmisionHasta != null && fechaEmsionDesde != "" && fechaEmisionHasta != "")
      filtro += "?fechaEmisionDesde=" + fechaEmsionDesde + "&fechaEmisionHasta=" + fechaEmisionHasta

    if (numeroContrato != null && numeroContrato != 0)
      filtro += "&numeroContrato=" + numeroContrato

    if (idTipoMovimiento != null && idTipoMovimiento != 0)
      filtro += "&idTipoMovimiento=" + idTipoMovimiento

    if (idInquilino != 0 && idInquilino != null)
      filtro += "&idInquilino=" + idInquilino

    if (idContrato != 0 && idContrato != null)
      filtro += "&idContrato=" + idContrato

    if (idPropiedad != 0 && idPropiedad != null)
      filtro += "&idPropiedad=" + idPropiedad

    if (idPropietario != 0 && idPropietario != null)
      filtro += "&idPropietario=" + idPropietario

    if (idPeriodoContrato != 0 && idPeriodoContrato != null)
      filtro += "&idPeriodoContrato=" + idPeriodoContrato

    return this.http.get<MovimientoDetailDto[]>("/api/Movimiento/GetAllByFilter" + filtro);
  }

  getById(id: number): Observable<MovimientoDetailDto> {
    return this.http.get<MovimientoDetailDto>("/api/Movimiento/GetById?id=" + id);
  }
}
