import { Component, OnInit } from '@angular/core';
//Servicios
import { PersonasService } from '@services/personas/personas.service';
import { EstadosCivilService } from "@services/estados-civil/estados-civil.service";
import { TiposDocumentoService } from "@services/tipos-documento/tipos-documento.service";
import { LocalidadesService } from "@services/localidades/localidades.service";
//Models
import { EstadoCivilDto } from "@models/estado-civil/estadoCivilDto.model";
import { TipoDocumentoDto } from "@models/tipo-documento/tipoDocumentoDto.model";
import { LocalidadDto } from "@models/localidad-provincia/localidadDto.model";
import { CreatePersonaRequest } from "@models/personas/createPersonaRequest.model"
import { UpdatePersonaRequest } from "@models/personas/updatePersonaRequest.model"
//
import { FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms'
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
//VAlidaciones
import { ProvinciaDto } from '@models/localidad-provincia/provinciaDto.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { PuedeDesactivar } from 'src/app/directives/can-deactivate/can-deactivate.guard';
import { LoginService } from '@services/login/login.service';
import { Roles } from '@models/base/identificadores.model';
import { Store } from '@ngrx/store';
import { SetearPersona } from 'src/app/redux/app.actions';
import { AppState } from 'src/app/redux/app.reducers';
import { PaisService } from '@services/pais/pais.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-persona-update',
  templateUrl: './form-persona-update.component.html',
  styleUrls: ['./form-persona-update.component.css']
})
export class FormPersonaUpdateComponent implements OnInit, PuedeDesactivar {

  subMenuActive: boolean = false;

  personaForm!: FormGroup
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  lstEstadosCivilModel: EstadoCivilDto[] = [];
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  idTipoDocumento: number = 0;
  idLocalidad: number = 0;
  idEstadoCivil: number = 0;
  formBuilder: FormBuilder = new FormBuilder;
  enviado!: boolean;
  usuarioCliente: boolean = false;
  lstPaises: BaseDto[] = []
  localidad!: BaseDto;
  get f() { return this.personaForm.controls; }

  constructor(
    private personasService: PersonasService,
    private tipoDocumentoService: TiposDocumentoService,
    private estadoCivilService: EstadosCivilService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private aRoute: ActivatedRoute,
    private loginService: LoginService,
    private store: Store<AppState>,
    private paisService: PaisService
    ) {
    this.newFormPersona();
  }

  newFormPersona() {
    this.personaForm = this.formBuilder.group({
      //Persona
      IdPersona: [],
      NombreCompleto: ["", Validators.compose([Validators.required, Validators.maxLength(50)])],
      ApellidoMaterno: ['', Validators.compose([Validators.maxLength(50)])],
      NroDocumento: ['',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
        this.validaPersonaByNroDocYTipoDoc.bind(this)],
      TelefonoPrincial: ['', Validators.compose([Validators.maxLength(20)])],
      TelefonoAlternativo: ['', Validators.compose([Validators.maxLength(20)])],
      TelefonoLaboral: ['', Validators.compose([Validators.maxLength(20)])],
      Sexo: ['', Validators.compose([Validators.required])],
      Correo: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      FechaNacimiento: [''],
      Ubicacion: ['', Validators.compose([Validators.maxLength(50)])],
      UbicacionLaboral: ['', Validators.compose([Validators.maxLength(50)])],
      Provincia: [''],
      CodigoCpa: ['', Validators.compose([Validators.maxLength(8)])],
      EstadoCivil: ['', Validators.compose([Validators.required])],
      TiposDocumento: ['', Validators.compose([Validators.required])],
      Ocupacion: ['', Validators.compose([Validators.maxLength(50)])],
      Observacion: ['', Validators.compose([Validators.maxLength(2000)])],
      Pais: [''],
    });

    this.spinner.show("spPersona")
    forkJoin({
      paises: this.paisService.getAll(),
      estadosCivil: this.estadoCivilService.getAll(),
      tiposDocumentos: this.tipoDocumentoService.getAll()
    })
      .subscribe(
        ({ paises, estadosCivil, tiposDocumentos }) => {

          this.lstPaises = paises;
          this.lstEstadosCivilModel = estadosCivil
          this.lstTipoDocumentoDto = tiposDocumentos
          this.spinner.hide("spPersona")

        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spPersona")
        });
  }

  permitirSalidaDeRuta(): boolean | import("rxjs").Observable<boolean> | Promise<boolean> {
    if (this.enviado = true) {
      return true
    }
    const confirmacion = window.confirm("¿Quier salir del formulario y perder los datos guardados?")
    return confirmacion
  }

  ngOnInit(): void {

    if (this.loginService.getIdUserRol() == Roles.Clientes)
      this.usuarioCliente = true
    this.personaDetailDto = this.personasService.getPersona()
    const action = new SetearPersona(this.personaDetailDto)
    this.store.dispatch(action)
    this.completaFormulario(this.personaDetailDto);

  }

  ngOnDestroy() {
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }

  completaFormulario(data: PersonaDetailDto) {
    this.personaForm.controls["IdPersona"].setValue(data.id)
    this.personaForm.controls["NombreCompleto"].setValue(data.nombreCompleto.toString())
    this.personaForm.controls["ApellidoMaterno"].setValue(data.apellidoMaterno)
    this.personaForm.controls["TelefonoPrincial"].setValue(data.telefonoPrincial)
    this.personaForm.controls["TelefonoAlternativo"].setValue(data.telefonoAlternativo)
    this.personaForm.controls["TelefonoLaboral"].setValue(data.telefonoLaboral)
    this.personaForm.controls["Sexo"].setValue(data.sexo)
    this.personaForm.controls["Correo"].setValue(data.correo)
    this.personaForm.controls["NroDocumento"].setValue(data.nroDocumento)
    if (data.fechaNacimiento != null) {
      var fecha = data.fechaNacimiento.split('-');
      var anio = fecha[0];
      var mes = fecha[1];
      var dia = fecha[2] =! "" ? fecha[2].substring(0, 2): "";
      let fechaCompleta = anio + "-" + mes + "-" + dia
      this.personaForm.controls["FechaNacimiento"].setValue(fechaCompleta)
    }
    this.personaForm.controls["Ubicacion"].setValue(data.ubicacion)
    this.personaForm.controls["UbicacionLaboral"].setValue(data.ubicacionLaboral)
    this.personaForm.controls["CodigoCpa"].setValue(data.codigoCpa)

    if (data.pais != null) {
      this.personaForm.controls["Pais"].setValue(data.pais.id)
    }

    if (data.estadoCivil != null) {
      this.personaForm.controls["EstadoCivil"].setValue(data.estadoCivil.id)
    }

    this.personaForm.controls["Ocupacion"].setValue(data.ocupacion)

    if (data.tipoDocumento != null) {
      this.personaForm.controls["TiposDocumento"].setValue(data.tipoDocumento.id)
    }

    this.personaForm.controls["Observacion"].setValue(data.observacion)
    this.localidad = data.localidad
    this.subMenuActive = true;

  }

  setupdatePersonaRequest(): UpdatePersonaRequest {

    const updatePersonaRequest = new UpdatePersonaRequest();

    var fecha = this.personaForm.controls['FechaNacimiento'].value.split('-');
    var fechaCompleta = "";
    if (fecha != "") {
      var anio = fecha[0];
      var mes = fecha[1];
      var dia = fecha[2];
      fechaCompleta = anio + "-" + mes + "-" + dia;
    }

    updatePersonaRequest.id = this.personaForm.controls['IdPersona'].value;
    updatePersonaRequest.nombreCompleto = this.personaForm.controls['NombreCompleto'].value;
    updatePersonaRequest.apellidoMaterno = this.personaForm.controls['ApellidoMaterno'].value;
    updatePersonaRequest.nroDocumento = this.personaForm.controls['NroDocumento'].value;
    updatePersonaRequest.id_TipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    updatePersonaRequest.id_EstadoCivil = this.personaForm.controls['EstadoCivil'].value;
    updatePersonaRequest.telefonoPrincial = this.personaForm.controls['TelefonoPrincial'].value;
    updatePersonaRequest.telefonoAlternativo = this.personaForm.controls['TelefonoAlternativo'].value;
    updatePersonaRequest.telefonoLaboral = this.personaForm.controls['TelefonoLaboral'].value;
    updatePersonaRequest.sexo = this.personaForm.controls['Sexo'].value;
    updatePersonaRequest.correo = this.personaForm.controls['Correo'].value;
    updatePersonaRequest.fechaNacimiento = fechaCompleta;
    updatePersonaRequest.ubicacion = this.personaForm.controls['Ubicacion'].value;
    updatePersonaRequest.ubicacionLaboral = this.personaForm.controls['UbicacionLaboral'].value;
    updatePersonaRequest.id_Localidad = this.localidad.id
    updatePersonaRequest.codigoCpa = this.personaForm.controls['CodigoCpa'].value
    updatePersonaRequest.observacion = this.personaForm.controls['Observacion'].value;
    updatePersonaRequest.ocupacion = this.personaForm.controls['Ocupacion'].value;
    updatePersonaRequest.idPais = this.personaForm.controls['Pais'].value;

    return updatePersonaRequest
  }



  onSubmit() {
    this.spinner.show()

    if (this.personaForm.invalid || isFalsy(this.localidad)) {
      return;
    }

    const updatePersonaRequest = this.setupdatePersonaRequest()
    this.personasService.updatePersona(updatePersonaRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Se guardaron los datos correctamente.", "");
          this.enviado = true;
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        })
  }

  //Selecciones
  selectTipoDocumento(event: any) {
    this.idTipoDocumento = event
  }

  selectEstadoCivil(event: any) {
    this.idEstadoCivil = event
  }

  onReset() {
    this.personaForm.reset();
  }

  goBack() {
    window.history.back();
  }

  //Validaciones
  validaLargoNumerico(control: FormControl) {
    if (control.value != null && control.value != "") {
      if (<number>control.value > 999999999999) {
        return { validaLargoNumerico: true }
      }
      else
        return { validaLargoNumerico: false }
    }
    else
      return { validaLargoNumerico: false }
  }

  validaPersonaByNroDocYTipoDoc(controlNroDoc: FormControl) {
    let idTipoDocumento = this.personaForm.controls['TiposDocumento'].value;
    if (controlNroDoc.value.toString().length > 7  && !isFalsy(idTipoDocumento) ) {
      let promesa = new Promise((resolve, reject) => {
        this.personasService.validaPersonaByNroDocYTipoDoc(this.personaDetailDto.id, controlNroDoc.value, idTipoDocumento)
          .subscribe(data => {
            if (data == true) {
              resolve({ existePersona: true })
            }
            else {
              resolve(true)
            }
          })
      });
      return promesa;
    }
    else {
      let promesa = new Promise((resolve, reject) => {
        resolve(true)
      });
      return promesa;
    }
  }
  //Fin Validaciones

  isFalsy(valor:any){
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
