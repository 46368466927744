<mat-form-field>
    <ngx-spinner [zIndex]="1" name="spBusquedaPropietaria" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
    <mat-label>Buscar propietario</mat-label>
    <input type="text" placeholder="Buscar por nombre o doc." matInput [matAutocomplete]="auto" [formControl]="entityControl" />
    <button disabled mat-icon-button matSuffix>
            <mat-icon>search</mat-icon>
        </button>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option [disabled]="disable" *ngFor="let propietario of filteredPropietarios | async ;let i=index;trackBy:trackByItems" [value]="propietario.descripcion" (click)="selectValue(propietario)" (onSelectionChange)="selectValue(propietario)">
            <span #tooltip="matTooltip" matTooltipPosition="right" matTooltip={{propietario.nroDocumento}}>{{propietario.descripcion | titlecase}}</span>
        </mat-option>
    </mat-autocomplete>
    <button mat-icon-button matSuffix *ngIf="entitySelect" (click)="editarPersona(entitySelect.id)" matTooltip="Editar persona" matTooltipPosition="below">
      <mat-icon color="primary" >info</mat-icon>
      </button>

    <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="entitySelect" (click)="selectValue(null)">
      <mat-icon>clear</mat-icon>
      </button>
</mat-form-field>
<mat-checkbox [formControl]="sinPropiedad" matTooltip="Con propiedades" class="example-disabled-checkbox">
    <mat-icon>roofing</mat-icon>

</mat-checkbox>