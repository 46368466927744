<div class="container-fluid">
    <br>
    <div class="row">
        <!-- Accesos Rapidos   -->
        <div class="col-lg-2 col-md-12 col-sm-12" *appRole="['Dashboard.General.Acciones']">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Acciones
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <div class="row">
                        <div class="col" style="margin-bottom: 10px;">
                            <app-btn-nuevo-propiedad></app-btn-nuevo-propiedad>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-btn-nueva-persona></app-btn-nueva-persona>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-btn-new-inquilino></app-btn-new-inquilino>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <!-- Contadores -->
        <div class="col-lg-6 col-md-10 col-sm-12" *appRole="['Dashboard.General.Contadores']">
            <mat-card>
                <ngx-spinner name="spContadores" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Entidades
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadoresEntidades">

                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css" />
                    <div class="container">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="card-counter primary">
                                    <i class="fa fa-key"></i>
                                    <span class="count-numbers">{{contadoresEntidades.inquilinos}}</span>
                                    <span class="count-name">Inquilinos</span>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card-counter danger">
                                    <i class="fa fa-building"></i>
                                    <span class="count-numbers">{{contadoresEntidades.propietarios}}</span>
                                    <span class="count-name">Propietarios</span>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card-counter success">
                                    <i class="fa fa-home"></i>
                                    <span class="count-numbers">{{contadoresEntidades.propiedades}}</span>
                                    <span class="count-name">Propiedades</span>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="card-counter info">
                                    <i class="fa fa-user"></i>
                                    <span class="count-numbers">{{contadoresEntidades.garantias}}</span>
                                    <span class="count-name">Garantias</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <!-- Estados de servico -->
        <div class="col-lg-4 col-md-10 col-sm-12" *appRole="['Dashboard.General.Servicios']">
            <mat-card>
                <ngx-spinner name="spGetContadorDetalleServicioPropiedad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Estados de Servicios (hoy)
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorDetalleServicioPropiedadDto">
                    <div class="row">
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="with-icon">
                                <mat-icon class="icon-success count-icon">price_check</mat-icon><span class="aligned-with-icon"><strong>Cobrados:</strong> </span><span class="count-number aligned-with-icon">{{contadorDetalleServicioPropiedadDto.cobrados}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="with-icon">
                                <mat-icon class="icon-info count-icon">attach_money</mat-icon><span class="aligned-with-icon"><strong>No Vencidos:</strong></span><span class="count-number aligned-with-icon"> {{contadorDetalleServicioPropiedadDto.noVencidos}}</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="with-icon">
                                <mat-icon class="icon-error count-icon">money_off</mat-icon><span class="aligned-with-icon"><strong>Deuda:</strong> </span><span class="count-number aligned-with-icon">{{contadorDetalleServicioPropiedadDto.deuda}}</span>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <!-- Pagos -->
    <div class="row" *appRole="['Dashboard.General.Pagos']">
        <div class="col-lg-4 col-md-12 col-sm-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Filtro de Pagos
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <from [formGroup]="form">
                        <mat-form-field class="full-width">
                            <mat-label>Ingrese rango de fechas</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                                <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                        </mat-form-field>&nbsp;&nbsp;
                        <button mat-stroked-button color="primary" (click)="filtrarPagos()">
<mat-icon>filter_alt</mat-icon>
Filtrar</button>
                    </from>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12">
            <mat-card>
                <ngx-spinner name="spGetContadorPagos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Estado de Pagos
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadorPagosDto">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="with-icon">
                                <mat-icon class="icon-success count-icon">attach_money</mat-icon><span class="aligned-with-icon"><strong>Completos:</strong> </span><span class="count-number aligned-with-icon">{{contadorPagosDto.completo}}</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="with-icon">
                                <mat-icon class="icon-warn count-icon">money_off</mat-icon><span class="aligned-with-icon"><strong>Pendientes:</strong></span><span class="count-number aligned-with-icon"> {{contadorPagosDto.pendiente}}</span>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12">
            <mat-card>
                <ngx-spinner name="spGetContadorPagos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                </ngx-spinner>
                <mat-card-header>
                    <mat-card-title>
                        Pagos por Formas de Pago
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content *ngIf="contadoPagosPorFormaPagoDto">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="chart-wrapper">
                                <canvas baseChart [data]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
</canvas>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
