<div class="position-relative">
    <ngx-spinner [zIndex]=100 name="spMovimiento" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="movimientoForm">

        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Datos de movimiento
                    </mat-panel-title>
                    <mat-panel-description>
                        Ingrese valores del movimiento
                        <mat-icon> account_balance</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <br>
                <div class="row">
                    <div class="col-lg-2">
                        <mat-form-field class="example-full-width">
                            <ngx-spinner [zIndex]=100 name="spBusquedaTiposMovimiento" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label>Tipo Movimiento</mat-label>
                            <mat-select placeholder="Seleccione opcion" formControlName="TipoMovimiento">
                                <mat-option [value]="tipoMovimiento.id" *ngFor="let tipoMovimiento of lstTiposMovimiento;let i=index;trackBy:trackByItems">
                                    {{tipoMovimiento.resta == true ? tipoMovimiento.descripcion + ' (-)' : tipoMovimiento.descripcion + ' (+)'}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="f.TipoMovimiento.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field>
                            <mat-label>Mes y Año</mat-label>
                            <input matInput [matDatepicker]="dp" formControlName="Periodo">
                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2">
                        <mat-form-field class="example-full-width">
                            <span matPrefix>$ &nbsp;</span>
                            <mat-label>Importe</mat-label>
                            <input type="text" mask="separator.2" thousandSeparator="," placeholder="Importe" formControlName="Importe" matInput>
                            <mat-error *ngIf="f.Importe.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-5">
                        <app-buscar-contrato [width]="classContrato" (eventEntity)="selectContrato($event)">
                        </app-buscar-contrato>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <br>
                <div>
                    <ngx-spinner [zIndex]="100" name="spBusquedaContrato" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <app-card-contrato *ngIf="!isFalsy(contratoDetailDto)" [contratoDetailDto]="contratoDetailDto">
                    </app-card-contrato>
                </div>


                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()" [disabled]="!movimientoForm.valid || contratoDetailDto==undefined || contratoDetailDto==null">Siguiente</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Destino del movimiento
                    </mat-panel-title>
                    <mat-panel-description>
                        Asignacion del movimiento a Propietario o Inquilino
                        <mat-icon> account_balance</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Atención!</strong> Siempre se debe seleccionar un <strong>Inquilino</strong> o un
                    <strong>Propietario</strong> y un <strong>Contrato</strong> para asignar el movimiento correctamente.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>

                <div class="row">
                    <div class="col-lg-3">
                        <!-- <app-buscar-persona-inquilino (eventEntity)="selectInquilino($event)"
              [inquilinoEntrada]="isFalsy(contratoDetailDto) || contratoDetailDto == null ? null : contratoDetailDto.inquilino">
            </app-buscar-persona-inquilino> -->
                        <app-buscar-persona-inquilino (eventEntity)="selectInquilino($event)">
                        </app-buscar-persona-inquilino>

                        <div *ngIf="inquilinoDto" class="with-icon">
                            <mat-icon matSuffix matLine *ngIf="inquilinoDto" color="warn" (click)="selectInquilino(null)" matTooltip="Quitar" matTooltipPosition="below">close</mat-icon>
                            <span><b>Inquilino: </b> {{isFalsy(inquilinoDto) ? "": inquilinoDto.persona.descripcion |
                titlecase}}</span>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <app-buscar-persona-propietaria [checkSinPropiedad]="true" (eventEntity)="selectPropietario($event)" [showDocumento]="false">
                        </app-buscar-persona-propietaria>

                        <div *ngIf="propietario" class="with-icon">
                            <mat-icon matSuffix matLine *ngIf="propietario" color="warn" (click)="selectPropietario(null)" matTooltip="Quitar" matTooltipPosition="below">close</mat-icon>
                            <span><b>Propietario: </b>{{isFalsy(propietario) ? "" :propietario.descripcion | titlecase}}</span>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <app-buscar-propiedad (eventEntity)="selectPropiedad($event)"></app-buscar-propiedad>
                        <div *ngIf="propiedad" class="with-icon">
                            <mat-icon matSuffix matLine *ngIf="propiedad" color="warn" (click)="selectPropiedad(null)" matTooltip="Quitar" matTooltipPosition="below">close</mat-icon>
                            <span><b>Propiedad: </b>{{isFalsy(propiedad) ? "": propiedad.descripcion | titlecase}}</span>
                        </div>
                    </div>
                </div>

                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Anterior</button>
                    <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Descripción del movimiento
                    </mat-panel-title>
                    <mat-panel-description>
                        Informacion extra sobre el movimiento
                        <mat-icon> account_balance</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field class="full-width-x100">
                            <mat-label>Descripción</mat-label>
                            <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                            <mat-error *ngIf="f.Descripcion.errors?.maxlength">Supero el maximo de caracteres.</mat-error>
                            <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
                            <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Anterior</button>
                    <button mat-raised-button color="primary" *appRole="['Movimientos.Crear']" [disabled]="!movimientoForm.valid" (click)="onSubmit()">Agregar</button>
                    <button mat-button mat-dialog-close color="warn" (click)="closeDialog()">Cancelar</button>
                </mat-action-row>
            </mat-expansion-panel>

        </mat-accordion>
        <br>
        <mat-divider></mat-divider>
        <br>
    </form>
</div>