import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateGarantiaRequest } from '@models/garantia/createGarantiaRequest.model';
import { AsignarGarantiaInquilinoRequest } from '@models/garantia/asignarGarantiaInquilinoRequest.model';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { GarantiaSummaryDto } from '@models/garantia/garantiaSummaryDto.model';
import { CreateGarantiaWithPersona } from '@models/garantia/createGarantiaWithPersona.model';
import { UpdateGarantiaRequest } from '@models/garantia/updateGarantiaRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { isFalsy } from 'utility-types';
import { DisableGarantiaRequest } from '@models/garantia/disableGarantiaRequest.model';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';
import { EnableGarantiaRequest } from '@models/garantia/enableGarantiaRequest.model';

@Injectable({
  providedIn: 'root'
})
export class GarantiasService {
  idPersona: number = 0;
  garantiaDto: GarantiaDto = new GarantiaDto;
  public garantia!: GarantiaSummaryDto
  public garantias!: GarantiaSummaryDto[]

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }


  create(createGarantiaRequest: CreateGarantiaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Garantias/Create", createGarantiaRequest);
  }

  update(updateGarantiaRequest: UpdateGarantiaRequest): Observable<any> {
    return this.http.put("/api/Garantias/Update", updateGarantiaRequest);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Garantias/Delete?id=" + id);
    else
      return of(null)
  }

  asignarGarantiaInquilino(asignarGarantiaInquilinoRequest: AsignarGarantiaInquilinoRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Garantias/AsignarGarantiaInquilino", asignarGarantiaInquilinoRequest)
  }

  getAllGarantiasByPersona(idPersona: number): Observable<GarantiaSummaryDto[]> {
    return this.http.get<GarantiaSummaryDto[]>("/api/Garantias/GetAllGarantiasByPersonaOrByInquilino?idPersona=" + idPersona);
  }

  getAllGarantiasByInquilino(idPersonaInquilino: number): Observable<GarantiaSummaryDto[]> {
    return this.http.get<GarantiaSummaryDto[]>("/api/Garantias/GetAllGarantiasByPersonaOrByInquilino?idPersonaInquilino=" + idPersonaInquilino);
  }

  getGarantiaById(idGarantia: number): Observable<GarantiaDto> {
    return this.http.get<GarantiaDto>("/api/Garantias/GetById?id=" + idGarantia)
  }

  createGarantiaWithPersona(createGarantiaWithPersona: CreateGarantiaWithPersona): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Garantias/CreateGarantiaWithPersona", createGarantiaWithPersona);
  }

  enableGarantia(enableGarantiaRequest: EnableGarantiaRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Garantias/EnableGarantia", enableGarantiaRequest)
  }

  disableGarantia(disableGarantiaRequest: DisableGarantiaRequest): Observable<MessageSuccessfullDto> {
    return this.http.post<MessageSuccessfullDto>("/api/Garantias/DisableGarantia", disableGarantiaRequest)
  }

  getAllTiposGarantia(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/TiposGarantia/getAll");
  }


}
