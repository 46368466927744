<div class="row content">
    <div class="col-sm-2">
        <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action active ">
                  <div class="with-icon">
                    <mat-icon >account_circle</mat-icon><span class="aligned-with-icon"> Usuario</span>
                  </div>
            </button>
        </div>
    </div>

    <div class="col-sm-10">
        <br>
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [disabled]="false" [expanded]='true' [hideToggle]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Datos Personales
                    </mat-panel-title>
                    <mat-icon style="margin-right: 10px;">user</mat-icon>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <ngx-spinner [zIndex]="100" name="spPersona" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                    </ngx-spinner>
                    <form [formGroup]="usuarioForm">
                        <div class="row">
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <mat-label>Nombre de usuario</mat-label>
                                    <input matInput type="text" maxlength="50" formControlName="NombreUsuario">
                                    <mat-error *ngIf="f.NombreUsuario.errors?.required">Ingrese nombre completo.
                                    </mat-error>
                                    <mat-error *ngIf="f.NombreUsuario.errors?.maxlength">Supero el maximo de caracteres.
                                    </mat-error>
                                    <mat-icon matSuffix color="primary" mat-icon-button *ngIf="!usuarioCliente" [routerLink]="['/usuarios',idUsuario]" #tooltip="matTooltip" matTooltip="Ver mas info.">
                                        info
                                    </mat-icon>

                                </mat-form-field>
                            </div>
                            <div class="col-md-3 mb-3">
                                <mat-form-field appearance="standard">
                                    <mat-label>Persona</mat-label>
                                    <input matInput type="text" maxlength="50" formControlName="Persona">
                                    <mat-icon matSuffix mat-icon-button color="primary" *ngIf="!usuarioCliente" (click)="editarPersona()" #tooltip="matTooltip" matTooltip="Ver mas info.">
                                        info
                                    </mat-icon>
                                    <mat-icon matSuffix mat-icon-button color="primary" *ngIf="usuarioCliente" (click)="editarPersona()" #tooltip="matTooltip" matTooltip="Editar datos personales.">
                                        edit
                                    </mat-icon>
                                </mat-form-field>

                            </div>
                            <div class="col-md-3 mb-3" *ngIf="!usuarioCliente">
                                <ngx-spinner [zIndex]="100" name="spRol" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                                </ngx-spinner>
                                <mat-form-field appearance="standard">
                                    <mat-label>Rol</mat-label>
                                    <input matInput type="text" maxlength="50" formControlName="Rol">
                                </mat-form-field>
                            </div>

                        </div>
                    </form>
                </ng-template>
            </mat-expansion-panel>


            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Restablecer clave
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <app-changePassword></app-changePassword>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</div>