<div *appRole="['Movimientos.Crear']">
    <button mat-raised-button [disabled]="contratoDetailDto == undefined ? false : contratoDetailDto.estadoContrato.id == 2 || contratoDetailDto.estadoContrato.id == 3 " color="primary" (click)="openDialogCreateMovimiento()">
    <mat-icon>add</mat-icon>
    Nuevo movimiento
  </button>&nbsp;&nbsp;
    <button mat-raised-button [disabled]=" contratoDetailDto == undefined ? false : contratoDetailDto.estadoContrato.id == 2 || contratoDetailDto.estadoContrato.id == 3 " color="primary" (click)="openDialogNuevoMovimientoCaja()">
    <mat-icon>add</mat-icon>
    Nuevo movimiento de caja
  </button>
</div>

<br>
<br>
<mat-card *appRole="['Movimientos.Listar']">
    <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-12">
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-lg-3">
                                <mat-form-field class="full-width">
                                    <mat-label>Ingrese rango de fechas</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                                        <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3">
                                <mat-form-field>
                                    <mat-label>Rangos rapidos</mat-label>
                                    <mat-select placeholder="Seleccione opcion" formControlName="RangoFecha" (selectionChange)="selectRangoFecha($event)">
                                        <mat-option [value]="fecha.id" *ngFor="let fecha of lstRangoFecha ;let i=index;trackBy:trackByItems">
                                            {{fecha.descripcion | titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Nro. Contrato</mat-label>
                                    <input type="number" formControlName="NumeroContrato" matInput>
                                    <mat-icon matSuffix>gavel</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="checkedFiltroAvanzado">
                            <div class="col-lg-3">
                                <mat-form-field class="example-full-width">
                                    <ngx-spinner [zIndex]=100 name="spBusquedaTiposMovimientos" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                                    <mat-label>Tipos Movimientos</mat-label>
                                    <mat-select placeholder="Seleccione opcion" formControlName="TiposMovimientos">
                                        <mat-option [value]="tipoMovimiento.id" *ngFor="let tipoMovimiento of lstTiposMovimientos ;let i=index;trackBy:trackByItems">
                                            {{tipoMovimiento.descripcion | titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
                                <app-buscar-propiedad (eventEntity)="selectPropiedad($event)"></app-buscar-propiedad>&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
                                <app-buscar-persona-propietaria (eventEntity)="selectPropietario($event)" [showDocumento]="false"></app-buscar-persona-propietaria>&nbsp;&nbsp;
                            </div>
                            <div class="col-lg-3" *ngIf="checkedFiltroAvanzado">
                                <app-buscar-persona-inquilino (eventEntity)="selectInquilino($event)"></app-buscar-persona-inquilino>&nbsp;&nbsp;
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <button mat-stroked-button color="primary" (click)="buscarMovimientos()">
                  <mat-icon>filter_alt</mat-icon>
                  Filtrar</button>&nbsp; &nbsp;
                                <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                                    Filtros Avanzados
                                </mat-slide-toggle>&nbsp; &nbsp;
                                <button mat-stroked-button color="primary" (click)="clearFilter()">
                  <mat-icon>clear_all</mat-icon>
                  Limpiar</button>
                            </div>

                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>

    <div class="row" [hidden]=!data>
        <div class="col-lg-2">
            <mat-form-field class="example-full-width">
                <mat-label>Buscar</mat-label>
                <input type="search" matInput (keyup)="applyFilter($event)">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort [hidden]=!data (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

        <!-- Tipo Movimiento Column -->
        <ng-container matColumnDef="tipoMovimientoDescripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por Tipo de Movimiento">
                <span>Movimiento</span>
            </th>
            <td mat-cell *matCellDef="let element"> <span>{{element.tipoMovimiento.resta == true ?
          element.tipoMovimientoDescripcion + ' (-)' : element.tipoMovimientoDescripcion + ' (+)' | titlecase}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="contratoDescripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por Contrato">
                <span>Contrato</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <a [routerLink]="['/contratos',element.contratoId]" #tooltip="matTooltip" matTooltipPosition="right" matTooltip="Ver"><span>{{element.contratoDescripcion | titlecase}}</span></a>
            </td>
        </ng-container>

        <ng-container matColumnDef="inquilinoDescripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por Inquilino">
                <span>Inquilino</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.inquilinoDescripcion | titlecase}}</span>

            </td>
        </ng-container>

        <ng-container matColumnDef="propietarioDescripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Ordenar por Propietario">

                <span>Propietario</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.propietarioDescripcion | titlecase}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="periodo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Periodo</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.periodoMes">
          {{element.periodoMes  +"/"+ element.periodoAnio+"-"+element.periodo}}
        </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="fechaEmision">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Fecha Emisión</span>
            </th>
            <td mat-cell *matCellDef="let element"> <span>{{element.fechaEmision | date:('dd/MM/yyyy')}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="fechaPago">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Fecha Pago</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!element.anulado">{{element.fechaPago | date:('dd/MM/yyyy')}}</span>
                <span *ngIf="element.anulado"><strong>Anulado</strong></span>
            </td>
        </ng-container>

        <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Importe</span>
            </th>
            <td mat-cell *matCellDef="let element"> <span>{{element.importe | currency}} </span></td>
        </ng-container>

        <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Detalle</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <span>{{element.descripcion}}</span>
                <!-- <mat-icon *ngIf="element.descripcion" color="primary" [matTooltip]="element.descripcion">info</mat-icon> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef> Acción</th>
            <td mat-cell *matCellDef="let element" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
                <mat-menu #menu="matMenu">
                    <button *appRole="['Movimientos.Ver']" mat-menu-item (click)="openDialogUpdateMovimiento(element.id,'Ver')">
            <mat-icon>visibility</mat-icon>
            Ver
          </button>
                    <div *appRole="['Movimientos.Modificar']">
                        <button mat-menu-item *ngIf="element.fechaPago == null && element.anulado == false" (click)="openDialogUpdateMovimiento(element.id,'Editar')">
              <mat-icon>edit</mat-icon>
              Editar
            </button>
                    </div>

                    <div *appRole="['Movimientos.Eliminar']">
                        <button *ngIf="element.fechaPago == null && element.anulado == false" mat-menu-item (click)="openDialogUpdateMovimientoEliminar(element.id,'Eliminar')">
              <mat-icon>delete</mat-icon>
              Eliminar
            </button>
                    </div>

                </mat-menu>
            </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>


    <div class="no-data-table">
        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos
      para mostrar.</span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
</mat-card>
