import { Component } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';


@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.css']
})

export class RadarChartComponent {

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  public radarChartLabels: Label[] = ['Alquiladas', 'Falta garantia', 'Falta contrato',
    'Sin Alquilar'];

  public radarChartData: ChartDataSets[] = [
    { data: [7, 3, 2, 5], label: 'Estados Propiedades' }
  ];
  public radarChartType: ChartType = 'radar';

}
