<h2 mat-dialog-title>Ver valores actuales de ICL</h2>
<mat-dialog-content class="mat-typography">
    <br>
    <div class="row">
        <div class="col-lg-6">
            <a mat-raised-button href="https://ikiwi.net.ar/indice-contratos-locacion/" target="_blank">Web ikiki</a>
        </div>
        <div class="col-lg-6">
            <a mat-raised-button href="http://www.bcra.gov.ar/PublicacionesEstadisticas/Principales_variables_datos.asp?serie=7988&detalle=%CDndice%20para%20Contratos%20de%20Locaci%F3n%20(ICL-Ley%2027.551,%20con%20dos%20decimales,%20base%2030.6.20=1)" target="_blank">Web BCRA</a>
        </div>
    </div>
    <br>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
