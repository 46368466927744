<mat-card *appRole="['DetalleServicios.Ver']">
    <form [formGroup]="form">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mat-form-field>
                <mat-label>Ingrese rango de vencimientos</mat-label>
                <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
                    <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                    <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>

                <mat-error *ngIf="f.FechaDesde.errors?.required">Este campo es <strong>requerido</strong>.
                </mat-error>
                <mat-error *ngIf="f.FechaHasta.errors?.required">Este campo es <strong>requerido</strong>.
                </mat-error>
            </mat-form-field>
        </div>

          <div class="col-lg-2 col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Rangos de fechas rapidos</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="RangoFecha" (selectionChange)="selectRangoFecha($event)">
                        <mat-option [value]="fecha.id" *ngFor="let fecha of lstRangoFecha ;let i=index;trackBy:trackByItems">
                            {{fecha.descripcion | titlecase}}</mat-option>
                    </mat-select>

                </mat-form-field>
            </div>


            <div class="col-lg-2 col-md-6 col-12">
                <mat-form-field>
                    <ngx-spinner [zIndex]=100 name="spBusquedaEstados" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Estados</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="EstadoDetalleServicioPropiedad">
                        <mat-option [value]="estadoDetalleServicioPropiedad.id" *ngFor="let estadoDetalleServicioPropiedad of lstEstadoDetalleServicioPropiedad;let i=index;trackBy:trackByItems">
                            {{estadoDetalleServicioPropiedad.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-2 col-md-6 col-12">
                <mat-form-field>
                    <ngx-spinner [zIndex]=100 name="spBusquedaQuienPaga" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Quien paga</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="QuienPaga">
                        <mat-option [value]="quienPaga.id" *ngFor="let quienPaga of lstQuienPaga;let i=index;trackBy:trackByItems">
                            {{quienPaga.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <mat-form-field class="full-width-x100">
                  <ngx-spinner [zIndex]=100 name="spBusquedaServicios" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                  <mat-label>Servicios</mat-label>
                  <mat-select placeholder="Seleccione opcion" formControlName="Servicio">
                      <mat-option [value]="servicio.id" [matTooltip]="servicio.tipoServicio.descripcion | titlecase" *ngFor="let servicio of lstServicio ;let i=index;trackBy:trackByItems">
                          {{servicio.tipoServicio == undefined ? "" :servicio.tipoServicio.descripcion | titlecase}} - {{servicio.broche == true ? servicio.nroBroche == undefined ? "" : servicio.nroBroche : ""}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
        </div>
        <div class="row">
            <div class="col-lg-2 col-md-6 col-12">
                <button mat-stroked-button color="primary" (click)="buscarServicios()">
                <mat-icon>filter_alt</mat-icon>
              Filtrar</button>
            </div>
        </div>

    </form>
    <br>
    <div class="example-action-buttons" *ngIf="busquedaRealizda">
        <button mat-button color="primary" [matMenuTriggerFor]="menu">Acción<mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item [disabled]="detallesAPagarVistaAgrupada.length == 0 && detallesAPagarVistaSimple.length == 0 && this.selection.selected.length == 0" (click)="changeEstadoDetalleServicioPropiedad()" [matTooltip]="' * Servicios Cobrados pasan al estado Pagados. \n * Servicios en Deuda o No vencidos pasan a al estado Pagado sin cobro.'">
          <mat-icon  >price_check</mat-icon>Pago realizado</button>
        </mat-menu>

        <button mat-button color="primary" [matMenuTriggerFor]="menuVistas"><mat-icon>grid_view</mat-icon></button>
        <mat-menu #menuVistas="matMenu">
            <button mat-menu-item (click)="accordion.closeAll(); expandeExpansionPanel(false);vistaSimple=true;vistaAgrupada=false;vistaTabla=false"><mat-icon>grading</mat-icon>Vista Simple</button>
            <button mat-menu-item (click)="accordion.closeAll(); expandeExpansionPanel(false); vistaSimple=false;vistaAgrupada=true;vistaTabla=false"><mat-icon>view_list</mat-icon>Vista Agrupada</button>
            <button mat-menu-item (click)="accordion.closeAll(); vistaSimple=false;vistaAgrupada=false;vistaTabla=true"><mat-icon>table_view</mat-icon>Vista Tabla</button>

        </mat-menu>
        <button mat-button *ngIf="!vistaTabla" (click)="accordion.openAll(); expandeExpansionPanel(true)">Expandir Todo</button>
        <button mat-button *ngIf="!vistaTabla" (click)="accordion.closeAll(); expandeExpansionPanel(false)">Contraer Todo</button>
        <!-- <button mat-button (click)="selectAll(); expandeExpansionPanel(true)">Seleccionar Todo</button> -->
    </div>
    <br>
    <!-- Vista Agrupada -->
    <mat-accordion multi *ngIf="vistaAgrupada">
        <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-expansion-panel *ngFor="let servicio of detalleServicioPropiedadGroupByServicioPropiedadResponse.servicios">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{servicio.tipoServicio == undefined ? "" :servicio.tipoServicio.descripcion | titlecase}} -
                </mat-panel-title>
                <mat-panel-description class="right-aligned">
                    <span> Broche: {{servicio.nroBroche}} - Total:{{servicio.total| currency }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-accordion multi>
                <mat-expansion-panel *ngFor="let propiedad of servicio.propiedades;let i=index;trackBy:trackByItems" [expanded]="expandeTodo">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span>{{propiedad.ubicacion | uppercase}} {{propiedad.piso | uppercase}}  {{propiedad.departamento | uppercase}}  ({{isFalsy(propiedad.localidad)? "": propiedad.localidad.descripcion | titlecase}} - {{isFalsy(propiedad.provincia) ? "": propiedad.provincia.descripcion | titlecase}})</span>
                        </mat-panel-title>
                        <mat-panel-description class="right-aligned">

                            <span matLine>SubTotal:{{propiedad.subtotal| currency }} - {{matSelectListDetSerPro.selectedOptions.selected.length}}</span>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-selection-list style="padding-left: 12px;" [multiple]="false">
                        <mat-list-option checkboxPosition="before">
                            <div class="row" style="align-items: baseline;">
                                <div class="col-lg-3 col-md-6 col-12">
                                    <span><b>Numero cuenta</b></span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span><b>Periodo</b></span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span><b>Importe</b></span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span><b>Quien paga</b></span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span><b>Estado</b></span>
                                </div>
                                <div class="col-lg-1 col-md-6 col-12 ">
                                    <span><b>Acción</b></span>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                    <mat-selection-list #matSelectListDetSerPro (selectionChange)="selectDetalleServicioPropiedadVistaAgrupada($event)">
                        <mat-list-option *ngFor="let detalleServicioPropiedad of propiedad.detallesServiciosPropiedad;let i=index;trackBy:trackByItems" checkboxPosition="before" [value]="detalleServicioPropiedad">
                            <div class="row" style="align-items: flex-end;">
                                <div class="col-lg-3 col-md-6 col-12">
                                    <span>{{detalleServicioPropiedad.nroCuenta}}</span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span>{{detalleServicioPropiedad.periodoMes}}/{{detalleServicioPropiedad.periodoAnio}}-{{detalleServicioPropiedad.periodo}}</span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span>{{detalleServicioPropiedad.importe | currency}}</span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <span class="badge bg-secondary text-light">{{isFalsy(detalleServicioPropiedad.quienPaga) ? "" : detalleServicioPropiedad.quienPaga.descripcion}}</span>
                                </div>
                                <div class="col-lg-2 col-md-6 col-12">
                                    <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.Pagado">
                                        <span class="badge bg-success text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                    </div>
                                    <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.PagadoSinCobro">
                                        <span class="badge bg-primary text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                    </div>
                                    <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.Cobrado">
                                        <span class="badge bg-info text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                    </div>
                                    <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.NoVencido">
                                        <span class="badge bg-warning text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                    </div>
                                    <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.Deuda">
                                        <span class="badge bg-danger text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                    </div>
                                </div>
                                <div class="col-lg-1">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                  </button>
                                    <mat-menu #menu="matMenu">
                                        <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                                        <mat-icon>visibility</mat-icon>
                                        <span>Ver</span>
                                      </button> -->
                                        <button mat-menu-item *appRole="['DetalleServicios.Modificar']" (click)="openDialogEditarDetalleServicio(detalleServicioPropiedad.id, propiedad.id)">
                                        <mat-icon>edit</mat-icon>
                                        <span>Editar</span>
                                      </button>
                                        <button *appRole="['DetalleServicios.Eliminar']" mat-menu-item (click)="openDialog('Eliminar',detalleServicioPropiedad.id)">
                                        <mat-icon>delete</mat-icon>
                                        <span>Eliminar</span>
                                      </button>
                                    </mat-menu>
                                </div>
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- Vista Simple -->
    <mat-accordion multi *ngIf="vistaSimple">
        <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-expansion-panel *ngFor="let servicio of detalleServicioPropiedadGroupByServicioPropiedadResponse.servicios;let i=index;trackBy:trackByItems">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{servicio.tipoServicio == undefined ? "" : servicio.tipoServicio.descripcion | uppercase}}
                </mat-panel-title>
                <mat-panel-description class="right-aligned">
                    <span> Broche: {{servicio.nroBroche}} - Total:{{servicio.total| currency }}</span>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-selection-list style="padding-left: 12px;" [multiple]="false">
                <mat-list-option checkboxPosition="before">
                    <div class="row" style="align-items: baseline;">
                        <!-- <div class="col-lg-1">
                            <span ><b>Cuenta</b></span>
                        </div> -->
                        <div class="col-lg-3 col-md-6 col-12">
                            <span><b>Propiedad</b></span>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12">
                            <span><b>Periodo</b></span>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12">
                            <span><b>Importe</b></span>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12">
                            <span><b>Quien paga</b></span>
                        </div>
                        <div class="col-lg-2 col-md-6 col-12">
                            <span><b>Estado</b></span>
                        </div>
                        <div class="col-lg-1 col-md-6 col-12">
                            <span><b>Accion</b></span>
                        </div>
                    </div>
                </mat-list-option>
            </mat-selection-list>

            <div *ngFor="let propiedad of servicio.propiedades;let i=index;trackBy:trackByItems">
                <mat-selection-list #matSelectListDetSerPro (selectionChange)="selectDetalleServicioPropiedadVistaSimple($event)">

                    <mat-list-option *ngFor="let detalleServicioPropiedad of propiedad.detallesServiciosPropiedad;let i=index;trackBy:trackByItems" checkboxPosition="before" [value]="detalleServicioPropiedad">
                        <div class="row" style="align-items: baseline;">
                            <!-- <div class="col-lg-1">
                                <span >{{detalleServicioPropiedad.nroCuenta}}</span>
                            </div> -->
                            <div class="col-lg-3 col-md-6 col-12">
                                <a color="primary" [routerLink]="['/propiedades/'+propiedad.id]" matTooltip='Localidad: &#13;{{isFalsy(propiedad.localidad) ? "" : propiedad.localidad.descripcion}} &#13; Provincia: &#13;{{isFalsy(propiedad.provincia)? "": propiedad.provincia.descripcion}}'
                                    [matTooltipClass]="'allow-cr'">
                                    <span>{{isFalsy(propiedad.ubicacion) ? "" : propiedad.ubicacion | titlecase}} {{propiedad.piso | uppercase}}  {{propiedad.departamento | uppercase}}</span>
                                </a>
                            </div>
                            <div class="col-lg-2 col-md-6 col-12">
                                <span>{{detalleServicioPropiedad.periodoMes}}/{{detalleServicioPropiedad.periodoAnio}}-{{detalleServicioPropiedad.periodo}}</span>
                            </div>
                            <div class="col-lg-2 col-md-6 col-12">
                                <span>{{detalleServicioPropiedad.importe | currency}}</span>
                            </div>
                            <div class="col-lg-2 col-md-6 col-12">
                                <span class="badge bg-secondary text-light">{{isFalsy(detalleServicioPropiedad.quienPaga) ? "" : detalleServicioPropiedad.quienPaga.descripcion}}</span>

                            </div>
                            <div class="col-lg-2 col-md-6 col-12">
                                <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.Pagado">
                                    <span class="badge bg-success text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                </div>
                                <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.PagadoSinCobro">
                                    <span class="badge bg-primary text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                </div>
                                <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.Cobrado">
                                    <span class="badge bg-info text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                </div>
                                <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.NoVencido">
                                    <span class="badge bg-warning text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                </div>
                                <div *ngIf="detalleServicioPropiedad.estado.id == estadosDetalleServicioPropiedad.Deuda">
                                    <span class="badge bg-danger text-light">{{isFalsy(detalleServicioPropiedad.estado)? "" : detalleServicioPropiedad.estado.descripcion}}</span>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-6 col-12">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                              </button>
                                <mat-menu #menu="matMenu">
                                    <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                                <mat-icon>visibility</mat-icon>
                                <span>Ver</span>
                              </button> -->
                                    <button mat-menu-item *appRole="['DetalleServicios.Modificar']" (click)="openDialogEditarDetalleServicio(detalleServicioPropiedad.id, propiedad.id)">
                                <mat-icon>edit</mat-icon>
                                <span>Editar</span>
                              </button>
                                    <button mat-menu-item *appRole="['DetalleServicios.Eliminar']" (click)="openDialog('Eliminar',detalleServicioPropiedad.id)">
                                <mat-icon>delete</mat-icon>
                                <span>Eliminar</span>
                              </button>
                                </mat-menu>
                            </div>
                        </div>
                    </mat-list-option>
                </mat-selection-list>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <!-- Vista Tabla -->
    <mat-accordion multi *ngIf="vistaTabla">
        <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <table mat-table [dataSource]="dataSource" [hidden]=!data matSort class="mat-elevation-z8">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <mat-header-cell mat-header-cell *matHeaderCellDef [ngClass]="'w-75'" class="custom-header-cell">
                    <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let row" [ngClass]="'w-75'">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="dspId">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'w-75'"> # </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista" [ngClass]="'w-75'">{{vista.dspId}}</mat-cell>
            </ng-container> -->

            <!-- Servicio -->
            <ng-container matColumnDef="descripcionServicio">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header-cell"> Servicio </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista">
                    <span [matTooltip]="vista.nroBroche">{{vista.descripcionServicio | uppercase}}</span>
                </mat-cell>
            </ng-container>


            <!-- Cuenta -->
            <ng-container matColumnDef="dspCuenta">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header-cell"> Cuenta </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista"> <span>{{vista.dspCuenta}}</span>
                </mat-cell>
            </ng-container>

            <!-- Propiedad -->
            <ng-container matColumnDef="propiedadUbicacion">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header-cell"> Propiedad </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista">
                    <a color="primary" [routerLink]="['/propiedades/'+vista.propiedadId]" matTooltip='Localidad: &#13;{{vista.propiedadLocalidad}} &#13; Provincia: &#13;{{vista.propiedadProvincia}}' [matTooltipClass]="'allow-cr'">
                        <span>{{vista.propiedadUbicacion | titlecase}} {{vista.propiedadPiso | titlecase}} {{vista.propiedadDepto | titlecase}}</span></a>
                </mat-cell>
            </ng-container>

            <!-- Periodo -->
            <ng-container matColumnDef="periodo">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="custom-header-cell"> Periodo </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista"> <span>{{vista.dspPeriodoMes}}/{{vista.dspPeriodoAnio}}-{{vista.dspPeriodo}}</span>
                </mat-cell>
            </ng-container>

            <!-- Monto -->
            <ng-container matColumnDef="dspImporte">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header-cell"> Importe </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista"> <span>{{vista.dspImporte | currency}}</span>
                </mat-cell>
            </ng-container>

            <!-- Quien Paga -->
            <ng-container matColumnDef="dspQuienPaga">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header-cell"> Quien paga </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista">
                    <span class="badge bg-secondary text-light">{{vista.dspQuienPaga}}</span>
                </mat-cell>
            </ng-container>

            <!-- Estado -->
            <ng-container matColumnDef="dspEstadoDescripcion">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header-cell"> Estado </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista">

                    <div *ngIf="vista.dspEstadoId == estadosDetalleServicioPropiedad.Pagado">
                        <span class="badge bg-success text-light">{{vista.dspEstadoDescripcion}}</span>
                    </div>
                    <div *ngIf="vista.dspEstadoId == estadosDetalleServicioPropiedad.PagadoSinCobro">
                        <span class="badge bg-primary text-light">{{vista.dspEstadoDescripcion}}</span>
                    </div>
                    <div *ngIf="vista.dspEstadoId == estadosDetalleServicioPropiedad.Cobrado">
                        <span class="badge bg-info text-light">{{vista.dspEstadoDescripcion}}</span>
                    </div>
                    <div *ngIf="vista.dspEstadoId == estadosDetalleServicioPropiedad.NoVencido">
                        <span class="badge bg-warning text-light">{{vista.dspEstadoDescripcion}}</span>
                    </div>
                    <div *ngIf="vista.dspEstadoId == estadosDetalleServicioPropiedad.Deuda">
                        <span class="badge bg-danger text-light">{{vista.dspEstadoDescripcion}}</span>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Accion -->
            <ng-container matColumnDef="dspAccion">
                <mat-header-cell mat-header-cell *matHeaderCellDef class="custom-header-cell"> Acción </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let vista">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                    <mat-menu #menu="matMenu">
                        <!-- <button mat-menu-item [routerLink]="['/contratos',contrato.id]">
                        <mat-icon>visibility</mat-icon>
                        <span>Ver</span>
                      </button> -->
                        <button mat-menu-item *appRole="['DetalleServicios.Modificar']" (click)="openDialogEditarDetalleServicio(vista.dspId, vista.propiedadId)">
                        <mat-icon>edit</mat-icon>
                        <span>Editar</span>
                      </button>
                        <button mat-menu-item *appRole="['DetalleServicios.Eliminar']" (click)="openDialog( 'Eliminar',vista.dspId) ">
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns "></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumns; " (click)="selection.toggle(row) "></mat-row>
        </table>
        <div class="no-data-table ">
            <span class="with-icon " *ngIf="isFalsy(dataSource.data.length)"><mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.</span>
        </div>
        <mat-paginator showFirstLastButtons [pageSize]="50 " [hidden]=!data showFirstLastButtons [pageSizeOptions]="[5, 10, 20,50,100,200] ">
        </mat-paginator>
    </mat-accordion>
</mat-card>
<!--Scroll to top-->
<app-scroll-to-top></app-scroll-to-top>
