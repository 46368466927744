<div *appRole="['Contratos.Ver']">
  <div class="position-relative">
    <!-- <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <button mat-stroked-button color="primary" (click)="getAll()">
              <mat-icon>filter_alt</mat-icon>
              Filtrar
            </button>&nbsp; &nbsp;

            <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
              Filtros Avanzados </mat-slide-toggle>&nbsp; &nbsp;

            <button mat-stroked-button color="primary" *ngIf="checkedFiltroAvanzado" (click)="clearForm()">
              <mat-icon>clear_all</mat-icon>
              Limpiar
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card> -->
    <button mat-stroked-button color="primary" (click)="getAll()">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>&nbsp; &nbsp;
    <mat-form-field [hidden]=!data class="example-full-width">
      <mat-label>Buscar</mat-label>
      <input matInput type="search" #search (keyup)="applyFilter($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <table mat-table [dataSource]="dataSource" [hidden]=!data matSort matSortStart="asc" table class="mat-elevation-z8">

      <!-- VAlor Ajuste Column -->
      <ng-container matColumnDef="fechaProceso">
        <th mat-header-cell *matHeaderCellDef> Fecha Proceso </th>
        <td mat-cell *matCellDef="let element"> {{element.fechaProceso | date:('dd/MM/yyyy')}} </td>
      </ng-container>
      <!-- Contrato Column -->
      <ng-container matColumnDef="contrato">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/contratos/'+element.contrato.id]">
            {{element.contrato.descripcion | titlecase}}</a>
        </td>
      </ng-container>

      <!-- Periodo Column -->
      <ng-container matColumnDef="periodo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Periodo </th>
        <td mat-cell *matCellDef="let element"> {{element.periodo}} </td>
      </ng-container>

      <!-- VAlor Ajuste Column -->
      <ng-container matColumnDef="valorAjuste">
        <th mat-header-cell *matHeaderCellDef> Valor Ajuste </th>
        <td mat-cell *matCellDef="let element"> {{ element.valorAjuste | number:'1.3-3' }} </td>
      </ng-container>

      <ng-container matColumnDef="tipoAjusteContrato">
        <th mat-header-cell *matHeaderCellDef> Tipo Ajuste </th>
        <td mat-cell *matCellDef="let element">
          {{element.tipoAjusteContrato?.acronimo | uppercase}}
        </td>
      </ng-container>

      <!-- Importe Anterior Column -->
      <ng-container matColumnDef="importeAnterior">
        <th mat-header-cell *matHeaderCellDef> Importe Anterior </th>
        <td mat-cell *matCellDef="let element"> {{element.importeAnterior | currency}} </td>
      </ng-container>

      <!-- Importe Anterior Column -->
      <ng-container matColumnDef="importeNuevo">
        <th mat-header-cell *matHeaderCellDef> Importe Nuevo </th>
        <td mat-cell *matCellDef="let element"> {{element.importeNuevo | currency }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No hay datos que coincidan con el filtro "{{search.value}}"</td>
      </tr>

    </table>
    <div class="no-data-table">
      <span class="with-icon" *ngIf="dataSource.data.length == 0">
        <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
      </span>
    </div>
    <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20"
      [pageSizeOptions]="[5, 10, 20,50,100,200]">
    </mat-paginator>
  </div>
</div>
