import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateInquilinoRequest } from '@models/inquilino/createInquilinoRequest.model';
import { CreateReceiptInquilinoRequest } from '@models/inquilino/createReceiptInquilinoRequest.model';
import { CreateReceiptInquilinoResponse } from '@models/inquilino/createReceiptInquilinoResponse.mode';
import { GetPendingDebtInquilinoResponse } from '@models/inquilino/getPendingDebtInquilinoResponse.model';
import { GetSummaryInquilinoResponse } from '@models/inquilino/getSummaryInquilinoResponse.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { RenterDeliveredNecessaryGuaranteesResponse } from '@models/inquilino/renterDeliveredNecessaryGuaranteesResponse.model';
import { isFalsy } from 'utility-types';
import { MessageSuccessfullDto } from '@models/base/MessageSuccessfullDto.model';
import { FastChargePersonaRequest } from '@models/inquilino/fastChargePersonaRequest.model';

@Injectable({
  providedIn: 'root'
})
export class InquilinosService {

  private cambiarEstado = new Subject<number>()
  private cambiarInquilinoDto = new Subject<InquilinoDto>()
  enviarIdPersonaObservable = this.cambiarEstado.asObservable()
  enviarInquilinoDtoObservable = this.cambiarInquilinoDto.asObservable()

  public inquilinoDto = new BehaviorSubject<InquilinoDto>(new InquilinoDto);

  sendIdPersona(idPersona: number) {
    this.cambiarEstado.next(idPersona)
  }

  sendInquilinoDto(inquilinoDto: InquilinoDto) {
    this.cambiarInquilinoDto.next(inquilinoDto)
  }

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Crear inquilino de forma rapida. Agrega Persona, Inquilino, Usuario y Tipo de Garantias
   * @param fastChargePersonaRequest: FastChargePersonaRequest
   * @returns
   */
  fastChargePersona(fastChargePersonaRequest: FastChargePersonaRequest): Observable<MessageSuccessfullDto> {
    const headers = new HttpHeaders()
    return this.http.post<MessageSuccessfullDto>("/api/Personas/FastChargePersona", fastChargePersonaRequest);
  }

  /**
  * Crar Inquilino
  * @param createInquilinoRequest: CreateInquilinoRequest
  * @returns Observable<CreateResponseBase>
  */
  create(createInquilinoRequest: CreateInquilinoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Inquilinos/Create", createInquilinoRequest);
  }

  /**
 * Obtener todos los inquilinos
 * @returns Observable<InquilinoDto[]>
 */
  getAll(): Observable<InquilinoDto[]> {
    return this.http.get<InquilinoDto[]>("/api/Inquilinos/GetAll")
  }

  /**
  * Obtener todos los inquilinos  que no estan asignados a un contrato.
  * @returns Observable<InquilinoDto[]>
  */
  GetAllInquilinosWithoutCurrentContrat(): Observable<InquilinoDto[]> {
    return this.http.get<InquilinoDto[]>("/api/Inquilinos/GetAllInquilinosWithoutCurrentContrat")
  }

  /**
* Obtener un Inquilino por Id de Persona
* @param idPersona: number
* @returns InquilinoDto[]
*/
  getInquilinoByIdPersona(idPersona: number) {
    return this.http.get<InquilinoDto>("/api/Inquilinos/GetInquilinoByIdPersona?idPersona=" + idPersona)
  }

  /**
* Obtener todos los inquilinos
* @param id: number
* @returns Observable<InquilinoDto[]>
*/
  getById(id: number) {
    return this.http.get<InquilinoDto>("/api/Inquilinos/GetById?id=" + id)
  }

  /**
* Elimina un Inquilino
* @param id: number
* @returns Observable<any>
*/
  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Inquilinos/Delete?id=" + id);
    else
      return of(null)
  }

  /**
* Obtiene liquidacion del Inquilino
* @param idContrato: number
* @param  fechaGeneracion: string | null
* @param incluirPeriodoPosterior:boolean
* @returns Observable<GetSummaryInquilinoResponse>
*/
  getSummaryInquilino(idContrato: number, fechaGeneracion: string | null, incluirPeriodoPosterior: boolean): Observable<GetSummaryInquilinoResponse> {
    return this.http.get<GetSummaryInquilinoResponse>("/api/Inquilinos/GetSummaryInquilino?idContrato=" + idContrato + "&fechaGeneracion=" + fechaGeneracion + "&incluirPeriodoPosterior=" + incluirPeriodoPosterior)
  }

  /**
* Obtiene resumen del Inquilino y lo envia por email
* @param idContrato: number
* @param  fechaGeneracion: string | null
* @param incluirPeriodoPosterior:boolean
* @param email: string | null
* @returns Observable<GetSummaryInquilinoResponse>
*/
  getSummaryInquilinoAndSendEmail(idContrato: number, fechaGeneracion: string | null, incluirPeriodoPosterior: boolean, email: string | null): Observable<any> {
    return this.http.get<any>("/api/Inquilinos/GetSummaryInquilinoAndSendEmail?idContrato=" + idContrato + "&fechaGeneracion=" + fechaGeneracion + "&incluirPeriodoPosterior=" + incluirPeriodoPosterior + "&email=" + email)
  }
  /**
   * Obtener correo de la persona a partir de un Id de Inquilino
   * @param idInquilino: number
   * @returns Observable<any>
   */
  getEmailPersonaByIdInquilino(idInquilino: number): Observable<any> {
    const headers = new HttpHeaders()
    return this.http.get<any>("/api/Inquilinos/GetEmailPersonaByIdInquilino?idInquilino=" + idInquilino, { headers, responseType: 'text' as 'json' })
  }

  /**
   * Proceso el cual genera los resumenes para los inquilinos y los envia por correo.
   * @returns Observable<any>
   */
  getSummaryInquilinoMasivoAndSendEmail(incluirPeriodoPosterior: boolean): Observable<any> {
    return this.http.get<any>("/api/Inquilinos/GetSummaryInquilinoMasivoAndSendEmail?incluirPeriodoPosterior=" + incluirPeriodoPosterior)
  }

  /**
   *
   * @param idContrato: number
   * @returns  Observable<GetPendingDebtInquilinoResponse>
   */
  getPendingDebtInquilino(idContrato: number): Observable<GetPendingDebtInquilinoResponse> {
    return this.http.get<GetPendingDebtInquilinoResponse>("/api/Inquilinos/GetPendingDebtInquilino?idContrato=" + idContrato)
  }

  /**
   * Crear recibo a un inquilino
   * @param createReceiptInquilinoRequest: CreateReceiptInquilinoRequest
   * @returns Observable<CreateReceiptInquilinoResponse>
   */
  createReceiptInquilino(createReceiptInquilinoRequest: CreateReceiptInquilinoRequest): Observable<CreateReceiptInquilinoResponse> {
    return this.http.post<CreateReceiptInquilinoResponse>("/api/Inquilinos/CreateReceiptInquilino", createReceiptInquilinoRequest);
  }

  /**
   * Validacion de garantias
   * @param idInquilino: number
   * @returns Observable<RenterDeliveredNecessaryGuaranteesResponse>
   */
  renterDeliveredNecessaryGuarantees(idInquilino: number): Observable<RenterDeliveredNecessaryGuaranteesResponse> {
    return this.http.get<RenterDeliveredNecessaryGuaranteesResponse>("/api/Inquilinos/RenterDeliveredNecessaryGuarantees?idInquilino=" + idInquilino)
  }





}
