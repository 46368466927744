import { Component, Input, OnInit } from '@angular/core';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-card-contrato',
  templateUrl: './card-contrato.component.html',
  styleUrls: ['./card-contrato.component.css']
})
export class CardContratoComponent implements OnInit {

  @Input() contratoDetailDto!: ContratoDetailDto
  constructor() { }

  ngOnInit(): void {
    if(isFalsy(this.contratoDetailDto))
    this.contratoDetailDto = new ContratoDetailDto
  }

  isFalsy(valor:any){
    return isFalsy(valor)
  }

}
