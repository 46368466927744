import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChartDto } from '@models/dashboard/graficos/chartDto.model';

@Injectable({
  providedIn: 'root'
})
export class GraficosService {
  chartDto!: ChartDto
  private enviarChart = new Subject<ChartDto>()
  enviarChartObservable = this.enviarChart.asObservable()


  enviaChart(chartDto: ChartDto) {
    this.chartDto = chartDto
    this.enviarChart.next(chartDto)
  }
}
