<div class="list-group" *appRole="['Personas.Ver']">
    <button type="button" [routerLinkActive]="['active']" routerLink='/personas/editar' class="list-group-item list-group-item-action">
<div class="with-icon">
  <mat-icon >person_pin</mat-icon><span class="aligned-with-icon"> Persona</span>
</div>
</button>

    <button type="button" *appRole="['Propietarios.Ver']" [routerLinkActive]="['active']" routerLink='/propietario'  class="list-group-item list-group-item-action">

<div class="with-icon">
  <mat-icon >apartment</mat-icon><span class="aligned-with-icon"> Propietario</span>
</div>
</button>

    <button type="button" [routerLinkActive]="['active']" routerLink='/inquilino' *appRole="['Propietarios.Ver']" class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon >vpn_key</mat-icon><span class="aligned-with-icon"> Inquilino</span>
      </div>
</button>

    <button type="button" *appRole="['Garantias.Ver']" [routerLinkActive]="['active']" routerLink='/garantia'  class="list-group-item list-group-item-action">
<div class="with-icon">
  <mat-icon >person</mat-icon><span class="aligned-with-icon"> Garantia</span>
</div>
</button>

 <button type="button" *appRole="['Documentos.Ver']" [routerLinkActive]="['active']" routerLink='/documentospersona' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>folder</mat-icon> <span class="aligned-with-icon">Documentos</span>
      </div>
  </button>

  <button type="button" *appRole="['CuentasContable.Ver']" [routerLinkActive]="['active']" routerLink='/cuentascontablepersonas'  class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon>card_travel</mat-icon> <span class="aligned-with-icon">Cuentas Contables</span>
    </div>
</button>
</div>
