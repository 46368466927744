import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BuscarServiciosComponent } from "@views/busquedas/buscar-servicios/buscar-servicios.component";
import { BtnDialogActualizaMontosBrochesComponent } from "@views/detalle-servicios-propiedad/btn-dialog-actualiza-montos-broches/btn-dialog-actualiza-montos-broches.component";
import { DetalleServicioPropiedadComponent } from "@views/detalle-servicios-propiedad/detalle-servicio-propiedad/detalle-servicio-propiedad.component";
import { DialogActualizaMontosBrochesComponent } from "@views/detalle-servicios-propiedad/dialog-actualiza-montos-broches/dialog-actualiza-montos-broches.component";
import { DialogCreateDetalleServicioComponent } from "@views/detalle-servicios-propiedad/dialog-create-detalle-servicio/dialog-create-detalle-servicio.component";
import { DialogEditarDetalleServicioComponent } from "@views/detalle-servicios-propiedad/dialog-editar-detalle-servicio/dialog-editar-detalle-servicio.component";
import { ListadoDetalleServicioPorPropiedadComponent } from "@views/detalle-servicios-propiedad/listado-detalle-servicio-por-propiedad/listado-detalle-servicio-por-propiedad.component";
import { ListadoDetalleServicioPropiedadComponent } from "@views/detalle-servicios-propiedad/listado-detalle-servicio-propiedad/listado-detalle-servicio-propiedad.component";
import { BtnNuevoServicioPropiedadComponent } from "@views/propiedades/servicios-propiedad/btn-nuevo-servicio-propiedad/btn-nuevo-servicio-propiedad.component";
import { FormCreateServicioPropiedadComponent } from "@views/propiedades/servicios-propiedad/form-create-servicio-propiedad/form-create-servicio-propiedad.component";
import { FormUpdateServicioPropiedadComponent } from "@views/propiedades/servicios-propiedad/form-update-servicio-propiedad/form-update-servicio-propiedad.component";
import { InstructivosServiciosComponent } from "@views/propiedades/servicios-propiedad/instructivos-servicios/instructivos-servicios.component";
import { ListadoServiciosPropiedadComponent } from "@views/propiedades/servicios-propiedad/listado-servicios-propiedad/listado-servicios-propiedad.component";
import { ServicioPropiedadComponent } from "@views/propiedades/servicios-propiedad/servicio-propiedad/servicio-propiedad.component";
import { ServiciosPropiedadComponent } from "@views/propiedades/servicios-propiedad/servicios-propiedad/servicios-propiedad.component";
import { ScrollToTopComponent } from "@views/scroll-to-top/scroll-to-top.component";
import { BtnCreateServicioComponent } from "@views/servicios/btn-create-servicio/btn-create-servicio.component";
import { BtnImportarDetallesServicioPropiedadComponent } from "@views/servicios/importar-detalles-servicio-propiedad/btn-importar-detalles-servicio-propiedad/btn-importar-detalles-servicio-propiedad.component";
import { ImportarDetallesServicioPropiedadComponent } from "@views/servicios/importar-detalles-servicio-propiedad/importar-detalles-servicio-propiedad/importar-detalles-servicio-propiedad.component";
import { ListadoServiciosComponent } from "@views/servicios/listado-servicios/listado-servicios.component";
import { FormServicioCreateComponent } from "@views/servicios/servicio/form-servicio-create/form-servicio-create.component";
import { FormServicioUpdateComponent } from "@views/servicios/servicio/form-servicio-update/form-servicio-update.component";
import { ServicioComponent } from "@views/servicios/servicio/servicio.component";
import { ServiciosComponent } from "@views/servicios/servicios/servicios.component";
import { BtnNuevoTipoServicioComponent } from "@views/tipos-servicios/btn-nuevo-tipo-servicio/btn-nuevo-tipo-servicio.component";
import { DialogTipoServiciosComponent } from "@views/tipos-servicios/dialog-tipo-servicios/dialog-tipo-servicios.component";
import { TiposServiciosComponent } from "@views/tipos-servicios/tipos-servicios/tipos-servicios.component";
import { ArchivosModule } from "./archivos.module";
import { PropiedadesModule } from "./propiedades.module";
import { SharedModule } from "./shared.module";



@NgModule({
    imports:[
        CommonModule,
        SharedModule,
        PropiedadesModule,
        ArchivosModule
    ],
    declarations:[
        ServiciosComponent,
        ListadoServiciosComponent,
        FormServicioCreateComponent,
        BtnCreateServicioComponent,
        ServiciosPropiedadComponent,
        FormCreateServicioPropiedadComponent,
        FormUpdateServicioPropiedadComponent,
        TiposServiciosComponent,
        DialogTipoServiciosComponent,
        BtnNuevoTipoServicioComponent,
        ServicioComponent,
        FormServicioUpdateComponent,
        FormServicioCreateComponent,
        ServiciosComponent,
        BtnCreateServicioComponent,
        ListadoServiciosPropiedadComponent,
        BtnNuevoServicioPropiedadComponent,
        BuscarServiciosComponent,
        ServicioPropiedadComponent,
        DialogCreateDetalleServicioComponent,
        DetalleServicioPropiedadComponent,
        ListadoDetalleServicioPorPropiedadComponent,
        DialogEditarDetalleServicioComponent,
        DialogActualizaMontosBrochesComponent,
        BtnDialogActualizaMontosBrochesComponent,
        ImportarDetallesServicioPropiedadComponent,
        BtnImportarDetallesServicioPropiedadComponent,
        ListadoDetalleServicioPropiedadComponent,
        ScrollToTopComponent,
        InstructivosServiciosComponent
    ],
    exports:[
        ServiciosComponent,
        ListadoServiciosComponent,
        FormServicioCreateComponent,
        BtnCreateServicioComponent,
        ServiciosPropiedadComponent,
        FormCreateServicioPropiedadComponent,
        FormUpdateServicioPropiedadComponent,
        TiposServiciosComponent,
        DialogTipoServiciosComponent,
        BtnNuevoTipoServicioComponent,
        ServicioComponent,
        FormServicioUpdateComponent,
        FormServicioCreateComponent,
        ServiciosComponent,
        BtnCreateServicioComponent,
        ListadoServiciosPropiedadComponent,
        BtnNuevoServicioPropiedadComponent,
        BuscarServiciosComponent,
        ServicioPropiedadComponent,
        DialogCreateDetalleServicioComponent,
        DetalleServicioPropiedadComponent,
        ListadoDetalleServicioPorPropiedadComponent,
        DialogEditarDetalleServicioComponent,
        DialogActualizaMontosBrochesComponent,
        BtnDialogActualizaMontosBrochesComponent,
        InstructivosServiciosComponent
    ]
})

export class ServiciosModule{}
