import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, TiposMovimiento } from '@models/base/identificadores.model';
import { TipoServiciosService } from '@services/servicios/tipos-servicios/tipo-servicios.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposMoviemientoService } from '@services/tipos-movimiento/tipos-movimiento.service';
import { isFalsy } from 'utility-types';
import { TipoMovimientoDto } from '@models/tipos-movimiento/tipoMovimientoDto.model';

@Component({
  selector: 'app-dialog-nuevo-movimiento',
  templateUrl: './dialog-nuevo-movimiento.component.html',
  styleUrls: ['./dialog-nuevo-movimiento.component.css']
})
export class DialogNuevoMovimientoComponent implements OnInit {

  action: string;
  local_data: any;
  form: FormGroup;
  formBuilder: FormBuilder = new FormBuilder;
  lstTiposMovimientos: TipoMovimientoDto[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredConceptos!: Observable<string[]>;
  conceptos: string[] = [];
  allConceptos: string[] = [];

  get f() { return this.form.controls; }

  @ViewChild(MatAutocompleteTrigger) autoTrigger!: MatAutocompleteTrigger;

  @ViewChild('conceptoInput') conceptoInput!: ElementRef<HTMLInputElement>;

  constructor(
    public tiposMoviemientoService: TiposMoviemientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogNuevoMovimientoComponent>,
    public tipoServiciosService: TipoServiciosService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.local_data = { ...data };
    this.action = this.local_data.event;

    this.form = this.formBuilder.group({
      Importe: ["", Validators.compose([Validators.required])],
      TiposMovimiento: ["", Validators.compose([Validators.required,])],
      Descripcion: [""],
    })

    this.filteredConceptos = this.form.controls["Descripcion"].valueChanges.pipe(
      startWith(null),
      map((concepto: string | null) => (concepto ? this._filter(concepto) : this.allConceptos.slice())),
    );
  }

  onAutocompleteClosed() {
    if (this.autoTrigger) {
      this.autoTrigger.closePanel();
    }
  }


  doAction() {
    let valorImporte = parseFloat(this.form.controls["Importe"].value)
    if (valorImporte > 0) {
      let tipoMovimiento = this.form.controls["TiposMovimiento"].value

      let descripcion = this.conceptos.toString()
      this.local_data.tipoMovimiento = tipoMovimiento
      if (tipoMovimiento.resta)
        valorImporte = valorImporte * -1
      this.local_data.importe = valorImporte
      this.local_data.descripcion = descripcion
      this.local_data.periodo = 0
      this.local_data.periodoMes = new Date().getMonth() + 1
      this.local_data.periodoAnio = new Date().getFullYear()
      this.dialogRef.close({ event: this.action, data: this.local_data });
    }
    else
      this.snackBar.showInfo("Verifique valor importe.", "Info");
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  ngOnInit(): void {
    this.getAllTiposMovimientos()
    this.getAllServicios();
    this.form.controls["Importe"].setValue(isFalsy(this.local_data.importe) ? "" : this.local_data.importe)
  }

  getAllTiposMovimientos() {
    this.spinner.show("spBusquedaTipoMovimientos")
    this.tiposMoviemientoService.getAll().subscribe(
      data => {
        if (!isFalsy(this.local_data.tipoMovimiento)) {
          this.local_data.tipoMovimiento.forEach((element1: number) => {
            data.forEach(element2 => {
              if (element1 == element2.id)
                this.lstTiposMovimientos.push(element2)
            });
          });
        }
        else
          this.lstTiposMovimientos = data.filter(this.esMovimientoTipoSaldo)
        this.spinner.hide("spBusquedaTipoMovimientos")
      },
      error => {
        this.spinner.hide("spBusquedaTipoMovimientos")
        this.snackBar.showError(error, "Error");
      }
    )
  }



  getAllServicios() {
    this.spinner.show("spBusquedaConceptos")
    this.tipoServiciosService.getAll().subscribe(
      data => {
        data.sort((a, b) => a.descripcion.localeCompare(b.descripcion)).forEach(element => {
          this.allConceptos.push(element.descripcion)
        });
        this.spinner.hide("spBusquedaConceptos")
      },
      error => {
        this.spinner.hide("spBusquedaConceptos")
        this.snackBar.showError(error, "Error");
      }
    )
  }


  esMovimientoTipoSaldo(elemento: TipoMovimientoDto) {
    return elemento.id != TiposMovimiento.SaldoAFavor
      && elemento.id != TiposMovimiento.SaldoAdeuda
      && elemento.id != TiposMovimiento.SaldoDeudaPeriodo
  }
 add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.conceptos.push(value);
    }

    event.chipInput!.clear();
    this.form.controls["Descripcion"].setValue(null)
  }

  remove(concepto: string): void {
    const index = this.conceptos.indexOf(concepto);

    if (index >= 0) {
      this.conceptos.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.conceptos.push(event.option.viewValue);
    this.conceptoInput.nativeElement.value = '';
    this.form.controls["Descripcion"].setValue(null)
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allConceptos.filter(concepto => concepto.toLowerCase().includes(filterValue));
  }

  trackByItems(index: number, item: any): any { return item; }


}
