import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { Asuntos, TiposEntidad, TiposFolios, TiposGarantia } from '@models/base/identificadores.model';
import { CreateDocumentoRequest } from '@models/documentos/createDocumentoRequest.model';
import { CreateGarantiaWithPersona } from '@models/garantia/createGarantiaWithPersona.model';
import { GarantiaDto } from '@models/garantia/garantiaDto.model';
import { TipoDocumentoDto } from '@models/tipo-documento/tipoDocumentoDto.model';
import { UsuarioDto } from '@models/usuarios/usuarioDto.model';
import { DocumentosService } from '@services/documentos/documentos.service';
import { GarantiasService } from '@services/garantias/garantias.service';
import { InquilinosService } from '@services/inquilinos/inquilinos.service';
import { LoginService } from '@services/login/login.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposDocumentoService } from '@services/tipos-documento/tipos-documento.service';
import { TiposFolioService } from '@services/tipos-folio/tipos-folio.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/personas/personaDetailDto.model';

@Component({
  selector: 'app-form-garantia-create-public',
  templateUrl: './form-garantia-create-public.component.html',
  styleUrls: ['./form-garantia-create-public.component.css']
})
export class FormGarantiaCreatePublicComponent implements OnInit {


  lstTiposGarantiaModel: BaseDto[] = []
  lstTiposFolios: BaseDto[] = []
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  lstTipoDocumentoDto: TipoDocumentoDto[] = [];
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  valor: any
  errorMessage: string = "";
  accordionList: any;
  formBuilder: FormBuilder = new FormBuilder;
  matDialogClose!: boolean;
  mostrarPersona!: boolean;
  mostrarPropiedad!: boolean;
  mostrarFianza!: boolean;
  formTipoGarantia: FormGroup;
  formPersona: FormGroup;
  formPersonaGarantia: FormGroup;
  formPropiedad: FormGroup;
  idTipoGarantia: any;
  tipoGarantiaSelect: number = 0;
  createGarantiaWithPersona = new CreateGarantiaWithPersona();
  formFianza: FormGroup;
  idTipoFolio!: number;
  archivoDto!: ArchivoDto;
  archivoDtoDNIDorso!: ArchivoDto;
  archivoDtoDNIFrente!: ArchivoDto;
  garantia!: GarantiaDto;
  archivosFrenteCorrectos: boolean = false
  archivosDorsoCorrectos: boolean = false
  indivisa: number = 100
  createResponseBase: CreateResponseBase = new CreateResponseBase;
  personaDetailDto!: PersonaDetailDto;
  localidadPersona: any;
  user!: UsuarioDto;

  get f() { return this.formPersona.controls; }
  get fPro() { return this.formPropiedad.controls; }
  get fPer() { return this.formPersonaGarantia.controls; }
  get fG() { return this.formTipoGarantia.controls; }
  get fTL() { return this.formFianza.controls; }


  constructor(
    private personasService: PersonasService,
    private inquilinosService: InquilinosService,
    private tiposFoliosServices: TiposFolioService,
    private tipoDocumentoService: TiposDocumentoService,
    private garantiasService: GarantiasService,
    private router: Router,
    private loginService: LoginService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private documentosService: DocumentosService,
  ) {
    this.formPersona = this.formBuilder.group({
      //Persona
      NombreCompleto: ["", Validators.compose([Validators.required, Validators.maxLength(50)])],
      NroDocumento: ['',
        Validators.compose([Validators.required, Validators.maxLength(50)])],
      Correo: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      TiposDocumento: ['', Validators.compose([Validators.required])],
      Ubicacion: ['', Validators.compose([Validators.required],)],
      TelefonoPrincipal: ['', Validators.compose([Validators.required],)],
      TelefonoLaboral: ['', Validators.compose([Validators.maxLength(100)],)],
    });

    this.formTipoGarantia = this.formBuilder.group({
      TipoGarantia: ['', Validators.compose([Validators.required])],
    });
    this.formPersonaGarantia = this.formBuilder.group({
      Sueldo: [""],
      CuitCuil: ['', Validators.compose([,Validators.pattern(/^([0-9]{11}|[0-9]{2}-[0-9]{8}-[0-9]{1})$/)])],
      Empleador: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
    });
    this.formFianza = this.formBuilder.group({
      Cuit: ['', Validators.compose([Validators.pattern(/^[0-9]{2}-[0-9]{8}-[0-9]$/)])],
      RazonSocial: ['', Validators.compose([Validators.maxLength(200), Validators.required])],
    });
    this.formPropiedad = this.formBuilder.group({
      TipoFolio: ['', Validators.compose([Validators.required],)],
      Tomo: ['', Validators.compose([Validators.maxLength(20)],)],
      NroFolio: ['', Validators.compose([Validators.maxLength(20), Validators.required],)],
      Numero: ['', Validators.compose([Validators.maxLength(20)],)],
      Indivisa: [100, Validators.compose([Validators.maxLength(20)],)],
      UbicacionPropiedad: ['', Validators.compose([Validators.maxLength(100)],)],
      LocalidadPropiedad: ['', Validators.compose([Validators.maxLength(100)],)],
      PropiedadHorizontal: [''],
    });
  }


  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser()

    this.getAllTipoDocumento();
    this.getAllTiposGarantia();
    this.formPersona.controls["NroDocumento"].disable();
  }

  getAllTiposGarantia() {
    this.spinner.show()
    this.garantiasService.getAllTiposGarantia()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstTiposGarantiaModel = data
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        });
  }

  getAllTiposFolio() {
    this.spinner.show("spDtoGta")
    this.tiposFoliosServices.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spDtoGta")
          this.lstTiposFolios = data.sort((a, b) => a.descripcion.localeCompare(b.descripcion));
        },
        error => {
          this.spinner.hide("spDtoGta")
          this.snackBar.showError( error, "Error");
        });
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidadPersona = data
  }

  selectArchivoDNIDorso(event: ArchivoDto) {
    this.archivoDtoDNIFrente = event
    if (this.archivoDtoDNIDorso.content == "")
      this.archivosDorsoCorrectos = false
    else
      this.archivosDorsoCorrectos = true
  }

  selectArchivoDNIFrente(event: ArchivoDto) {
    this.archivoDtoDNIDorso = event
    if (this.archivoDtoDNIDorso.content == "")
      this.archivosFrenteCorrectos = false
    else
      this.archivosFrenteCorrectos = true
  }

  selectTipoGarantia(event: any) {
    this.idTipoGarantia = event;
    //Persona
    if (event == TiposGarantia.ReciboSueldo) {
      this.mostrarPersona = true
      this.mostrarPropiedad = false
      this.mostrarFianza = false
      this.formPropiedad.reset();
      this.formFianza.reset();
    }
    //Propiedad
    if (event == TiposGarantia.Propiedad) {
      this.getAllTiposFolio()
      this.mostrarPersona = false
      this.mostrarPropiedad = true
      this.mostrarFianza = false
      this.formPersonaGarantia.reset();
      this.formFianza.reset();
    }
    //Fianza
    if (event == TiposGarantia.Fianza) {
      this.mostrarPersona = false
      this.mostrarPropiedad = false
      this.mostrarFianza = true
      this.formPersonaGarantia.reset();
      this.formPropiedad.reset();
      this.idTipoFolio = TiposFolios.SinFolio
    }
  }


  selectTipoFolio(event: any) {
    this.idTipoFolio = event;
  }

  getAllTipoDocumento() {
    this.spinner.show()
    this.tipoDocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstTipoDocumentoDto = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        });
  }

  onSubmit() {
    if (this.formPersona.invalid || this.formTipoGarantia.invalid) {
      return;
    }
    this.setCreateGarantiaWithPersona();
    this.crearGarantiaConPersona(this.createGarantiaWithPersona)
  }

  crearGarantiaConPersona(createGarantiaWithPersona: CreateGarantiaWithPersona) {
    this.spinner.show()
    this.garantiasService.createGarantiaWithPersona(createGarantiaWithPersona)
      .subscribe(
        async data => {
          this.spinner.hide()
          this.createResponseBase = data
          await this.getPersonaByIdGarantia(data)
          this.snackBar.showSuccess("Se creo la garantia de manera exitosa.", "");
          this.router.navigate(["/garantias/misGarantias"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        },
      )
  }
  cargarDocumentosDNIFrente(id_PersonaInquilino: number) {
    this.spinner.show("spDocumento")
    const createDocumentoRequest: CreateDocumentoRequest = new CreateDocumentoRequest
    createDocumentoRequest.nombre = "Documento: Personas - " + id_PersonaInquilino + " - Foto DNI Frente"
    createDocumentoRequest.fileContent = this.archivoDtoDNIFrente.content
    createDocumentoRequest.fileName = this.archivoDtoDNIFrente.descripcion
    createDocumentoRequest.fileType = this.archivoDtoDNIFrente.contentType
    createDocumentoRequest.idAsunto = Asuntos.DNIFrente
    createDocumentoRequest.idEntidad = id_PersonaInquilino
    createDocumentoRequest.idTipoEntidad = TiposEntidad.Personas
    createDocumentoRequest.etiquetas = "dni, garantia, frente"
    this.documentosService.create(createDocumentoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spDocumento")
        },
        error => {
          this.spinner.hide("spDocumento")
          this.snackBar.showError( error, "Error");
        },
      )
  }

  cargarDocumentosDNIDorso(id_PersonaInquilino: number) {
    this.spinner.show("spDocumento")
    const createDocumentoRequest: CreateDocumentoRequest = new CreateDocumentoRequest
    createDocumentoRequest.nombre = "Documento: Personas - " + id_PersonaInquilino + " - Foto DNI Dorso"
    createDocumentoRequest.fileContent = this.archivoDtoDNIDorso.content
    createDocumentoRequest.fileName = this.archivoDtoDNIDorso.descripcion
    createDocumentoRequest.fileType = this.archivoDtoDNIDorso.contentType
    createDocumentoRequest.etiquetas = "dni, garantia, dorso"
    createDocumentoRequest.idAsunto = Asuntos.DNIDorso
    createDocumentoRequest.idEntidad = id_PersonaInquilino
    createDocumentoRequest.idTipoEntidad = TiposEntidad.Personas
    this.documentosService.create(createDocumentoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spDocumento")
        },
        error => {
          this.spinner.hide("spDocumento")
          this.snackBar.showError( error, "Error");
        },
      )
  }

  selectTipoDocumento(event: any) {
    if (event != 0) {
      this.formPersona.controls["NroDocumento"].enable();
      this.formPersona.controls["NroDocumento"].setValue("");
    }
    else {
      this.formPersona.controls["NroDocumento"].disable();
    }
  }

  setCreateGarantiaWithPersona() {
    this.createGarantiaWithPersona = new CreateGarantiaWithPersona();
    //Persona
    this.createGarantiaWithPersona.nombreCompleto = this.formPersona.controls['NombreCompleto'].value;
    this.createGarantiaWithPersona.nroDocumento = this.formPersona.controls['NroDocumento'].value;
    this.createGarantiaWithPersona.id_TipoDocumento = this.formPersona.controls['TiposDocumento'].value;
    this.createGarantiaWithPersona.correo = this.formPersona.controls['Correo'].value;
    this.createGarantiaWithPersona.telefonoPrincipal = this.formPersona.controls['TelefonoPrincipal'].value;
    this.createGarantiaWithPersona.telefonoLaboral = this.formPersona.controls['TelefonoLaboral'].value;
    this.createGarantiaWithPersona.id_Localidad = this.localidadPersona.id
    //Tipo Garantia
    this.createGarantiaWithPersona.id_TipoGrantia = this.formTipoGarantia.controls['TipoGarantia'].value
    if (this.createGarantiaWithPersona.id_TipoGrantia == TiposGarantia.ReciboSueldo) {
      //Tipo Persona
      this.createGarantiaWithPersona.cuitCuil = this.formPersonaGarantia.controls['CuitCuil'].value
      this.createGarantiaWithPersona.sueldo = this.formPersonaGarantia.controls['Sueldo'].value
      this.createGarantiaWithPersona.razonSocial = this.formPersonaGarantia.controls['Empleador'].value
      this.createGarantiaWithPersona.idTipoFolio = TiposFolios.SinFolio
    }
    if (this.createGarantiaWithPersona.id_TipoGrantia == TiposGarantia.Propiedad) {
      //Tipo Propiedad
      this.createGarantiaWithPersona.idTipoFolio = this.formPropiedad.controls['TipoFolio'].value
      this.createGarantiaWithPersona.nroFolio = this.formPropiedad.controls['NroFolio'].value

      if (this.createGarantiaWithPersona.idTipoFolio == TiposFolios.FolioPersonalCronologico) {
        if (this.createGarantiaWithPersona.tomo != "")
          this.createGarantiaWithPersona.tomo = this.formPropiedad.controls['Tomo'].value
        else {
          this.createGarantiaWithPersona.tomo = "0"
        }

        if (this.createGarantiaWithPersona.numero != "")
          this.createGarantiaWithPersona.numero = this.formPropiedad.controls['Numero'].value
        else {
          this.createGarantiaWithPersona.numero = "0"
        }

      }
      else {
        this.createGarantiaWithPersona.tomo = ""
        this.createGarantiaWithPersona.numero = ""
      }

      this.createGarantiaWithPersona.indivisa = this.formPropiedad.controls['Indivisa'].value
      this.createGarantiaWithPersona.ubicacionPropiedad = this.formPropiedad.controls['UbicacionPropiedad'].value
      this.createGarantiaWithPersona.localidad = this.formPropiedad.controls['LocalidadPropiedad'].value
      this.createGarantiaWithPersona.horizontal = this.formPropiedad.controls['PropiedadHorizontal'].value
    }
    if (this.createGarantiaWithPersona.id_TipoGrantia == TiposGarantia.Fianza) {
      //Tipo Fianza
      this.createGarantiaWithPersona.razonSocial = this.formFianza.controls['RazonSocial'].value
      this.createGarantiaWithPersona.cuitCuil = this.formFianza.controls['Cuit'].value
      this.createGarantiaWithPersona.idTipoFolio = TiposFolios.SinFolio
    }
    //Persona
    this.createGarantiaWithPersona.id_PersonaInquilino = this.user.persona.id

  }

  onReset() {
    this.formPersona.reset();
    this.formPersonaGarantia.reset();
    this.formTipoGarantia.reset();
    this.formPropiedad.reset();
    this.formFianza.reset();
    this.formTipoGarantia.controls["TipoGarantia"].setValue(0)
    this.mostrarPersona = false
    this.mostrarPropiedad = false
  }

  //Validaciones
  validaLargoNumerico(control: FormControl) {
    if (control.value != null && control.value != "") {
      if (<number>control.value > 999999999999) {
        return { validaLargoNumerico: true }
      }
      else
        return { validaLargoNumerico: false }
    }
    else
      return { validaLargoNumerico: false }
  }

  validaPersonaByNroDocYTipoDoc(controlNroDoc: FormControl) {
    let idTipoDocumento = this.formPersona.controls['TiposDocumento'].value;
    if (controlNroDoc.value.toString().length > 7  && !isFalsy(idTipoDocumento) ) {
      let promesa = new Promise((resolve, reject) => {
        this.personasService.validaPersonaByNroDocYTipoDoc(this.personaDetailDto.id, controlNroDoc.value, idTipoDocumento)
          .subscribe(data => {
            if (data == true) {
              this.personasService.getPersonaByNroDocYTipoDoc(idTipoDocumento, controlNroDoc.value).subscribe(data => {
                this.personaDetailDto = data
                this.formPersona.controls['NombreCompleto'].setValue(this.personaDetailDto.nombreCompleto);
                this.formPersona.controls['Correo'].setValue(this.personaDetailDto.correo);
              })
              //resolve({ existePersona: true })
            }
            else {
              resolve(true)
            }
          })
      });
      return promesa;
    }
    return null
  }
  //Fin Validaciones

  getPersonaByNroDocYTipoDoc(id: any, nroDoc: any) {

  }

  getPersonaByIdGarantia(CreateResponseBase: CreateResponseBase) {
    this.spinner.show()
    this.garantiasService.getGarantiaById(CreateResponseBase.id)
      .subscribe(
        async data => {
          this.spinner.hide()
          this.garantia = data
          if (this.garantia.persona.id != 0) {
            await this.cargarDocumentosDNIFrente(this.garantia.persona.id)
            await this.cargarDocumentosDNIDorso(this.garantia.persona.id)
          }
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        },
      )
  }

  trackByItems(index: number, item: any): any { return item; }

}
