import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxSpinnerService } from 'ngx-spinner';
import { merge, Observable } from 'rxjs';
import { ArchivoTemporalDto } from '@models/archivos-temporales/archivoTemporalDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { Genericos } from '@models/base/genericos.model';
import { AccionesDialog, EstadosComprobantes, FormasPagos, RangoFechasValores, SnackbarClass, TiposComprobantes, TiposContrato, TiposMovimiento } from '@models/base/identificadores.model';
import { ComprobanteDetailDto } from '@models/comprobantes/comprobanteDetailDto.model';
import { DetalleComprobanteDetailDto } from '@models/comprobantes/detalleComprobanteDetailDto.model';
import { EmailDto } from '@models/email/emailDto.model';
import { ReceiverDto } from '@models/email/receiverDto.model';
import { SendEmailRequest } from '@models/email/sendEmailRequest.model';
import { InquilinoDto } from '@models/inquilino/inquilinoDto.model';
import { PagoBasicDto } from '@models/pago/pagoBasicDto.model';
import { PersonaPropietarioDto } from '@models/propietarios/personaPropietarioDto.model';
import { ArchivosTemporalesService } from '@services/archivos-temporales/archivos-temporales.service';
import { ComprobantesService } from '@services/comprobantes/comprobantes.service';
import { EmailService } from '@services/email/email.service';
import { EstadosComprobanteService } from '@services/estados-comprobante/estados-comprobante.service';
import { PersonasService } from '@services/personas/personas.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TiposComprobantesService } from '@services/tipos-comprobantes/tipos-comprobantes.service';
import { isFalsy } from 'utility-types';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { DialogComprobanteComponent } from '../dialog-comprobante/dialog-comprobante.component';
import { DialogNuevoEmailComponent } from '@views/genericos/dialog-nuevo-email/dialog-nuevo-email.component';
import { ContratoDetailDto } from '@models/contrato/contratoDetailDto.model';
import { ContratosService } from '@services/contratos/contratos.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ComprobantesController } from '@controllers/comprobantes.controller';


export class VistaSimple {
  public estado!: string
  public tipoComprobante!: string
  public fechaEmision!: string
  public contrato!: number
  public numeroComprobante!: string
  public descripcion!: string
  public propiedad!: string
  public propietario!: string
  public inquilino!: string
  public fechaAcreditacion!: string
  public totalDebe: number = 0
  public totalHaber: number = 0
  public totalPago: number = 0
  public total: number = 0
  public comprobanteDetailDto!: ComprobanteDetailDto
}
export class VistaCaja {
  public estado!: string
  public idEstado!: number
  public tipoComprobante!: string
  public fechaEmision!: string
  public numeroComprobante!: string
  public descripcion!: string
  public efectivo: number = 0
  public cheques: number = 0
  public depositos: number = 0
  public transferencias: number = 0
  public fechaAcreditacion!: string
  public total: number = 0
  public comprobanteDetailDto!: ComprobanteDetailDto
}

export class VistaCajaCompleta {
  public vdIdEstado!: number
  public vdestado!: string
  public tipoComprobante!: string
  public fechaEmision!: string
  public fechaAcreditacion!: string
  public numeroComprobante!: string
  public descripcion!: string
  public importePago!: number
  public importeComprobante!: number
  public formaPago!: string
  public comprobanteDetailDto!: ComprobanteDetailDto
  public cabecera: boolean = false
}


@Component({
  selector: 'app-listado-comprobantes-genericos',
  templateUrl: './listado-comprobantes-genericos.component.html',
  styleUrls: ['./listado-comprobantes-genericos.component.css'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListadoComprobantesGenericosComponent implements OnInit, AfterViewInit {

  //#region Variables Globales
  personaPropietarioDto!: PersonaPropietarioDto | null;
  propiedadBaseDto!: BaseDto | null;
  inquilinoDto!: InquilinoDto | null;
  formBuilder: FormBuilder = new FormBuilder;
  form!: FormGroup;
  data: boolean = false;
  lstRangoFecha = Genericos.lstRangoFecha
  lstTiposComprobantes!: BaseDto[];
  lstEstadosComprobante!: BaseDto[];

  rangoFecha: number = RangoFechasValores.Trimestral
  dataSource = new MatTableDataSource<ComprobanteDetailDto>();
  dataSourceVistaSimple = new MatTableDataSource<VistaSimple>();
  dataSourceCaja = new MatTableDataSource<VistaCaja>();
  dataSourceCajaCompleta = new MatTableDataSource<VistaCajaCompleta>();
  columns: string[] = [];
  columnDefinitions!: { def: string; label: string; hide: any; }[];
  contratoDetailDto!: ContratoDetailDto | null;
  @Input() numeroContrato!: number
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSourceVistaSimple.paginator = this.paginator;
    this.dataSourceVistaSimple.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }


  formColumnas: FormGroup = this.formBuilder.group({
    tipoComprobante: [false],
    estado: [false],
    numeroComprobante: [false],
    fechaEmision: [false],
    contrato: [false],
    // propiedad: [false],
    propietario: [false],
    inquilino: [false],
    totalDebe: [false],
    totalHaber: [false],
    totalPago: [false],
    total: [false],
    accion: [false]
  })
  destinatarios!: string;
  receiverDto!: ReceiverDto;

  vistaSimple: boolean = false
  vistaCaja: boolean = true
  vistaDetalle: boolean = false
  vistaCajaCompleta: boolean = false
  columnDefinitionsVistaCaja: string[] = ["vctipoComprobante", "vcestado", "vcfechaEmision", "vcnumeroComprobante", "vcdescripcion", "vcefectivo", "vccheques", "vcdepositos", "vctransferencias", "vctotal", "vcfechaAcreditacion", "vcaccion"]
  columnDefinitionsVistaCajaCompleta: string[] = ["vcctipoComprobante", "vccestado", "vccfechaEmision", "vccnumeroComprobante", "vccdescripcion", "vccimporteComprobante", "vccformaPago", "vccimportePago", "vccfechaAcreditacion", "vccaccion"]
  dataVistaCaja: boolean = false;
  dataVistaSimple: boolean = false;
  dataVistaCajaCompleta: boolean = false;
  checkedFiltroAvanzado: boolean = false




  constructor(
    private snackBarService: SnackBarService,
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private comprobantesService: ComprobantesService,
    private tiposComprobantesService: TiposComprobantesService,
    private estadosComprobanteService: EstadosComprobanteService,
    private breakpointObserver: BreakpointObserver,
    private emailService: EmailService,
    private archivosTemporalesService: ArchivosTemporalesService,
    private personaService: PersonasService,
    private toastService: HotToastService,
    private contratoService: ContratosService
  ) {
    this.form = this.formBuilder.group({
      FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
      FechaHasta: [new Date(), Validators.compose([Validators.required,])],
      RangoFecha: [''],
      TiposComprobantes: [''],
      NumeroContrato: [''],
      EstadosComprobante: ['']
    })

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.columnDefinitions = result.matches ?
        [
          { def: 'tipoComprobante', label: 'Tipo Comprobante', hide: this.formColumnas.controls['tipoComprobante'].value },
          { def: 'numeroComprobante', label: 'Comprobante', hide: this.formColumnas.controls['numeroComprobante'].value },
          { def: 'contrato', label: 'Contrato', hide: this.formColumnas.controls['contrato'].value },
          { def: 'accion', label: 'Acción', hide: this.formColumnas.controls['accion'].value }
        ] :
        [
          { def: 'tipoComprobante', label: 'Tipo Comprobante', hide: this.formColumnas.controls['tipoComprobante'].value },
          { def: 'estado', label: 'Estado', hide: this.formColumnas.controls['estado'].value },
          { def: 'numeroComprobante', label: 'Comprobante', hide: this.formColumnas.controls['numeroComprobante'].value },
          { def: 'fechaEmision', label: 'Fecha Emision', hide: this.formColumnas.controls['fechaEmision'].value },
          { def: 'contrato', label: 'Contrato', hide: this.formColumnas.controls['contrato'].value },
          // { def: 'propiedad', label: 'Propiedad', hide: this.formColumnas.controls['propiedad'].value },
          { def: 'propietario', label: 'Propietario', hide: this.formColumnas.controls['propietario'].value },
          { def: 'inquilino', label: 'Inquilino', hide: this.formColumnas.controls['inquilino'].value },
          { def: 'totalDebe', label: 'Total Debe.', hide: this.formColumnas.controls['totalDebe'].value },
          { def: 'totalHaber', label: 'Total Haber', hide: this.formColumnas.controls['totalHaber'].value },
          { def: 'totalPago', label: 'Total Pago', hide: this.formColumnas.controls['totalPago'].value },
          { def: 'total', label: 'Total', hide: this.formColumnas.controls['total'].value },
          { def: 'accion', label: 'Acción', hide: this.formColumnas.controls['accion'].value }
        ];
    });

  }
  //#region ng

  ngOnInit(): void {

    if (!isFalsy(this.numeroContrato)) {
      this.form.controls["NumeroContrato"].setValue(this.numeroContrato)
      this.buscarComprobantes(this.numeroContrato)
    }

    let o0: Observable<boolean> = this.formColumnas.controls['tipoComprobante'].valueChanges as Observable<boolean>;
    let o1: Observable<boolean> = this.formColumnas.controls['estado'].valueChanges as Observable<boolean>;
    let o2: Observable<boolean> = this.formColumnas.controls['numeroComprobante'].valueChanges as Observable<boolean>;
    let o3: Observable<boolean> = this.formColumnas.controls['fechaEmision'].valueChanges as Observable<boolean>;
    let o4: Observable<boolean> = this.formColumnas.controls['contrato'].valueChanges as Observable<boolean>;
    let o5: Observable<boolean> = this.formColumnas.controls['propietario'].valueChanges as Observable<boolean>;
    let o6: Observable<boolean> = this.formColumnas.controls['inquilino'].valueChanges as Observable<boolean>;
    let o7: Observable<boolean> = this.formColumnas.controls['totalDebe'].valueChanges as Observable<boolean>;
    let o8: Observable<boolean> = this.formColumnas.controls['totalHaber'].valueChanges as Observable<boolean>;
    let o9: Observable<boolean> = this.formColumnas.controls['totalPago'].valueChanges as Observable<boolean>;
    let o10: Observable<boolean> = this.formColumnas.controls['total'].valueChanges as Observable<boolean>;
    let o11: Observable<boolean> = this.formColumnas.controls['accion'].valueChanges as Observable<boolean>;

    merge(o0, o1, o2, o3, o4, o5, o6, o7, o8, o9, o10, o11).subscribe(v => {
      this.columnDefinitions[0].hide = this.formColumnas.controls['tipoComprobante'].value
      this.columnDefinitions[1].hide = this.formColumnas.controls['estado'].value
      this.columnDefinitions[2].hide = this.formColumnas.controls['numeroComprobante'].value
      this.columnDefinitions[3].hide = this.formColumnas.controls['fechaEmision'].value
      this.columnDefinitions[4].hide = this.formColumnas.controls['contrato'].value
      this.columnDefinitions[5].hide = this.formColumnas.controls['propietario'].value
      this.columnDefinitions[6].hide = this.formColumnas.controls['inquilino'].value
      this.columnDefinitions[7].hide = this.formColumnas.controls['totalDebe'].value
      this.columnDefinitions[8].hide = this.formColumnas.controls['totalHaber'].value
      this.columnDefinitions[9].hide = this.formColumnas.controls['totalPago'].value
      this.columnDefinitions[10].hide = this.formColumnas.controls['total'].value
      this.columnDefinitions[11].hide = this.formColumnas.controls['accion'].value
    });

    this.getDisplayedColumns();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  //#endregion

  //#region Acciones Tablas
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  getDisplayedColumns() {
    this.columns = this.columnDefinitions.filter(cd => !cd.hide).map(cd => cd.def);
  }
  //#endregion

  //#region Vistas Formulario
  generaVistaSimple(comprobanteDetailDto: ComprobanteDetailDto[]) {

    var lstVistaCaja: VistaSimple[] = []
    comprobanteDetailDto
      .forEach(comprobante => {

        var vistaSimple: VistaSimple = new VistaSimple
        vistaSimple.estado = isFalsy(comprobante.cabeceraComprobante.estadoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.estadoComprobante.descripcion
        vistaSimple.tipoComprobante = isFalsy(comprobante.cabeceraComprobante.tipoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.tipoComprobante.descripcion
        vistaSimple.fechaEmision = !isFalsy(comprobante.cabeceraComprobante.fechaEmision) ? comprobante.cabeceraComprobante.fechaEmision : ""
        vistaSimple.numeroComprobante = !isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? comprobante.cabeceraComprobante.numeroComprobante : ""
        vistaSimple.comprobanteDetailDto = comprobante
        vistaSimple.propiedad = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? comprobante.cabeceraComprobante.propiedad.ubicacion : ""
        vistaSimple.contrato = isFalsy(comprobante.cabeceraComprobante.contrato) ? 0 : comprobante.cabeceraComprobante.contrato.numeroContrato

        //Detalles Comprobante
        comprobante.detalleComprobante
          .forEach(element => {
            //Movimientos
            if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo)
              if (!isFalsy(element.movimiento) &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.EgresoCaja &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.IngresoCaja &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.CobroParcialAlquiler &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.LiquidacionPropietario &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.DeudaBonificacion) {
                if (element.movimiento.tipoMovimiento.resta == true)
                  vistaSimple.totalDebe += isFalsy(element.movimiento.importe) ? 0 : Math.round(element.movimiento.importe * 100) / 100
                else
                  vistaSimple.totalHaber += isFalsy(element.movimiento.importe) ? 0 : Math.round(element.movimiento.importe * 100) / 100
              }
            if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación)
              if (!isFalsy(element.movimiento) &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.EgresoCaja &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.IngresoCaja &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.EntregaParcialAlquiler &&
                element.movimiento.tipoMovimiento.id != TiposMovimiento.DeudaBonificacion) {
                if (element.movimiento.tipoMovimiento.resta == true)
                  vistaSimple.totalDebe += isFalsy(element.movimiento.importe) ? 0 : Math.round(element.movimiento.importe * 100) / 100
                else
                  vistaSimple.totalHaber += isFalsy(element.movimiento.importe) ? 0 : Math.round(element.movimiento.importe * 100) / 100
              }
            //DSP
            vistaSimple.totalDebe += isFalsy(element.detalleServicioPropiedad) ? 0 : isFalsy(element.detalleServicioPropiedad.importeATenerEnCuenta) ? 0 : element.detalleServicioPropiedad.importeATenerEnCuenta

            //Periodos
            if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación) {
              vistaSimple.totalHaber += isFalsy(element.periodoContrato) ? 0 : Math.round(element.periodoContrato.importeAlquiler * 100) / 100
            }
            else {
              vistaSimple.totalDebe += isFalsy(element.periodoContrato) ? 0 : Math.round(element.importeTotal * 100) / 100
            }


          });
        //Pagos
        if (!isFalsy(comprobante.cabeceraComprobante.pagos))
          comprobante.cabeceraComprobante.pagos.forEach(pago => {
            vistaSimple.totalPago += Math.round(pago.importe * 100) / 100
          });


        lstVistaCaja.push(vistaSimple)
      });
    this.dataSourceVistaSimple.data = lstVistaCaja
    this.dataVistaSimple = this.dataSourceVistaSimple.data.length >= 1 ? true : false
  }
  generaVistaCaja(comprobanteDetailDto: ComprobanteDetailDto[]) {


    var lstVistaCaja: VistaCaja[] = []
    comprobanteDetailDto.forEach(comprobante => {
      let tieneEgresoCaja = false;
      let totalMovimientos = 0;
      var vistaCaja: VistaCaja = new VistaCaja
      let signoSaldo = 1
      vistaCaja.estado = isFalsy(comprobante.cabeceraComprobante.estadoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.estadoComprobante.descripcion
      vistaCaja.idEstado = comprobante.cabeceraComprobante.estadoComprobante.id
      vistaCaja.tipoComprobante = isFalsy(comprobante.cabeceraComprobante.tipoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.tipoComprobante.descripcion
      vistaCaja.fechaEmision = isFalsy(comprobante.cabeceraComprobante.fechaEmision) ? "" : comprobante.cabeceraComprobante.fechaEmision
      vistaCaja.numeroComprobante = isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? "" : comprobante.cabeceraComprobante.numeroComprobante
      vistaCaja.comprobanteDetailDto = comprobante
      var ubicacion = isFalsy(comprobante.cabeceraComprobante.propiedad) ? "" : comprobante.cabeceraComprobante.propiedad.ubicacion
      var localidad = isFalsy(comprobante.cabeceraComprobante.propiedad) ? "" : isFalsy(comprobante.cabeceraComprobante.propiedad.localidad) ? "" : "(" + comprobante.cabeceraComprobante.propiedad.localidad.descripcion + ")"

      vistaCaja.descripcion = isFalsy(ubicacion) ? "Ingreso/Retiro de Caja" : ubicacion + " " + localidad

      comprobante.detalleComprobante.forEach(elemento => {
        if (!isFalsy(elemento.movimiento)) {
          totalMovimientos++;

          if (elemento.movimiento.tipoMovimiento.id === TiposMovimiento.EgresoCaja) {
            tieneEgresoCaja = true;
            return;
          }
        }
      });

      // Verificar condiciones después del bucle
      if (totalMovimientos === 1 && tieneEgresoCaja) {
        // Realizar acciones cuando hay exactamente un movimiento y es de tipo "EgresoCaja"
        signoSaldo = -1;
      }

      if (!isFalsy(comprobante.cabeceraComprobante.pagos))
        comprobante.cabeceraComprobante.pagos.forEach(pago => {
          if (pago.formaPago.id == FormasPagos.Trasferencia) {
            vistaCaja.transferencias += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }

          if (pago.formaPago.id == FormasPagos.TarjetaCredito) {
            vistaCaja.transferencias += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }

          if (pago.formaPago.id == FormasPagos.TarjetaDebito) {
            vistaCaja.transferencias += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }
          if (pago.formaPago.id == FormasPagos.MonederoElectronico) {
            vistaCaja.transferencias += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }

          if (pago.formaPago.id == FormasPagos.DepositoBanco)
            vistaCaja.depositos += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo

          if (pago.formaPago.id == FormasPagos.Efectivo)
            vistaCaja.efectivo += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo

          if (pago.formaPago.id == FormasPagos.ChequeDeTercero) {
            vistaCaja.cheques += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }

          if (pago.formaPago.id == FormasPagos.ChequeDiferido) {
            vistaCaja.cheques += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }

          if (pago.formaPago.id == FormasPagos.ChequePropio) {
            vistaCaja.cheques += isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
            vistaCaja.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          }

          vistaCaja.total = vistaCaja.cheques + vistaCaja.efectivo + vistaCaja.depositos + vistaCaja.transferencias

        });
      lstVistaCaja.push(vistaCaja)
    });
    this.dataSourceCaja.data = lstVistaCaja
    this.dataVistaCaja = this.dataSourceCaja.data.length >= 1 ? true : false
  }

  generaVistaCajaCompleta(comprobanteDetailDto: ComprobanteDetailDto[]) {

    var lstVistaCajaCompleta: VistaCajaCompleta[] = []
    comprobanteDetailDto.forEach(comprobante => {
      let tieneEgresoCaja = false;
      let totalMovimientos = 0;
      var vistaCajaCompleta: VistaCajaCompleta = new VistaCajaCompleta
      let signoSaldo = 1
      vistaCajaCompleta.vdIdEstado = comprobante.cabeceraComprobante.estadoComprobante.id
      vistaCajaCompleta.vdestado = isFalsy(comprobante.cabeceraComprobante.estadoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.estadoComprobante.descripcion
      vistaCajaCompleta.tipoComprobante = isFalsy(comprobante.cabeceraComprobante.tipoComprobante.descripcion) ? "" : comprobante.cabeceraComprobante.tipoComprobante.descripcion
      vistaCajaCompleta.fechaEmision = !isFalsy(comprobante.cabeceraComprobante.fechaEmision) ? comprobante.cabeceraComprobante.fechaEmision : ""
      vistaCajaCompleta.numeroComprobante = !isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? comprobante.cabeceraComprobante.numeroComprobante : ""
      vistaCajaCompleta.comprobanteDetailDto = comprobante
      var ubicacion = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? comprobante.cabeceraComprobante.propiedad.ubicacion : ""
      var localidad = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? !isFalsy(comprobante.cabeceraComprobante.propiedad.localidad) ? "(" + comprobante.cabeceraComprobante.propiedad.localidad.descripcion + ")" : "" : ""
      vistaCajaCompleta.importeComprobante = this.getTotalDetalleComprobante(comprobante.detalleComprobante)

      vistaCajaCompleta.cabecera = true
      vistaCajaCompleta.descripcion = isFalsy(ubicacion) ? "Ingreso/Retiro de Caja" : ubicacion + " " + localidad

      lstVistaCajaCompleta.push(vistaCajaCompleta)

      comprobante.detalleComprobante.forEach(element => {
        totalMovimientos++;

        if (!isFalsy(element.movimiento) &&
          element.movimiento.tipoMovimiento.id === TiposMovimiento.EgresoCaja) {
          tieneEgresoCaja = true;
          // Salir del bucle tan pronto como se encuentre un movimiento de tipo "EgresoCaja"
          return;
        }




        //   if (!isFalsy(element.movimiento) &&
        //     element.movimiento.tipoMovimiento.id != TiposMovimiento.EgresoCaja &&
        //     element.movimiento.tipoMovimiento.id != TiposMovimiento.IngresoCaja &&
        //     element.movimiento.tipoMovimiento.id != TiposMovimiento.DeudaBonificacion)
        //     if (element.movimiento.tipoMovimiento.resta == true)
        //       signoSaldo = -1


      });
      if (totalMovimientos === 1 && tieneEgresoCaja) {
        signoSaldo = -1;
      }


      if (!isFalsy(comprobante.cabeceraComprobante.pagos))
        comprobante.cabeceraComprobante.pagos.forEach(pago => {
          var vistaCajaCompleta: VistaCajaCompleta = new VistaCajaCompleta
          vistaCajaCompleta.fechaEmision = isFalsy(comprobante.cabeceraComprobante.fechaEmision) ? "" : comprobante.cabeceraComprobante.fechaEmision
          vistaCajaCompleta.numeroComprobante = isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? "" : comprobante.cabeceraComprobante.numeroComprobante
          vistaCajaCompleta.comprobanteDetailDto = comprobante
          var ubicacion = isFalsy(comprobante.cabeceraComprobante.propiedad) ? "" : comprobante.cabeceraComprobante.propiedad.ubicacion
          var localidad = isFalsy(comprobante.cabeceraComprobante.propiedad) ? "" : isFalsy(comprobante.cabeceraComprobante.propiedad.localidad) ? "" : "(" + comprobante.cabeceraComprobante.propiedad.localidad.descripcion + ")"
          vistaCajaCompleta.formaPago = isFalsy(pago.formaPago) ? "" : pago.formaPago.descripcion
          vistaCajaCompleta.importePago = isFalsy(pago.importe) ? 0 : (Math.round(pago.importe * 100) / 100) * signoSaldo
          vistaCajaCompleta.fechaAcreditacion = isFalsy(pago.fechaAcreditacion) ? "" : pago.fechaAcreditacion
          vistaCajaCompleta.cabecera = false
          lstVistaCajaCompleta.push(vistaCajaCompleta)
        });


    });
    this.dataSourceCajaCompleta.data = lstVistaCajaCompleta
    this.dataVistaCajaCompleta = this.dataSourceCajaCompleta.data.length >= 1 ? true : false
  }

  //#endregion

  //#region Filtros
  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

    this.getAllTiposComprobantes();
    this.getAllEstadosComprobante();

  }

  selectRangoFecha(event: any) {
    this.rangoFecha = event.value
    if (this.rangoFecha == RangoFechasValores.Todos) {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime() + this.rangoFecha)))
    }
    else {
      this.form.controls["FechaDesde"].setValue(new Date((new Date().getTime() - this.rangoFecha)))
      this.form.controls["FechaHasta"].setValue(new Date((new Date().getTime())))
    }
  }

  selectPropietario(personaPropietarioDto: PersonaPropietarioDto) {
    this.personaPropietarioDto = personaPropietarioDto
  }

  selectContrato(baseDto: BaseDto) {
    if (isFalsy(baseDto))
      this.contratoDetailDto = null
    else
      this.getContrato(baseDto.id)
  }

  selectPropiedad(propiedadBaseDto: BaseDto) {
    this.propiedadBaseDto = propiedadBaseDto
  }

  selectInquilino(inquilinoDto: InquilinoDto) {
    this.inquilinoDto = inquilinoDto
  }

  //#endregion

  //#region Calculos tabla
  getTotal() {
    return this.dataSourceCaja.data.filter(x => x.idEstado != EstadosComprobantes.Anulado).map(t => (Math.round(t.total * 100) / 100)).reduce((acc, value) => acc + value, 0);
  }

  getTotalTransferencias() {
    return this.dataSourceCaja.data.filter(x => x.idEstado != EstadosComprobantes.Anulado).map(t => Math.round(t.transferencias * 100) / 100).reduce((acc, value) => acc + value, 0);
  }

  getTotalDepositos() {
    return this.dataSourceCaja.data.filter(x => x.idEstado != EstadosComprobantes.Anulado).map(t => Math.round(t.depositos * 100) / 100).reduce((acc, value) => acc + value, 0);
  }

  getTotalCheques() {
    return this.dataSourceCaja.data.filter(x => x.idEstado != EstadosComprobantes.Anulado).map(t => Math.round(t.cheques * 100) / 100).reduce((acc, value) => acc + value, 0);
  }

  getTotalEfectivo() {
    return this.dataSourceCaja.data.filter(x => x.idEstado != EstadosComprobantes.Anulado).map(t => Math.round(t.efectivo * 100) / 100).reduce((acc, value) => acc + value, 0);
  }

  getTotalPagos(item: PagoBasicDto[]): number {
    let totalPagos = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.importe * 100) / 100), 0);
    return Math.round(totalPagos * 100) / 100
  }

  getTotalDetalleComprobante(item: DetalleComprobanteDetailDto[]): number {
    let totalComprobante = item.reduce((sum, element) => (Math.round(sum * 100) / 100) + (Math.round(element.importeTotal * 100) / 100), 0);
    return Math.round(totalComprobante * 100) / 100
  }
  //#endregion

  //#region  Peticiones Back-end
  cancelVoucher(id: number) {
    this.spinner.show()
    this.comprobantesService.cancelVoucher(id)
      .subscribe(
        data => {
          this.spinner.hide()
          this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
            return value.id != id;
          });
          this.data = this.dataSource.data.length >= 1 ? true : false
          if (isFalsy(data)) {
            this.snackBarService.showSuccess("Comprobante anulado correctamente.", "Exito");
            this.buscarComprobantes(this.numeroContrato)
          }

          else {
            this.spinner.hide()
            this.snackBarService.showTemplate(data.cancelVoucherError.descripcion, "/comprobante/" + data.cancelVoucherError.idComprobante, "", "Error", 0, SnackbarClass.error);

          }

        },
        error => {
          this.spinner.hide()
          this.snackBarService.showError(error, "Error");
        })
  }

  buscarComprobantes(nroContrato: number) {
    let numeroContrato = null
    //Fijos
    let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
    let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
    // Ajustar la hora para la fecha desde
    fechaDesde = fechaDesde + ' 00:00:00';

    // Ajustar la hora para la fecha hasta
    fechaHasta = fechaHasta + ' 23:59:59';

    //Variables
    let contratoId = isFalsy(this.contratoDetailDto) ? null : this.contratoDetailDto.id
    let idInquilino = isFalsy(this.inquilinoDto) ? null : this.inquilinoDto.id
    let idPropiedad = isFalsy(this.propiedadBaseDto) ? null : this.propiedadBaseDto.id
    let idPropietario = isFalsy(this.personaPropietarioDto) ? null : this.personaPropietarioDto.id_Propietario

    if (!isFalsy(nroContrato))
      numeroContrato = nroContrato
    else
      numeroContrato = isFalsy(this.contratoDetailDto) ? null : this.contratoDetailDto.numeroContrato

    let idTipoComprobante = this.form.controls["TiposComprobantes"].value
    let idEstadoComprobante = this.form.controls["EstadosComprobante"].value

    this.spinner.show()
    this.comprobantesService.getAllByFilter(fechaDesde, fechaHasta, idTipoComprobante, idEstadoComprobante, numeroContrato, idPropiedad, idInquilino, idPropietario)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data.sort((b, a) => a.cabeceraComprobante.numeroComprobante.toString().localeCompare(b.cabeceraComprobante.numeroComprobante.toString()))
          this.data = this.dataSource.data.length >= 1 ? true : false
          this.generaVistaSimple(data)
          this.generaVistaCaja(data)
          this.generaVistaCajaCompleta(data)
        },
        error => {
          this.spinner.hide();
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  getContrato(id: number) {
    this.spinner.show("spMovimiento")
    this.contratoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spMovimiento")
          this.contratoDetailDto = data
        },
        error => {
          this.spinner.hide("spMovimiento")
          this.snackBarService.showError(error, "Error");
        })
  }

  getArchivoTemporalByIdComprobante(comprobante: ComprobanteDetailDto, enviaEmail: boolean, download: boolean) {
    if (comprobante)
      this.archivosTemporalesService.getAllByIdComprobante(comprobante.cabeceraComprobante.idComprobante).pipe(
        this.toastService.observe(
          {
            loading: 'Aguarde por favor...',
            success: (s) => 'Proceso finalizado con exito. ',
            //error: (e) => e,
          }
        ))
        .subscribe(
          async data => {
            if (data.length >= 1)
              await data.forEach(element => {
                if (enviaEmail)
                  this.getFileContentById(element, true, false, comprobante)
                else if (download)
                  this.getFileContentById(element, false, true, comprobante)
                else
                  this.toastService.error("No existe archivo de comprobante.", {
                    duration: 5000, autoClose: true
                  })
              });
            else
              this.toastService.error("No existe archivo de comprobante.", {
                duration: 5000, autoClose: true
              })
          },
          error => {
            this.snackBarService.showError(error, "Error");
            this.toastService.close()
          }
        )
  }

  sendEmail(sendEmailRequest: SendEmailRequest) {
    if (sendEmailRequest)
      this.emailService.send(sendEmailRequest).pipe(
        this.toastService.observe(
          {
            loading: 'Enviando correo...',
            success: (s) => 'Correo enviado correctamente. ',
          }
        )).subscribe(
          data => {
            //this.snackBarService.showSuccess("Correo enviado correctamente.", "Exito");
          },
          error => {
            this.snackBarService.showError(error, "Error");
          }
        )
  }

  getFileContentById(archivoTemporalDto: ArchivoTemporalDto, enviaEmail: boolean, download: boolean, comprobante: ComprobanteDetailDto): string {
    let contenido = ""
    this.archivosTemporalesService.getFileContentById(archivoTemporalDto.id).pipe(
      this.toastService.observe(
        {
          loading: 'Obteniendo información...',
          success: (s) => 'Proceso finalizado con exito. ',
          // error: (e) => e,
        }
      )).subscribe
      (
        async data => {
          if (!isFalsy(data.content)) {
            archivoTemporalDto.contenidoArchivo = data.content
            if (download)
              await ComprobantesController.generarDocumentoPdf(archivoTemporalDto)
            if (enviaEmail) {
              await this.createEmail(archivoTemporalDto, comprobante)
            }

          }
        },
        error => {
          this.snackBarService.showError(error, "Error");
        }
      )
    return contenido
  }

  getAllTiposComprobantes() {
    this.spinner.show("spBusquedaTiposComprobantes")
    this.tiposComprobantesService.getAll()
      .subscribe(
        async data => {
          this.spinner.hide("spBusquedaTiposComprobantes")
          this.lstTiposComprobantes = data
        },
        error => {
          this.spinner.hide("spBusquedaTiposComprobantes")
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  getAllEstadosComprobante() {
    this.spinner.show("spBusquedaEstadosComprobante")
    this.estadosComprobanteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaEstadosComprobante")
          this.lstEstadosComprobante = data
        },
        error => {
          this.spinner.hide("spBusquedaEstadosComprobante")
          this.snackBarService.showError(error, "Error");
        }
      )
  }

  //#endregion

  //#region Acciones

  descargarComprobante(element: ComprobanteDetailDto) {
    this.getArchivoTemporalByIdComprobante(element, false, true)
  }

  createEmail(archivotemporal: ArchivoTemporalDto, comprobante: ComprobanteDetailDto) {
    var sendEmailRequest: SendEmailRequest = new SendEmailRequest
    let nroComprobante = !isFalsy(comprobante.cabeceraComprobante.numeroComprobante) ? "Comprobante nro. " + comprobante.cabeceraComprobante.numeroComprobante : ""
    let propiedad = !isFalsy(comprobante.cabeceraComprobante.propiedad) ? !isFalsy(comprobante.cabeceraComprobante.propiedad.ubicacion) ? " - Propiedad: " + comprobante.cabeceraComprobante.propiedad.ubicacion.toUpperCase() : "" : ""

    //Asunto
    sendEmailRequest.asunto = nroComprobante + propiedad
    //Body
    sendEmailRequest.body = ""

    //Destinatarios
    var destinatarios: ReceiverDto[] = []

    destinatarios.push(this.receiverDto);

    sendEmailRequest.destinatarios = destinatarios;

    //Adjuntos
    var listaArchivos: EmailDto[] = []
    var archivo = new EmailDto()
    archivo.fileContent = archivotemporal.contenidoArchivo
    archivo.extension = ".pdf"
    archivo.fileName = archivotemporal.nombreArchivo + archivo.extension
    listaArchivos.push(archivo)
    sendEmailRequest.files = listaArchivos

    if (sendEmailRequest)
      this.sendEmail(sendEmailRequest)
  }


  clearForm() {
    this.form.controls["EstadosComprobante"].setValue(0)
    this.form.controls["NumeroContrato"].setValue("")
    this.form.controls["TiposComprobantes"].setValue(0)
    this.inquilinoDto = null
    this.propiedadBaseDto = null
    this.personaPropietarioDto = null
    this.contratoDetailDto = new ContratoDetailDto

  }

  //#endregion

  //#region Dialogs

  openDialogEnviarCorreoComprobante(comprobante: ComprobanteDetailDto) {

    this.receiverDto = new ReceiverDto()

    if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Recibo) {
      if (comprobante.cabeceraComprobante.inquilino != null) {
        this.receiverDto.receiverAddress = isFalsy(comprobante.cabeceraComprobante.inquilino.persona.correo) ? "" : comprobante.cabeceraComprobante.inquilino.persona.correo
        this.receiverDto.receiverName = isFalsy(comprobante.cabeceraComprobante.inquilino.persona.nombreCompleto) ? "" : comprobante.cabeceraComprobante.inquilino.persona.nombreCompleto
      }
      else {
        this.snackBarService.showInfo("No existe inquilino vinculado al comprobante.", "Info");
      }

    }

    if (comprobante.cabeceraComprobante.tipoComprobante.id == TiposComprobantes.Liquidación) {
      if (comprobante.cabeceraComprobante.propietario != null) {
        this.receiverDto.receiverAddress = isFalsy(comprobante.cabeceraComprobante.propietario.persona.correo) ? "" : comprobante.cabeceraComprobante.propietario.persona.correo
        this.receiverDto.receiverName = isFalsy(comprobante.cabeceraComprobante.propietario.persona.nombreCompleto) ? "" : comprobante.cabeceraComprobante.propietario.persona.nombreCompleto
      }
      else {
        this.snackBarService.showInfo("No existe propietario vinculado al comprobante.", "Info");
      }

    }


    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.maxWidth = "25%"
    dialogConfig.data = this.receiverDto


    const dialogRef = this.dialog.open(DialogNuevoEmailComponent,
      dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar) {
        if (result.data != null) {
          this.receiverDto.receiverAddress = result.data
          this.receiverDto.receiverName = result.data
          this.getArchivoTemporalByIdComprobante(comprobante, true, false)
        }
      }

    });
  }


  openDialog(element: ComprobanteDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%"
    dialogConfig.data = element as ComprobanteDetailDto

    const dialogRef = this.dialog.open(DialogComprobanteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDialogCancelVoucher(element: ComprobanteDetailDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "30%"
    dialogConfig.data = { descripcion: element.cabeceraComprobante.numeroComprobante, id: element.cabeceraComprobante.idComprobante }

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar)
        this.cancelVoucher(result.data)
    });
  }

  //#endregion

  //#region Helper


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion


}
