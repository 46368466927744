import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PeriodosContratoService } from '@services/periodos-contrato/periodos-contrato.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { IndiceAjusteContratoDto } from '@models/indice-ajuste-contrato/indiceAjusteContratoDto.model';
import { IndiceAjusteContratoService } from '@services/indice-ajuste-contrato/indiceAjusteContrato.service';
import { isFalsy } from 'utility-types';
import { TipoAjusteContratoService } from '@services/tipo-ajuste-contrato/tipoAjusteContrato.service';
import { TipoAjusteContratoDto } from '@models/tipo-ajuste-contrato/tipoAjusteContratoDto.model';

@Component({
  selector: 'app-dialog-calcula-indice-ajuste-periodo-contrato',
  templateUrl: './dialog-calcula-indice-ajuste-periodo-contrato.component.html',
  styleUrls: ['./dialog-calcula-indice-ajuste-periodo-contrato.component.css'],
  providers: [DatePipe]
})
export class DialogCalculaIndiceAjustePeriodoContrato implements OnInit {

  indiceAjusteContrato!: IndiceAjusteContratoDto;
  formGenerarCalculo: FormGroup;
  action: any;
  enProceso:boolean= false
  lstTiposAjusteContrato!:TipoAjusteContratoDto[];
  lstIndiceAjusteContrato!: IndiceAjusteContratoDto[];
  get f() { return this.formGenerarCalculo.controls; }
  constructor
    (
      private _formBuilder: FormBuilder,
      private spinner: NgxSpinnerService,
      private snackBar: SnackBarService,
      private indiceAjusteContratoService: IndiceAjusteContratoService,
      private periodosContratoService: PeriodosContratoService,
      public datepipe: DatePipe,
      public dialogRef: MatDialogRef<DialogCalculaIndiceAjustePeriodoContrato>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private tipoAjusteContratoService: TipoAjusteContratoService,
      private toastService: HotToastService
    ) {
    this.action = this.data.accion;


    this.formGenerarCalculo = this._formBuilder.group({
      AcceptTerms: [false, Validators.requiredTrue],
      BotonGenerar: [],
      TiposAjusteContrato:[]
    });
  }

  ngOnInit(): void {
    this.getAllTipoAjusteContrato()
  }

  generarCalculo() {
    if (this.formGenerarCalculo.valid) {
      this.enProceso = true
      const fechaProceso = this.datepipe.transform(new Date, 'yyyy-MM-dd HH:mm:ss') as string;
      this.periodosContratoService.correrProcesoAjustePeriodosContrato(fechaProceso)
        .pipe(
          this.toastService.observe(
            {
              loading: 'Aguarde por favor...',
              success: (s) => 'Proceso finalizado correctamente.',
            }
          )
        )
        .subscribe(
          data => {
            this.toastService.close()
            this.dialogRef.close({ event: AccionesDialog.Aceptar });
          },
          error => {
            this.toastService.close()
            this.snackBar.showError(error, "Error");
          });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  getAllIndiceAjusteContrato() {
    this.spinner.show("spIndiceAjusteContrato");
    this.indiceAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spIndiceAjusteContrato");
          data.sort((a, b) =>
            a.fecha < b.fecha ? 1 : -1)
          this.lstIndiceAjusteContrato = data
          this.indiceAjusteContrato = this.lstIndiceAjusteContrato[0]
        },
        error => {
          this.spinner.hide("spIndiceAjusteContrato");
          this.snackBar.showError(error, "Error");
        });
  }

  getAllTipoAjusteContrato() {
    this.spinner.show("spBusquedaTipoAjusteContrato")
    this.tipoAjusteContratoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.lstTiposAjusteContrato = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()))
        },
        error => {
          this.spinner.hide("spBusquedaTipoAjusteContrato")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  //#region Helper

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
  //#endregion

}
