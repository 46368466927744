<br>
<div class="container-fluid">
  <div class="row">
    <!-- Accesos Rapidos   -->
    <div class="col-lg-2 col-md-12 col-sm-12 ">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Acciones
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-lg-12">
              <app-btn-nuevo-contrato></app-btn-nuevo-contrato>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Contratos por estado -->
    <div class="col-lg-5 col-md-12 col-sm-12">
      <mat-card>
        <ngx-spinner name="spContadores" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
          [fullScreen]="false">
        </ngx-spinner>
        <mat-card-header>
          <mat-card-title>
            Estados de contratos
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="contadorContratoDto">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="with-icon">
                <mat-icon class="icon-success count-icon">check_circle_outline</mat-icon><span
                  class="aligned-with-icon"><strong>Activos:</strong> </span><span
                  class="count-number aligned-with-icon">{{contadorContratoDto.activos}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="with-icon">
                <mat-icon class="icon-error count-icon">highlight_off</mat-icon><span
                  class="aligned-with-icon"><strong>Finalizados:</strong></span><span
                  class="count-number aligned-with-icon"> {{contadorContratoDto.finalizados}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="with-icon">
                <mat-icon class="icon-warn count-icon">highlight_off</mat-icon><span
                  class="aligned-with-icon"><strong>Reiscididos:</strong> </span><span
                  class="count-number aligned-with-icon">{{contadorContratoDto.reiscididos}}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <!-- Contrato por clase de propiedad -->
    <div class="col-lg-5 col-md-12 col-sm-12">
      <mat-card>
        <ngx-spinner name="getContadorContratoPorPripiedadClase" bdColor="rgba(255,255,255,0.8)" size="default"
          color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-card-header>
          <mat-card-title>
            Contratos por clase
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="contadorContratoPorPropiedadClaseDto">
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="with-icon">
                <mat-icon class="icon-success count-icon">gavel</mat-icon><span
                  class="aligned-with-icon"><strong>Total:</strong> </span><span
                  class="count-number">{{contadorContratoPorPropiedadClaseDto.totalContratos}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="with-icon">
                <mat-icon class="icon-info count-icon">home</mat-icon><span
                  class="aligned-with-icon"><strong>Casa/Vivienda:</strong></span><span class="count-number ">
                  {{contadorContratoPorPropiedadClaseDto.casaVivienda}}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="with-icon">
                <mat-icon class="icon-info count-icon">business</mat-icon><span
                  class="aligned-with-icon"><strong>Comercial:</strong> </span><span
                  class="count-number">{{contadorContratoPorPropiedadClaseDto.comercial}}</span>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Contratos por Vencer
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ngx-spinner name="getContratosPorVencer" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
            type="ball-atom" [fullScreen]="false">
          </ngx-spinner>
          <mat-form-field class="example-full-width">
            <mat-label>Buscar</mat-label>
            <input matInput type="search" (keyup)="applyFilterContratosPorVencer($event)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <br>
          <table mat-table [dataSource]="dataSourceContratosPorVencer" [hidden]=!dataContratosPorVencer table matSort
            matSortStart="asc" class="mat-elevation-z8">


            <!-- Id Column -->
            <!-- <ng-container matColumnDef="id">
                            <mat-header-cell mat-header-cell *matHeaderCellDef> # </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
                        </ng-container> -->

            <!-- Fecha Vencimiento Column -->
            <ng-container matColumnDef="fechaVencimiento">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Vencimiento </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element"> {{element.fechaVencimiento | date:("dd/MM/yyyy")}}
              </mat-cell>
            </ng-container>

            <!-- Cantidad dias restantes Column -->
            <ng-container matColumnDef="cantidadDiasRestante">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Dias restantes </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element"> {{element.cantidadDiasRestante}} </mat-cell>
            </ng-container>

            <!-- Ubicacion Column -->
            <ng-container matColumnDef="ubicacionPropiedad">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Propiedad </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element">
                {{element.ubicacionPropiedad | titlecase}}
              </mat-cell>
            </ng-container>

            <!-- Ubicacion Column -->
            <ng-container matColumnDef="nombrePersonaInquilino">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Inquilino </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element">
                {{element.nombrePersonaInquilino | titlecase}}
              </mat-cell>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="accion">
              <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="['/contratos',element.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button>
                  <!-- <button mat-menu-item (click)="descargarArchivo(element)">
                  <mat-icon>download</mat-icon>
                  <span>Descargar</span>
                </button>
                    <button mat-menu-item (click)="openDialog(element)">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>-->
                </mat-menu>
              </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumnsContratosPorVencer"></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumnsContratosPorVencer;"></mat-row>
          </table>
          <div class="no-data-table">
            <span class="with-icon"
              *ngIf="dataSourceContratosPorVencer.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
              datos para mostrar.</span>
          </div>
          <mat-paginator showFirstLastButtons class="paginator" [hidden]=!dataContratosPorVencer [pageSize]="5"
            [pageSizeOptions]="[5, 10, 20,50,100,200]">
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Contratos para actualizar ajuste de periodo
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ngx-spinner name="getContratosPorAjustar" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
            type="ball-atom" [fullScreen]="false">
          </ngx-spinner>
          <mat-form-field class="example-full-width">
            <mat-label>Buscar</mat-label>
            <input matInput type="search" (keyup)="applyFilterContratosPorAjustar($event)">
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <br>
          <table mat-table [dataSource]="dataSourceContratosPorAjustar" [hidden]=!dataContratosPorAjustar table matSort
            matSortStart="asc" class="mat-elevation-z8">


            <ng-container matColumnDef="fechaComienzo">
              <mat-header-cell mat-header-cell *matHeaderCellDef > Fecha Comienzo </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element"> {{element.fechaComienzo | date:("dd/MM/yyyy")}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="numeroContrato">
              <mat-header-cell mat-header-cell *matHeaderCellDef > Nro. Contrato </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element"> {{element.numeroContrato}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="propiedad">
              <mat-header-cell mat-header-cell *matHeaderCellDef > Propiedad </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element">
                {{element.propiedad.ubicacion | titlecase}}, Depto: {{element.propiedad.piso | titlecase}} Piso: {{element.propiedad.depto | titlecase}}
              </mat-cell>
            </ng-container>


            <!-- Action Column -->
            <ng-container matColumnDef="accion">
              <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let element" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [routerLink]="['/contratos',element.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumnsContratosPorAjustar"></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumnsContratosPorAjustar;"></mat-row>
          </table>
          <div class="no-data-table">
            <span class="with-icon"
              *ngIf="dataSourceContratosPorAjustar.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
              datos para mostrar.</span>
          </div>
          <mat-paginator showFirstLastButtons class="paginator" [hidden]=!dataContratosPorAjustar [pageSize]="5"
            [pageSizeOptions]="[5, 10, 20,50,100,200]">
          </mat-paginator>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
