import { LOCALE_ID, ErrorHandler, Inject, Injectable, InjectionToken, NgModule, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from '../route/app-routing.module';
import { AppComponent } from '../app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
// importar locales
import localePy from '@angular/common/locales/es-PY';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
import localeEsAr from '@angular/common/locales/es-AR';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en')
registerLocaleData(localeEsAr, 'es-Ar');

//ngx-markdown
import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

//Breadcrum
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";

//NGX
import { NgxSpinnerModule } from "ngx-spinner";

//Manejo de Errores
import { AuthInterceptorService } from 'src/app/interceptors/auth/auth-interceptor.service';

//Fin-Charts


//App
import { BreadcrumbComponent } from '../views/breadcrumb/breadcrumb.component';
import { MyLoaderComponent } from '../views/my-loader/my-loader.component';
import { SpinnerComponent } from '../views/spinner/spinner.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { InicioComponent } from '../views/inicio/inicio.component';
import { PercentageDirective } from '../directives/porcentaje.directive';
import { CoreModule } from './core.module';
import { SharedModule } from './shared.module';
import { PersonasModule } from './personas.module';
import { UsuariosModule } from './usuarios.module';
import { TiposFolioModule } from './tipos-folio.module';
import { TipoSelladoModule } from './tipo-sellado.module';
import { TipoPunitorioModule } from './tipo-punitorio.module';
import { PropiedadesModule } from './propiedades.module';
import { LocalidadModule } from './localidad.module';
import { LoginModule } from '../views/login/login.module';
import { GarantiasModule } from './garantias.module';
import { AuthGuardService } from '../guard/auth-guard/auth-guard.service';
import { CanDeactivateGuard } from '../directives/can-deactivate/can-deactivate.guard';
import { GlobalHttpErrorInterceptorService } from '../interceptors/error-interceptor/error-interceptor.service';
import { ProcesosModule } from './procesos.module';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule } from '@angular/forms';
import { IndicesAjusteContratoModule } from './indices-ajuste-contrato.module';
import { BonificacionesModule } from './bonificaciones.module';
import { NavSearchPerfilLogoModule } from './nav-search-perfil-logo.module';
import { FilterPipe } from '../pipes/filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GenerarResumenMensualComponent } from '../views/generar-resumen-mensual/generar-resumen-mensual.component';
import { DialogGenerarResumenMensualComponent } from '../views/generar-resumen-mensual/dialog-generar-resumen-mensual/dialog-generar-resumen-mensual.component';
import { BtnGenerarResumenMensualComponent } from '../views/generar-resumen-mensual/btn-generar-resumen-mensual/btn-generar-resumen-mensual.component';
import { TiposMovimientoModule } from './tipos-movimiento.module';
import { TiposBonificacionModule } from './tipos-bonificacion.module';
import { TipoCondicionContableModule } from './tipos-condicion-contable.module';
import { ComprobantesModule } from './comprobantes.module';
import { ContratosModule } from './contratos.module';
import { ArchivosModule } from './archivos.module';
import { BancosModule } from './bancos.module';
import { ConfiguracionModule } from './configuracion.module';
import { CuentasContableModule } from './cuentas-contables.module';
import { DashboardModule } from './dashboard.module';
import { ServiciosModule } from './servicios.module';
import { DocumentosModule } from './documentos.module';
import { FormaPagoModule } from './forma-pago.module';
import { InquilinosModule } from './inquilinos.module';
import { LiquidacionesModule } from './liquidaciones.module';
import { MovimientoModule } from './movimiento.module';
import { NotificacionesModule } from './notificaciones.module';
import { PropietariosModule } from './propietarios.module';
import { CajaModule } from './caja.module';

//Mascaras
import { CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
//Editor HTML
import { NgxEditorModule } from 'ngx-editor';
import { DialogDescripcionComponent } from '../views/dialog-basic/dialog-descripcion/dialog-descripcion.component';

//Notificaciones
import { SnackbarComponent } from '../views/snackbar/snackbar.component';
import { HotToastModule } from '@ngneat/hot-toast';
//Crear PDF
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//Redex
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from 'src/environments/environment';
import { ErroresModule } from './errores.modules';
import { SeguridadModule } from './seguridad.module';
import { GenericosModule } from './genericos.module';
import { ArchivoTemporalModule } from './archivo-temporal.module';
import { EmailModule } from './email.module';
import { reducer } from '../redux/app.reducers';
import { MyMonitoringService } from '@services/microsoft/applicationInsights.services';
import { RollbarErrorHandlerService, rollbarFactory, RollbarService } from '@services/error/rollbarErrorHandler.service';
import { AuditoriaLogModule } from './auditoria-log.module';
import { TipoAjusteContratoModule } from './tipo-ajuste-contrato.module';
import { PeriodosContratoModule } from './periodos-contrato.module';
//Configuracion Mascaras

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

const maskConfig: Partial<IConfig> = {
  validation: false,
};

// Microsfot Application Insights
// @Injectable()
// export class ErrorHandlerService extends ErrorHandler {

//   constructor(@Inject(MyMonitoringService) private myMonitoringService: MyMonitoringService) {
//     super();
//   }
//   handleErrorInsights(error: Error) {
//     this.myMonitoringService.logException(error); // Manually log exception
//   }
// }


@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    ErroresModule,
    Ng2SearchPipeModule,
    PersonasModule,
    UsuariosModule,
    TiposFolioModule,
    TipoSelladoModule,
    TipoPunitorioModule,
    ServiciosModule,
    PropietariosModule,
    PropiedadesModule,
    LocalidadModule,
    DocumentosModule,
    ContratosModule,
    GarantiasModule,
    BancosModule,
    InquilinosModule,
    LoginModule,
    ArchivosModule,
    ProcesosModule,
    LayoutModule,
    DashboardModule,
    CuentasContableModule,
    ConfiguracionModule,
    BonificacionesModule,
    NavSearchPerfilLogoModule,
    TiposMovimientoModule,
    TiposBonificacionModule,
    TipoCondicionContableModule,
    MovimientoModule,
    CajaModule,
    NotificacionesModule,
    FormaPagoModule,
    LiquidacionesModule,
    ComprobantesModule,
    GenericosModule,
    ArchivoTemporalModule,
    EmailModule,
    SeguridadModule,
    AuditoriaLogModule,
    TipoAjusteContratoModule,
    IndicesAjusteContratoModule,
    PeriodosContratoModule,
    //NGX Mask https://www.npmjs.com/package/ngx-mask
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    //Breadcrum
    NgDynamicBreadcrumbModule,
    //Spinner
    NgxSpinnerModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    //Editor HTML
    NgxEditorModule,

    //Notifaciones : https://github.com/ngneat/hot-toast#installation
    HotToastModule.forRoot(
      {
        reverseOrder: true,
        dismissible: true,
        position: 'bottom-right',
        autoClose: true,
      }
    ),
    //Redux
    StoreModule.forRoot(reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 5,
      logOnly: environment.production
    })


  ],
  declarations: [
    AppComponent,
    InicioComponent,
    BreadcrumbComponent,
    MyLoaderComponent,
    SpinnerComponent,
    PercentageDirective,
    SnackbarComponent,
    FilterPipe,
    GenerarResumenMensualComponent,
    DialogGenerarResumenMensualComponent,
    BtnGenerarResumenMensualComponent,
    DialogDescripcionComponent
  ],
  providers: [
    AuthGuardService,
    CanDeactivateGuard,
    AuthInterceptorService,
    MyMonitoringService,
    //locales
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: '$' },
    //RollbarService
    { provide: ErrorHandler, useClass: RollbarErrorHandlerService },
    { provide: RollbarService, useFactory: rollbarFactory },

    // Microsfot Application Insights
    //{ provide: ErrorHandler, useClass: ErrorHandlerService },
    //Error Service
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpErrorInterceptorService, multi: true },

    //Autenticacion
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
  ],
  exports: [
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
