<div class="list-group" *appRole="['Contratos.Ver']">
    <button type="button" [routerLinkActive]="['active']" routerLink='/contratos/{{idContrato}}' class="list-group-item list-group-item-action">
        <div class="with-icon">
        <mat-icon >gavel</mat-icon><span class="aligned-with-icon">Contrato</span>
        </div>
    </button>
    <button type="button" [routerLinkActive]="['active']" routerLink='/periodoshistoricos/{{idContrato}}' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>history</mat-icon> <span class="aligned-with-icon">Periodos Historicos</span>
      </div>
    </button>
   <button type="button" *appRole="['Documentos.Ver']" [routerLinkActive]="['active']" routerLink='/documentoscontrato/{{idContrato}}' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>folder</mat-icon> <span class="aligned-with-icon">Documentos</span>
      </div>
    </button>
    <button type="button" [routerLinkActive]="['active']" routerLink='/archivostemporalescontrato/{{idContrato}}' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>source</mat-icon> <span class="aligned-with-icon">Archivos Temporales</span>
      </div>
    </button>

    <button type="button" *appRole="['Movimientos.Ver']" [routerLinkActive]="['active']" routerLink='/movimientosscontrato/{{idContrato}}' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>account_balance</mat-icon> <span class="aligned-with-icon">Movimientos</span>
      </div>
    </button>
    <button type="button" *appRole="['Comprobantes.Ver']" [routerLinkActive]="['active']" routerLink='/comprobantescontrato/{{idContrato}}' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>wysiwyg</mat-icon> <span class="aligned-with-icon">Comprobantes</span>
      </div>
    </button>
</div>
