import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.css']
})
export class GenericDialogComponent implements OnInit {

head!: string
action!: string
body!: string
  constructor() { }

  ngOnInit(): void {
  }

}
