<mat-card *appRole="['Auditoria.Listado']">
  <ngx-spinner [zIndex]="100" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom"
    [fullScreen]="false">
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col-lg-3">
                <mat-form-field class="full-width">
                  <mat-label>Ingrese rango de fechas</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [disabled]="true" formControlName="FechaDesde" placeholder="Fecha desde">
                    <input matEndDate [disabled]="true" formControlName="FechaHasta" placeholder="Fecha hasta">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-lg-3">
                <mat-form-field>
                  <mat-label>Rangos rapidos</mat-label>
                  <mat-select placeholder="Seleccione opcion" formControlName="RangoFecha"
                    (selectionChange)="selectRangoFecha($event)">
                    <mat-option [value]="fecha.id"
                      *ngFor="let fecha of lstRangoFecha ;let i=index;trackBy:trackByItems">
                      {{fecha.descripcion | titlecase}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                  <span matPrefix>$ &nbsp;</span>
                  <mat-label>FullUrl</mat-label>
                  <input type="text" placeholder="Path" formControlName="FullUrl" matInput>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button mat-stroked-button color="primary" (click)="getAllByFilter()">
                  <mat-icon>filter_alt</mat-icon>
                  Filtrar</button>&nbsp; &nbsp;
                <!-- <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                  Filtros Avanzados
                </mat-slide-toggle>&nbsp; &nbsp;
                <button mat-stroked-button color="primary" (click)="clearFilter()">
                  <mat-icon>clear_all</mat-icon>
                  Limpiar</button> -->
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>

  <div class="row" [hidden]=!data>
    <div class="col-lg-2">
      <mat-form-field class="example-full-width">
        <mat-label>Buscar</mat-label>
        <input type="search" matInput (keyup)="applyFilter($event)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort [hidden]=!data class="mat-elevation-z8">



    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Timestamp</span>
      </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.timestamp | date:'yyyy-MM-dd HH:mm:ss'}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="id_Usuario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>Usuario</span>
      </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.id_Usuario}}</span> </td>
    </ng-container>


    <ng-container matColumnDef="ipAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span>IP</span>
      </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.ipAddress}}</span> </td>
    </ng-container>

    <ng-container matColumnDef="fullUrl">
      <th mat-header-cell *matHeaderCellDef >
        <span>FullUrl</span>
      </th>
      <td mat-cell *matCellDef="let element"> <span>{{element.fullUrl}}</span>
      </td>
    </ng-container>


    <ng-container matColumnDef="accion">
      <th mat-header-cell *matHeaderCellDef> Acción</th>
      <td mat-cell *matCellDef="let element" class="action-link">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openDialog('Ver',element)">
            <mat-icon>visibility</mat-icon>
            <span>Ver</span>
          </button>
        </mat-menu>
      </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos
      para mostrar.</span>
  </div>
  <mat-paginator showFirstLastButtons class="paginator" [hidden]=!data [pageSize]="20"
    [pageSizeOptions]="[5, 10, 20,50,100,200]">
  </mat-paginator>
</mat-card>
