export class UpdateEmpresaRequest {
  public id?: number
  public descripcion?: string
  public nombre?: string
  public ubicacion?: string
  public piso?: string
  public departamento?: string
  public idLocalidad?: number
  public condicionContable?: string
  public fechaIncioActividad?: string
  public cuit?: string
  public telefono?: string
  public correo?: string
  public iibb?: string
  public web?: string
  public idArchivoLogo?: number
  public idTipoCondicionContable?: number
}
